import React, { Fragment } from "react";
import { MoreVertical, ThumbsUp, UserPlus, MessageSquare } from "react-feather";
import LocationViewLeftBar from "./location-view-info-left";
import LocationViewPhotosPanel from "./location-view-photos-panel";
import { snippet } from "../../common/functions";

const LocationViewInfo = ({ form, openings, portfolios }) => {
  console.log(form);
  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-3 xl-40 col-lg-12 col-md-5">
          <div
            className="default-according style-1 faq-accordion job-accordion"
            id="accordionoc2"
          >
            <div className="row">
              <LocationViewLeftBar form={form} />
            </div>
          </div>
        </div>
        <div className="col-xl-6 xl-60 col-lg-12 col-md-7">
          <div className="row">
            {form.about && (
              <div className="col-sm-12">
                <div className="card mt-0">
                  <div className="card-header">
                    <h5>About Us</h5>
                  </div>
                  <div className="card-body avatar-showcase">
                    {form.about
                      ? new DOMParser().parseFromString(form.about, "text/html")
                          .body.textContent || ""
                      : ""}
                  </div>
                </div>
              </div>
            )}
            <div className="col-sm-12">
              <div className="card mt-0">
                <div className="card-header social-header">
                  <h5>
                    <span>{"Available Job Openings"}</span>
                    <span className="pull-right">
                      <MoreVertical />
                    </span>
                  </h5>
                </div>
                <div className="card-body">
                  {openings.map((data, i) => {
                    return (
                      <div className="row details-about" key={i}>
                        <div className="col-sm-6">
                          <div className="your-details">
                            <span className="f-w-600">
                              <a href={"/job/" + data.EmployerOpeningsID}>
                                {data.Title}
                              </a>
                            </span>
                            <p>
                              {" "}
                              {data.About
                                ? snippet(
                                    new DOMParser().parseFromString(
                                      data.About,
                                      "text/html"
                                    ).body.textContent || "",
                                    200
                                  )
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {portfolios && portfolios.length >= 1 && (
          <div className="col-xl-3 xl-100">
            <div
              className="default-according style-1 faq-accordion job-accordion"
              id="accordionoc3"
            >
              <div className="row">
                <LocationViewPhotosPanel portfolios={portfolios} />
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};
export default LocationViewInfo;
