import React, { useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useMutation } from "@apollo/client";
import { CONVERSATION, SEND_MESSAGE } from "../../api/db";
import emojidata from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

const MessageInput = ({ conversationid, userIds }) => {
  const [newMessage, setValue] = useState("");
  const [inputError, setInputError] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const addEmoji = (e) => {
    const text = `${newMessage}${e.native}`;
    setShowEmojiPicker(false);
    setValue(text);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleMessageChange = (e) => setValue(e.target.value);

  const [newMessageMutation, { saveloading, saveerror, savedata }] =
    useMutation(SEND_MESSAGE, {
      refetchQueries: [
        { query: CONVERSATION, variables: { conversationId: conversationid } },
      ],
    });

  const handleNewMessage = async (e) => {
    var error = inputError;

    if (e.key === "Enter" || e === "send") {
      if (!newMessage) return setInputError(true);

      var container = document.querySelector(".chat-history");
      setTimeout(function () {
        container.scrollBy({ top: container.scrollHeight, behavior: "smooth" });
      }, 310);

      try {
        await newMessageMutation({
          variables: { toUserIDs: userIds, message: newMessage },
        });
        setValue("");
        if (inputError) setInputError(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  if (saveloading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );

  if (saveerror) return <p>Error : {saveerror.message}</p>;

  return (
    <div className="row">
      <div className="mb-2">
        {showEmojiPicker ? (
          <Picker emojiSize={30} data={emojidata} onEmojiSelect={addEmoji} />
        ) : null}
      </div>
      <div className="col-xl-12 d-flex">
        <div className="smiley-box bg-primary">
          <div className="picker" onClick={() => toggleEmojiPicker()}>
            <img
              src={require("../../assets/images/smiley.png")}
              className=""
              alt=""
            />
          </div>
        </div>
        <div className="input-group text-box">
          <input
            type="text"
            className="form-control input-txt-bx"
            placeholder="Type a message......"
            value={newMessage}
            onKeyDown={(e) => handleNewMessage(e)}
            onChange={(e) => handleMessageChange(e)}
          />
          <div className="input-group-append ms-2">
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => handleNewMessage("send")}
            >
              Send
            </button>
          </div>
        </div>
      </div>
      {inputError && (
        <>
          <br />
          <span className="txt-danger">{"Message is required"}</span>
        </>
      )}
    </div>
  );
};

export default MessageInput;
