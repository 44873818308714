import React, { Fragment ,useState} from 'react';
import { Collapse, UncontrolledTooltip } from 'reactstrap';


const LocationViewLeftBar = ({form}) => {
    const [isProfile, setisProfile] = useState(true);
    const [isMutual, setisMutual] = useState(true);
    const [isActivity, setisActivity] = useState(true);
    var profileurl = "";
 
  
  if(form.profile)
  {    
      profileurl =  form.profile;
  }
  

  function getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
}
    
const videoId = "https://customer-3asu2ibnuow5dcv6.cloudflarestream.com/bfcaebe29a9d43b79ceb42b4ca212584/iframe";
//getId(form.videourl);

    return (
        <Fragment>
            <div className="col-xl-12">
                <div className="card mt-0">
                    <div className="card-header">
                        <h5 className="mb-0">
                            <button className="btn btn-link ps-0 border-0" onClick={() => setisProfile(!isProfile)}
                                data-toggle="collapse" data-target="#collapseicon5" aria-expanded={isProfile} aria-controls="collapseicon5">Location Profile</button>
                        </h5>
                    </div>
                    <Collapse isOpen={isProfile}>
                        <div className="card-body socialprofile filter-cards-view">
                                <div className="text-start">
                                    <h5 className="font-primary f-w-600">{form.locationName}</h5>
                                </div>                      
                            <div className="text-start">{form.address1}<br/>{form.address2}</div>
                            <div className="text-start">{form.city}, {form.state} {form.zip}</div>
                            {form.phone && form.phone != "unspecified" &&
                                <div className="text-center">{form.phone}</div>
                            }                           
                            
                            {form.jobCount &&
                            <div className="likes-profile text-center">
                                 <h5><span><i className="fa fa-store font-danger"></i>{form.jobCount} {"Jobs Available"}</span></h5>
                            </div>                              
                            }         
                        </div>
                    </Collapse>
                </div>
            </div>
            <div className="col-xl-12">
                {videoId && 
                   <div className="card mt-0">
                   <div className="card-header">
                       <h5 className="mb-0">
                           <button className="btn btn-link ps-0" onClick={() => setisMutual(!isMutual)}
                               data-toggle="collapse" data-target="#collapseicon6" aria-expanded={isMutual} aria-controls="collapseicon6">Media</button>
                       </h5>
                   </div>
                        <Collapse isOpen={isMutual}>
                            <div className="card-body social-status filter-cards-view">
                                
                                <div className="d-flex">
                                <iframe
  src="https://customer-3asu2ibnuow5dcv6.cloudflarestream.com/bfcaebe29a9d43b79ceb42b4ca212584/iframe"
  style={{border: "none"}}
  height="500"
  
  allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
  allowfullscreen="true"
></iframe>
                                </div>            
                            </div>
                        </Collapse>
                   </div>                
                }            
            </div>          
        </Fragment>
    );
};

export default LocationViewLeftBar;