import React, { Fragment, useState } from 'react'
import { Alert } from 'reactstrap'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  GET_STUDENT_DETAILS,
  GET_PORTFOLIO,
  CHECK_STUDENT_ID,
} from '../../api/db.js'
import logo from '../../assets/images/endless-logo.png'
import { SetUserJWT, LoginSuccess, SaveFirstLogin } from '../../api/session.js'
import {
  REGISTER_USER,
  REGISTER_STUDENT,
  REGISTER_EMPLOYER,
  LOGIN_USER_GRAPH,
} from '../../api/db.js'

function Register() {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [confirmpassword, setConfirmPassword] = useState('')
  const [phone, setPhone] = useState('')
  const [userType, setUserType] = useState(6)
  const [studentId, setStudentId] = useState('')
  const [studentBirthDay, setStudentBirthDay] = useState('')
  const [studentBirthMonth, setStudentBirthMonth] = useState('')
  const [employerName, setEmployerName] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zipcode, setZipCode] = useState('')
  const [error, setError] = useState('')
  const [step, setStep] = useState(1)

  const handleFirstNameChange = (e) => setFirstName(e.target.value)
  const handleLastNameChange = (e) => setLastName(e.target.value)
  const handleEmailChange = (e) => setEmail(e.target.value)
  const handlePasswordChange = (e) => setPassword(e.target.value)
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value)
  const handlePhoneChange = (e) => setPhone(e.target.value)
  const handleUserType = (e) => setUserType(e.target.value)
  const handleStudentId = (e) => setStudentId(e.target.value)
  const handleStudentBirthMonth = (e) => setStudentBirthMonth(e.target.value)
  const handleAddress1 = (e) => setAddress1(e.target.value)
  const handleAddress2 = (e) => setAddress2(e.target.value)
  const handleCity = (e) => setCity(e.target.value)
  const handleState = (e) => setState(e.target.value)
  const handleZipCode = (e) => setZipCode(e.target.value)
  const handleStudentBirthDay = (e) => setStudentBirthDay(e.target.value)
  const handleEmployerName = (e) => setEmployerName(e.target.value)
  const handleStepTwo = (e) => setStep(2)
  const handleStepThree = (e) => {
    setStep(3)
  }
  const [logUserIn, { loading, saveerror, data }] =
    useMutation(LOGIN_USER_GRAPH)

  const [
    registerUser,
    { loading: registerloading, error: registersaveerror, data: registerdata },
  ] = useMutation(REGISTER_USER)
  const [
    registerStudent,
    { loading: studentloading, error: studentsaveerror, data: studentdata },
  ] = useMutation(REGISTER_STUDENT)
  const [
    registerEmployer,
    { loading: employerloading, error: employersaveerror, data: employerdata },
  ] = useMutation(REGISTER_EMPLOYER)
  const [
    checkStudentId,
    {
      loading: studentCheckLoad,
      error: studentCheckError,
      data: studentCheckData,
    },
  ] = useLazyQuery(CHECK_STUDENT_ID)

  const verifyEmployer = (e) => {
    e.preventDefault()
    var errors = []

    if (!employerName) {
      errors.push('Employer Name is required')
    }

    if (errors.length >= 1) {
      setError(errors.toString())
    } else {
      setStep(2)
      setError('')
    }
  }

  const verifyAddress = (e) => {
    e.preventDefault()
    var errors = []

    if (!firstName) {
      errors.push('First Name is required')
    }

    if (!firstName) {
      errors.push('First Name is required')
    }

    if (!lastName) {
      errors.push('Last Name is required')
    }

    if (!address1) {
      errors.push('Address is required')
    }

    if (!city) {
      errors.push('City is required')
    }

    if (!state) {
      errors.push('State is required')
    }

    if (!phone) {
      errors.push('Phone number is required')
    }

    if (errors.length >= 1) {
      setError(errors.toString())
    } else {
      setStep(3)
      setError('')
    }
  }

  const handleRegistration = (e) => {
    e.preventDefault()

    var errors = []
    if (password != confirmpassword) {
      errors.push('Password and Confirm Password do not match')
    }

    if (!password) {
      errors.push('Password is required')
    }

    if (!confirmpassword) {
      errors.push('Confirm Password is required')
    }

    if (errors.length >= 1) {
      setError(errors.toString())
    } else {
      saveRegistration()
    }
  }

  const verifyStudent = async (e) => {
    e.preventDefault()
    var errors = []

    if (!studentId) {
      errors.push('StudentId Name is required')
    }

    if (!studentId) {
      errors.push('Birthday Month is required')
    }

    if (!studentId) {
      errors.push('Birthday Year is required')
    }

    if (errors.length >= 1) {
      setError(errors.toString())
    } else {
      const student = await checkStudentId({
        variables: {
          studentId: parseInt(studentId),
          birthDay: parseInt(studentBirthDay),
          birthMonth: parseInt(studentBirthMonth),
        },
      })

      if (!student || !student.data.checkStudent.isValid) {
        setError('Cannot validate student information please contact support')
      } else {
        setStep(2)
        setError('')
      }
    }
  }
  const saveRegistration = async () => {
    try {
      const user = await registerUser({
        variables: {
          userTypesId: parseInt(userType),
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          address1: address1,
          address2: address2,
          city: city,
          state: state,
          zipcode: zipcode,
          email: email,
          password: password,
        },
      })

      if (user) {
        if (userType == 6) {
          console.log(user)

          const student = await registerStudent({
            variables: {
              usersClearingId: user.data.registerUser.UsersClearingID,
              studentId: parseInt(studentId),
              birthDay: parseInt(studentBirthDay),
              birthMonth: parseInt(studentBirthMonth),
            },
          })
          console.log('student', user)
          if (student) {
            if (!student.data.registerStudent.Success) {
              setError(student.data.registerStudent.Message)
            } else {
              setError('')

              SaveFirstLogin('true')
              window.location.href = '/login'
            }
          }
        }

        if (userType == 4) {
          console.log(user)
          const employer = await registerEmployer({
            variables: {
              usersClearingId: user.data.registerUser.UsersClearingID,
              employerName: employerName,
            },
          })

          if (employer) {
            if (!employer.data.registerEmployer.Success) {
              setError(employer.data.registerEmployer.Message)
            } else {
              setError('')
              SaveFirstLogin('true')
              window.location.href = '/login'
            }
          }
        }
      }
    } catch (err) {
      console.log(err)

      setError(err.message)
      setUsername('')
      setPassword('')
    }
  }

  const showloader =
    loading ||
    registerloading ||
    studentloading ||
    employerloading ||
    studentCheckLoad

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="auth-bg pt-3">
          <div className="col-12 col-md-8">
            <div className="authentication-box w-100">
              <div className="text-center mb-2">
                <a href="/">
                  <img src={logo} alt="" />
                </a>
              </div>
              <div className="card mb-2 mt-3">
                <div className="card-body">
                  <div className="text-center">
                    <h4 className="mt-0">Register</h4>
                    <h6>{'Enter details below to create your account'} </h6>
                    {error && (
                      <Alert color="danger dark alert-dismissible">
                        <ul>
                          {error.split(',').map((x) => {
                            return <li>{x}</li>
                          })}
                        </ul>
                      </Alert>
                    )}
                  </div>
                  {showloader && (
                    <div className="theme-form">
                      <div className="loader-box" style={{ display: 'flex' }}>
                        <div
                          className="loader"
                          style={{ alignItems: 'center', width: '100%' }}
                        >
                          <div className="line"></div>
                          <div className="line"></div>
                          <div className="line"></div>
                          <div className="line"></div>
                        </div>
                      </div>
                    </div>
                  )}

                  {!showloader && (
                    <form
                      className="theme-form"
                      onSubmit={(e) => handleRegistration(e)}
                    >
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="card mt-0">
                            <div className="d-flex p-20">
                              <div className="radio radio-primary me-3 ms-2">
                                <input
                                  id="radio14"
                                  type="radio"
                                  name="radio1"
                                  value={'6'}
                                  checked={userType == 6}
                                  onChange={(e) => handleUserType(e)}
                                />
                                <label htmlFor="radio14"></label>
                              </div>
                              <div className="flex-grow-1">
                                <h6 className="mt-0 mega-title-badge">
                                  {'STUDENT'}
                                </h6>
                                <p>
                                  {
                                    'Undergraduate or Graduates looking for employment'
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="card mt-0">
                            <div className="d-flex p-20">
                              <div className="radio radio-secondary me-3 ms-2">
                                <input
                                  id="radio13"
                                  type="radio"
                                  name="radio1"
                                  value={'4'}
                                  checked={userType == 4}
                                  onChange={(e) => handleUserType(e)}
                                />
                                <label htmlFor="radio13"></label>
                              </div>
                              <div className="flex-grow-1">
                                <h6 className="mt-0 mega-title-badge">
                                  {'EMPLOYER'}
                                </h6>
                                <p>
                                  {
                                    'Business Locations or Employees looking to hire'
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {userType == '6' && step == 1 && (
                          <>
                            <div className="co-12 col-md-6">
                              <div className="form-group">
                                <label className="col-form-label pt-0">
                                  Student ID
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  required=""
                                  placeholder="Student ID"
                                  value={studentId}
                                  onChange={(e) => handleStudentId(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label className="col-form-label pt-0">
                                  Birthday Month
                                </label>
                                <select
                                  className="form-control"
                                  name="birthday_month"
                                  id="birthday_month"
                                  value={studentBirthMonth}
                                  onChange={(e) => handleStudentBirthMonth(e)}
                                >
                                  <option value="0">Month</option>
                                  <option value="1">January</option>
                                  <option value="2">February</option>
                                  <option value="3">March</option>
                                  <option value="4">April</option>
                                  <option value="5">May</option>
                                  <option value="6">June</option>
                                  <option value="7">July</option>
                                  <option value="8">August</option>
                                  <option value="9">September</option>
                                  <option value="10">October</option>
                                  <option value="11">November</option>
                                  <option value="12">December</option>
                                </select>{' '}
                              </div>
                              <div className="form-group">
                                <label className="col-form-label pt-0">
                                  Birthday Day
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  min="1"
                                  max="31"
                                  required=""
                                  placeholder="Birthday Day"
                                  value={studentBirthDay}
                                  onChange={(e) => handleStudentBirthDay(e)}
                                />
                              </div>

                              <button
                                className="btn btn-primary me-2 w-100"
                                onClick={(e) => verifyStudent(e)}
                              >
                                Next
                              </button>
                            </div>
                          </>
                        )}

                        {step == 2 && (
                          <>
                            <div className="col-12">
                              <h6>
                                {
                                  'Welcome! Enter profile information for your account'
                                }{' '}
                              </h6>
                              <div className="form-group">
                                <label className="col-form-label pt-0">
                                  First Name
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  required=""
                                  placeholder="First Name"
                                  value={firstName}
                                  onChange={(e) => handleFirstNameChange(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label className="col-form-label pt-0">
                                  Last Name
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  required=""
                                  placeholder="Last Name"
                                  value={lastName}
                                  onChange={(e) => handleLastNameChange(e)}
                                />
                              </div>

                              <div className="form-group">
                                <label className="col-form-label pt-0">
                                  Phone
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  required=""
                                  placeholder="Phone"
                                  value={phone}
                                  onChange={(e) => handlePhoneChange(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label className="col-form-label pt-0">
                                  Address 1
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  required=""
                                  placeholder="Address 1"
                                  value={address1}
                                  onChange={(e) => handleAddress1(e)}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label className="col-form-label pt-0">
                                  Address 2
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Address 2"
                                  value={address2}
                                  onChange={(e) => handleAddress2(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label className="col-form-label pt-0">
                                  City
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  required=""
                                  placeholder="City"
                                  value={city}
                                  onChange={(e) => handleCity(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label className="col-form-label pt-0">
                                  State
                                </label>
                                <select
                                  name="ed_state"
                                  id="ed_state"
                                  className="form-control ui search selection dropdown"
                                  value={state}
                                  onChange={(e) => handleState(e)}
                                >
                                  <option value="0">Select A State</option>
                                  <option value="AL">Alabama</option>
                                  <option value="AK">Alaska</option>
                                  <option value="AZ">Arizona</option>
                                  <option value="AR">Arkansas</option>
                                  <option value="CA">California</option>
                                  <option value="CO">Colorado</option>
                                  <option value="CT">Connecticut</option>
                                  <option value="DE">Delaware</option>
                                  <option value="DC">
                                    District of Columbia
                                  </option>
                                  <option value="FL">Florida</option>
                                  <option value="GA">Georgia</option>
                                  <option value="HI">Hawaii</option>
                                  <option value="ID">Idaho</option>
                                  <option value="IL">Illinois</option>
                                  <option value="IN">Indiana</option>
                                  <option value="IA">Iowa</option>
                                  <option value="KS">Kansas</option>
                                  <option value="KY">Kentucky</option>
                                  <option value="LA">Louisiana</option>
                                  <option value="ME">Maine</option>
                                  <option value="MD">Maryland</option>
                                  <option value="MA">Massachusetts</option>
                                  <option value="MI">Michigan</option>
                                  <option value="MN">Minnesota</option>
                                  <option value="MS">Mississippi</option>
                                  <option value="MO">Missouri</option>
                                  <option value="MT">Montana</option>
                                  <option value="NE">Nebraska</option>
                                  <option value="NV">Nevada</option>
                                  <option value="NH">New Hampshire</option>
                                  <option value="NJ">New Jersey</option>
                                  <option value="NM">New Mexico</option>
                                  <option value="NY">New York</option>
                                  <option value="NC">North Carolina</option>
                                  <option value="ND">North Dakota</option>
                                  <option value="OH">Ohio</option>
                                  <option value="OK">Oklahoma</option>
                                  <option value="OR">Oregon</option>
                                  <option value="PA">Pennsylvania</option>
                                  <option value="RI">Rhode Island</option>
                                  <option value="SC">South Carolina</option>
                                  <option value="SD">South Dakota</option>
                                  <option value="TN">Tennessee</option>
                                  <option value="TX">Texas</option>
                                  <option value="UT">Utah</option>
                                  <option value="VT">Vermont</option>
                                  <option value="VA">Virginia</option>
                                  <option value="WA">Washington</option>
                                  <option value="WV">West Virginia</option>
                                  <option value="WI">Wisconsin</option>
                                  <option value="WY">Wyoming</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label className="col-form-label pt-0">
                                  Zipcode
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  required=""
                                  placeholder="Zipcode"
                                  value={zipcode}
                                  onChange={(e) => handleZipCode(e)}
                                />
                              </div>
                              <button
                                className="btn btn-primary me-2 w-100"
                                onClick={(e) => verifyAddress(e)}
                              >
                                Next
                              </button>
                            </div>
                          </>
                        )}

                        {userType == '4' && step == 1 && (
                          <>
                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label className="col-form-label pt-0">
                                  Employer Name
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  required=""
                                  placeholder="Employer Name"
                                  value={employerName}
                                  onChange={(e) => handleEmployerName(e)}
                                />
                              </div>
                              <button
                                className="btn btn-primary me-2 w-100"
                                onClick={(e) => verifyEmployer(e)}
                              >
                                Next
                              </button>
                            </div>
                          </>
                        )}

                        {step == 3 && (
                          <>
                            <div className="col-12 col-md-6">
                              <h6>
                                {
                                  'Great! Enter your Login info to create your account.'
                                }{' '}
                              </h6>
                              <div className="form-group">
                                <label className="col-form-label pt-0">
                                  Email Address
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  required=""
                                  placeholder="test@gmail.com"
                                  value={email}
                                  onChange={(e) => handleEmailChange(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label className="col-form-label">
                                  Password
                                </label>
                                <input
                                  className="form-control"
                                  type="password"
                                  required=""
                                  placeholder="Password"
                                  onChange={(e) => handlePasswordChange(e)}
                                />
                              </div>
                              <div className="form-group">
                                <label className="col-form-label">
                                  Confirm Password
                                </label>
                                <input
                                  className="form-control"
                                  type="password"
                                  required=""
                                  placeholder="Confirm Password"
                                  onChange={(e) =>
                                    handleConfirmPasswordChange(e)
                                  }
                                />
                              </div>
                              <div className="form-group mt-3">
                                <button
                                  className="btn btn-primary me-2 w-100"
                                  type="submit"
                                >
                                  Register
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </form>
                  )}
                </div>
              </div>
              <div className="text-end">
                <a href="/login">
                  <span>
                    <i className="fa fa-angle-left pe-2"></i>Go Back
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Register
