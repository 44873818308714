import React, { Fragment } from "react";
import Breadcrumb from "../components/common/breadcrumb";
import ResumeBuilder from "../components/resumes/builder/resume-builder";
import { useParams } from "react-router-dom";
import { GET_STUDENT_DETAILS } from "../api/db";
import { GetUserID } from "../api/session";
import { useQuery } from "@apollo/client";
import { errorCheck } from "../components/common/functions";

const Resume = () => {
  var { id } = useParams();

  const { loading, error, data } = useQuery(GET_STUDENT_DETAILS, {
    variables: { userId: GetUserID() },
    fetchPolicy: "network-only",
  });

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );

  if (error) {
    return errorCheck(error);
  }

  var experiences = [];
  var education = [];
  var colorValue = "blue";

  if (id) {
    console.log(id);
    var resumee = data.student.Resumees.find((r) => r.ResumeeID === id);

    var resumeInput = {
      resumeId: parseInt(resumee.ResumeeID),
      isPrimary: Boolean(resumee.isPrimary),
      ResumeeColorID: String(resumee.ResumeeColorID),
      ResumeeStyleID: String(resumee.ResumeeStyleID),
      CareerID: String(resumee.CareerID),
      StudentID: resumee.StudendID,
      Title: resumee.Title,
      Objective: resumee.Objective,
      EmploymentHistory: experiences,
      EducationHistory: education,
      ColorValue: colorValue,
      FirstName: data.student.FirstName,
      LastName: data.student.LastName,
      Phone: data.student.Phone,
      Email: data.student.Email,
    };

    resumee.EmploymentHistory.forEach((exp) => {
      resumeInput.EmploymentHistory.push({
        employmentHistoryID: exp.EmploymentHistoryID,
        resumeeId: parseInt(exp.ResumeeID),
        employerName: exp.EmployerName,
        title: exp.Title,
        description: exp.Description,
        startMonth: exp.StartMonth,
        startYear: exp.StartYear,
        endMonth: exp.EndMonth,
        endYear: exp.EndYear,
        employerCity: exp.EmployerCity,
        employerState: exp.EmployerState,
        current: exp.Current,
      });
    });

    resumee.EducationHistory.forEach((exp) => {
      resumeInput.EducationHistory.push({
        educationHistoryId: exp.EducationHistoryID,
        resumeeId: parseInt(exp.ResumeeID),
        school: exp.School,
        certificate: exp.Certificate,
        startMonth: exp.StartMonth,
        startYear: exp.StartYear,
        endMonth: exp.EndMonth,
        endYear: exp.EndYear,
        city: exp.City,
        state: exp.State,
        current: exp.Current,
      });
    });

    if (resumeInput.ResumeeColorID === "1") resumeInput.ColorValue = "blue";

    if (resumeInput.ResumeeColorID === "2") resumeInput.ColorValue = "green";

    if (resumeInput.ResumeeColorID === "3") resumeInput.ColorValue = "pink";
  } else {
    resumeInput = {
      resumeId: "",
      isPrimary: false,
      ResumeeColorID: "",
      ResumeeStyleID: "",
      CareerID: "",
      StudentID: data.student.StudendID,
      Title: "",
      Objective: "",
      EmploymentHistory: experiences,
      EducationHistory: education,
      ColorValue: colorValue,
      FirstName: data.student.FirstName,
      LastName: data.student.LastName,
      Phone: data.student.Phone,
      Email: data.student.Email,
    };
  }

  return (
    <Fragment>
      <Breadcrumb parent="Resume" title={resumeInput.Title} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-md-6 xl-50">
            <ResumeBuilder resume={resumeInput} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Resume;
