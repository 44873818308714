import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  GET_EMPLOYER_LOCATION_JOB,
  GET_STUDENT_RESUMEES,
  GET_EMPLOYER_KEYWORD_LIST,
  GET_EMPLOYER_LOCATIONS,
} from "../api/db.js";
import { GetUserID } from "../api/session.js";

import JobView from "../components/locations/jobs/view/location-job-view.js";
import { errorCheck } from "../components/common/functions.js";

const Job = () => {
  var { id } = useParams();
  const userId = GetUserID();

  const { loading, error, data, refetch } = useQuery(
    GET_EMPLOYER_LOCATION_JOB,
    { variables: { employerOpeningsId: parseInt(id) } }
  );
  const {
    loading: jobsloading,
    error: jobserror,
    data: jobsdata,
    refetch: jobsrefetch,
  } = useQuery(GET_EMPLOYER_LOCATIONS, {
    variables: { employerId: GetUserID() },
  });
  const {
    loading: rloading,
    error: rerror,
    data: resumeedata,
  } = useQuery(GET_STUDENT_RESUMEES, {
    variables: { userId: parseInt(userId) },
  });
  const {
    loading: kloading,
    error: kerror,
    data: keywordList,
  } = useQuery(GET_EMPLOYER_KEYWORD_LIST, {});

  if (loading || rloading || kloading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );

  if (error) {
    return errorCheck(error);
  }

  console.log("jobdata", data);
  console.log(keywordList);

  if (keywordList) {
    var job = data.employerOpening;

    var resumees = resumeedata.student.Resumees;

    var keywords = keywordList.employerKeywords;
  }

  return (
    <Fragment>
      <JobView
        job={job}
        resumeelist={resumees}
        keywordlist={keywords}
        callback={jobsrefetch}
      ></JobView>
    </Fragment>
  );
};

export default Job;
