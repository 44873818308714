import React, { useState, Fragment, useEffect, useLayoutEffect } from "react";
import UserMenu from "../common/header-component/userMenu";
import Notification from "../common/header-component/notification";
import { Search, Maximize, Bell, MessageCircle, User } from "react-feather";
import { Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import { useQuery } from "@apollo/client";
import { CONVERSATIONS } from "../../api/db";
import { GetUserType } from "../../api/session";

const FooterMobile = ({ messageclick }) => {
  const [sidebar, setSidebar] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [headerbar, setHeaderbar] = useState(true);
  const [popover, setPopover] = useState(false);
  const HtmlContentToggle = () => setPopover(!popover);

  var unmessages = 0;
  var messageCount = 0;
  const { loading, error, data } = useQuery(CONVERSATIONS, {
    pollInterval: 2000,
  });

  if (data) {
    data.getConversations.map((conversationItem) => {
      unmessages = unmessages + conversationItem.UnReadCount;
    });

    data.getConversations.map((x) => {
      if (x.UnReadCount != 0) messageCount = messageCount + x.UnReadCount;
    });
  }

  function showRightSidebar() {
    if (rightSidebar) {
      document.querySelector(".right-sidebar").classList.add("show");
      setRightSidebar(!rightSidebar);
    } else {
      document.querySelector(".right-sidebar").classList.remove("show");
      setRightSidebar(!rightSidebar);
    }
  }

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
    <div className="page-main-header position-fixed bottom-nav-z bottom-0 bg-white w-100 pt-2 footer-border blue-top">
      <div className="main-header-right row">
        <div className="col-12 p-0">
          <div className="d-flex flex-row justify-content-between py-1">
            {GetUserType() == "Student" && (
              <div className="text-center">
                <a href="/jobs">
                  <Search size={20} />
                  <br />
                  <small className="footer-text-color">Search</small>
                </a>
              </div>
            )}

            {GetUserType() == "Employer" && (
              <div className="text-center">
                <a href="/students-search">
                  <Search size={20} />
                  <br />
                  <span className="footer-text-color">Search</span>
                </a>
              </div>
            )}

            <div className="text-center" id="Popover-1">
              <Bell size={20} />
              <br />
              <label position="stacked">
                <span className="footer-text-color">
                  <small>Notifications</small>
                </span>
              </label>
            </div>

            <Popover
              placement="top"
              isOpen={popover}
              target={"Popover-1"}
              toggle={HtmlContentToggle}
              data-html={true}
              className="notifypopover"
            >
              <PopoverBody>
                {messageCount != 0 && (
                  <span>{"You have " + messageCount + " unread messages"}</span>
                )}

                {messageCount == 0 && "No new messages"}
              </PopoverBody>
            </Popover>

            <div className="text-center">
              <MessageCircle size={20} onClick={() => messageclick()} />
              {unmessages > 0 && <span className="dot"></span>} <br />
              <small className="footer-text-color">Messages</small>
            </div>
            <div className="text-center">
              <a href="/settings/profile">
                <User size={20} />
                <br />
                <label position="stacked">
                  <small>Profile</small>
                </label>
              </a>
            </div>
          </div>
        </div>
        <script id="result-template" type="text/x-handlebars-template">
          <div className="ProfileCard u-cf">
            <div className="ProfileCard-avatar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-airplay m-0"
              >
                <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                <polygon points="12 15 17 21 7 21 12 15"></polygon>
              </svg>
            </div>
            <div className="ProfileCard-details">
              <div className="ProfileCard-realName"></div>
            </div>
          </div>
        </script>
        <script id="empty-template" type="text/x-handlebars-template">
          <div className="EmptyMessage">
            {
              "Your search turned up 0 results. This most likely means the backend is down, yikes!"
            }
          </div>
        </script>
      </div>
    </div>
  );
};
export default FooterMobile;
