import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { DELETE_PORTFOLIO_ITEM, ADD_PORTFOLIO_IMAGE } from "../../../api/db";
import { GetUserID } from "../../../api/session";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LightBoxModal from "../../common/lightBoxModal";
import { Image } from "react-bootstrap";

const Portfolio = ({ portfolios, refreshCallback }) => {
  const [modal6, setModal6] = useState();
  const [imageFile, setImageFile] = useState();
  const [imageFileUrl, setImageFileUrl] = useState();
  const [imageCaption, setImageCaption] = useState();
  const [isDeleted, setIsDeleted] = useState(false);
  const [isDeletedIndex, setIsDeletedIndex] = useState(-1);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [imageFileError, setImageFileError] = useState();

  const toggleUpload = () => {
    setModal6(!modal6);
  };

  const handleImageChange = (e) => {
    let regex = new RegExp(/[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/);

    if (!regex.test(e.target.files[0].name)) {
      setImageFileError("Image files must be of types: jpg, jpeg, png, gif");
      return toast.error("File Type not valid");
    }
    setImageFile(e.target.files[0]);
    setImageFileUrl(URL.createObjectURL(e.target.files[0]));
  };

  const handleImageCaption = (e) => {
    setImageCaption(e.target.value);
  };

  const handleImageSubmit = async () => {
    console.log("Submitting Images");
    console.log(imageFile);
    console.log(imageCaption);

    var imageUrl = "";
    const formData = new FormData();

    formData.append("file", imageFile);

    fetch("https://bc.empire-dev.com/api/upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then(async (result) => {
        console.log("Success:", result);
        imageUrl = result;
        var userid = GetUserID();
        console.log(userid);
        try {
          await addPortfolio({
            variables: {
              userId: parseInt(userid),
              src: imageUrl,
              caption: imageCaption,
            },
          });

          setImageCaption("");
          setImageFileUrl();
          refreshCallback();

          toast("Portfolio Image Added", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        } catch (err) {
          console.log(err.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    if (imageUrl != "") {
      console.log(imageUrl);
    }

    setModal6(!modal6);
  };

  const [deletePortfolio, deleting, deleteError] = useMutation(
    DELETE_PORTFOLIO_ITEM,
    {}
  );

  const [addPortfolio, { inserting }] = useMutation(ADD_PORTFOLIO_IMAGE, {});

  const handleDeletePortfolio = async (portfolioid) => {
    console.log(portfolioid);

    try {
      if (portfolioid) {
        await deletePortfolio({ variables: { portfolioId: portfolioid } });
        refreshCallback();
        setIsDeleted(false);
        setIsDeletedIndex();
      }
    } catch (err) {
      console.log(err);
      toast.error(deleteError.message);
    }
  };

  var portfolioimages = [];

  portfolios.map((items, i) => {
    portfolioimages.push(items.Src);
  });

  return (
    <div className="col-lg-8">
      <div className="card mt-0">
        <div className="card-header d-flex justify-content-between ">
          <h4 className="card-title align-self-end">Portfolio</h4>
          <div className="card-options">
            <a
              className="card-options-collapse"
              href="javascript"
              data-toggle="card-collapse"
            >
              <i className="fe fe-chevron-up"></i>
            </a>
            <a
              className="card-options-remove"
              href="javascript"
              data-toggle="card-remove"
            >
              <i className="fe fe-x"></i>
            </a>
          </div>

          <Button
            className="btn btn-secondary btn-sm"
            color="success"
            onClick={toggleUpload}
          >
            <i className="fa fa-plus"></i> {"Add Image"}
          </Button>
          <Modal isOpen={modal6} toggle={toggleUpload}>
            <ModalHeader toggle={toggleUpload}>Add Image</ModalHeader>
            <ModalBody>
              <form>
                <div className="form-group">
                  <label className="col-form-label" htmlFor="message-text">
                    Image:
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    onChange={(e) => handleImageChange(e)}
                  />
                  {imageFileError && (
                    <span className="txt-danger">{imageFileError}</span>
                  )}
                </div>
                <div className="form-group">
                  <label className="col-form-label" htmlFor="recipient-name">
                    Caption:
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    onChange={(e) => handleImageCaption(e)}
                    placeholder="Image Description"
                  />
                  <br />
                  {imageFileUrl && <Image src={imageFileUrl} fluid />}
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                disabled={!imageFileUrl}
                onClick={(e) => handleImageSubmit(e)}
              >
                Add Image
              </Button>
              <Button color="secondary" onClick={(e) => toggleUpload(e)}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        <div className="port-container mb-3 border-bottom pb-2 pt-2">
          <div className="text-start port-items pb-2 pb-md-0">
            <span className="d-block fw-bold text-dark">Image</span>
          </div>
          <div className="text-start port-items pb-2 pb-md-0">
            <span className="d-block fw-bold text-dark">Caption</span>
          </div>
          <div className="text-end port-items pb-2 pb-md-0">
            <span className="d-block fw-bold text-dark">Action</span>
          </div>
        </div>

        {portfolios.map((items, i) => (
          <div key={i} className="port-container mb-3 border-bottom pb-2 pt-2">
            <div className="text-start port-items pb-2 pb-md-0">
              <span className="d-block fw-bold text-dark">
                <a
                  className="text-inherit"
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("firing");
                    setSelectedImageIndex(i);
                  }}
                >
                  <img
                    className="object-fit-cover rounded w-25"
                    src={
                      items.Src +
                      "?w=150&amp;h=150&amp;mode=crop&amp;anchor=topcenter"
                    }
                    loading="lazy"
                    alt="portfolio"
                  />
                </a>
              </span>
            </div>
            <div className="text-start port-items pb-2 pb-md-0">
              <span className="d-block fw-bold text-dark">{items.Caption}</span>
            </div>
            <div className="text-end port-items pb-2 pb-md-0">
              <span className="d-block fw-bold text-dark">
                {isDeletedIndex != i && (
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                      setIsDeleted(true);
                      setIsDeletedIndex(i);
                      handleDeletePortfolio(items.PortfolioID);
                    }}
                  >
                    <i className="fa fa-trash"></i> {"Delete"}
                  </button>
                )}
              </span>

              {isDeleted && isDeletedIndex == i && (
                <div className="loader-box pull-right">
                  <span className="rotate groove"></span>
                  <small>Deleting Image...</small>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {selectedImageIndex != null && selectedImageIndex > -1 && (
        <LightBoxModal
          images={portfolioimages}
          selectedImg={portfolioimages[selectedImageIndex]}
          selectedIndex={selectedImageIndex}
          setSelectedIndex={setSelectedImageIndex}
        />
      )}
    </div>
  );
};

export default Portfolio;
