import React, { Fragment } from "react";
import Breadcrumb from "../../../common/breadcrumb.js";
import LocationJobCard from "../location-job-card.js";
import { useQuery, useMutation } from "@apollo/client";
import { GET_EMPLOYER_LOCATION_JOB, ADD_LOCATION } from "../../../../api/db.js";
import { GetUserID } from "../../../../api/session.js";
import { useParams } from "react-router-dom";
import { Button } from "reactstrap";
import LocationJobApplicantCard from "./location-job-applicant-card.js";
import { errorCheck } from "../../../common/functions.js";

const JobApplicants = () => {
  var { id } = useParams();

  const { loading, error, data } = useQuery(GET_EMPLOYER_LOCATION_JOB, {
    variables: { employerOpeningsId: parseInt(id) },
  });

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );

  if (error) {
    return errorCheck(error);
  }

  console.log(data);

  let applicants = [];
  var job = data.employerOpening;
  data.employerOpening.ApplicationTargets.Resumees.map((applicant) => {
    applicants.push({ applicant, job });
  });

  return (
    <Fragment>
      <Breadcrumb title={job.Title + " - Applicants"} />
      <div className="container-fluid">
        <div className="row">
          {applicants.map((application, i) => {
            return (
              <LocationJobApplicantCard
                key={i}
                application={application}
                job={application.job}
              />
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default JobApplicants;
