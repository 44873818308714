import { GetStudent } from '../../api/session'

const ResumeStyle1 = ({ resume }) => {
  var student = GetStudent(resume.studentId)

  var color = 'blue'
  if (resume.color == '1') color = '#0088b0'

  if (resume.color == '2') color = '#6bab39'

  if (resume.color == '3') color = '#e53679'
  let careertype = ''
  const ctypes = [
    { id: '1', category: 'Cosmetologist' },
    { id: '6', category: 'Makeup Artist' },
    { id: '8', category: 'Hairstylist' },
    { id: '11', category: 'Assistant/Apprentice' },
    { id: '12', category: 'Barber' },
    { id: '13', category: 'Braider' },
    { id: '14', category: 'Colorist' },
    { id: '15', category: 'Educator-Schools' },
    { id: '16', category: 'Educator-Salons' },
    { id: '17', category: 'Education Management' },
    { id: '18', category: 'Esthetician/Skin Care Specialist' },
    { id: '21', category: 'Nail Technician/Manicurist' },
    { id: '22', category: 'Product Specialist' },
    { id: '23', category: 'Receptionist' },
    { id: '24', category: 'Sales Consultant' },
    { id: '25', category: 'Salon Management' },
    { id: '26', category: 'Spa Management' },
    { id: '27', category: 'Esthetician/Cosmetologist' },
  ]

  careertype = ctypes.find((e) => e.id == resume.careerType).category

  return (
    <div id="resumeebody" className="container">
      <div className="row">
        <div
          className="col-12 bg-dark text-white p-3"
          style={{ backgroundColor: color }}
        >
          <h2 className="display-6">
            {resume.firstName} {resume.lastName}
          </h2>
          <p className="lead">{careertype}</p>
          <hr className="my-4 bg-light" />
          <p>
            <strong>Contact:</strong>
          </p>
          {resume.phone && <p className="mb-0">Phone: {resume.phone}</p>}

          {resume.email && <p>Email: {resume.email}</p>}
        </div>
        <div className="col-12 bg-white p-md-2">
          <h3 className="font-weight-bold">Summary</h3>
          <p dangerouslySetInnerHTML={{ __html: resume.objective }}></p>
          <h3 className="font-weight-bold">Experience</h3>
          {resume.experiences &&
            resume.experiences.map((exp, i) => {
              var start =
                exp.startMonth == '13' || exp.startYear == '1'
                  ? 'Present'
                  : exp.startMonth + '/' + exp.startYear
              var end =
                exp.endMonth == '13' || exp.endYear == '1'
                  ? 'Present'
                  : exp.endMonth + '/' + exp.endYear

              return (
                <p key={i}>
                  {exp.employerName},{' '}
                  {exp.employerCity + ', ' + exp.employerState} {exp.title},{' '}
                  {start + ' - ' + end}
                </p>
              )
            })}
          <h3 className="font-weight-bold">Education</h3>
          {resume.educations &&
            resume.educations.map((edu, i) => {
              var start =
                edu.startMonth == '13' || edu.startYear == '1'
                  ? 'Present'
                  : edu.startMonth + '/' + edu.startYear
              var end =
                edu.endMonth == '13' || edu.endYear == '1'
                  ? 'Present'
                  : edu.endMonth + '/' + edu.endYear
              return (
                <p key={i}>
                  {edu.school + ', ' + edu.city + ', ' + edu.state}{' '}
                  {edu.certificate}, {start + ' - ' + end}
                </p>
              )
            })}
        </div>
      </div>
    </div>

    //     <div id="resumeebody2" style={{backgroundColor: '#f5f5f5'}}>
    // <div className="row mx-auto g-0">

    //             <div className="col-3 text-white" style={{backgroundColor:color}}>
    //                 <div className="p-3 fw-bold text-end">
    //                     <span className="fs-6 text-uppercase"></span>
    //                     <span className="fs-6 text-uppercase"></span>
    //                     <span className="fs-6 fw-normal d-block"></span>
    //                     {resume.phone &&
    //              <span className="fs-6 fw-normal">p: </span>
    //               }

    //               {resume.email &&
    //              <span className="fs-6 fw-normal">e: </span>
    //               }
    //                 </div>
    //                 <div className="p-3 text-end" style={{height: "255px"}}>
    //                     <span className="fs-6 text-uppercase d-block">Summary</span>
    //                 </div>
    //                 <div className="p-3 text-end" style={{height: "200px"}}>
    //                     <span className="fs-6 text-uppercase d-block">Experience</span>
    //                 </div>
    //                 <div className="p-3 text-end">
    //                     <span className="fs-6 text-uppercase d-block">Education</span>
    //                 </div>
    //             </div>
    //             <div className="col-9 text-dark">
    //                  <div className="p-3 fw-bol text-startd" style={{marginTop:"50px overflow:auto;"}}>
    //                 </div>
    //                 <div className="p-3 text-start">
    //                     <span className="d-block fw-bold" style={{color:color}}>Objective</span>
    //                     <span className="fs-6 d-block">
    //                     <p dangerouslySetInnerHTML={{__html: resume.objective}}>

    //                                     </p>
    //                      </span>
    //                 </div>
    //                 {resume.experiences && resume.experiences.map((exp,i) => {

    //                    var start = exp.startMonth == "Present" || exp.startYear == "Present" ? "Present" : exp.startMonth+'/'+exp.startYear;
    //                    var end = exp.endMonth == "Present" || exp.endYear == "Present" ? "Present" : exp.endMonth+'/'+exp.endYear;

    //                   return(  <div key={i} className="p-3 text-start" style={{height: "200px"}}>
    //                   <span className="fs-6 d-block fw-bold" style={{color:color}}>{exp.title} {exp.employerName}, {(exp.employerCity+', '+exp.employerState)}</span>
    //                   <span className="fs-6 d-block fw-bold" style={{color:color}}> {start+' - '+end}</span>
    //                   <span>{exp.description}</span>
    //               </div>)
    //               })}
    //               {resume.educations && resume.educations.map((edu,i) => {

    //                    var start = edu.startMonth == "Present" || edu.startYear == "Present" ? "Present" : edu.startMonth+'/'+edu.startYear;
    //                    var end = edu.endMonth == "Present" || edu.endYear == "Present" ? "Present" : edu.endMonth+'/'+edu.endYear;

    //                     return( <div key={i} className="p-3 text-start">
    //                    <span className="fs-6 d-block" style={{color:color}}> {edu.school+', '+edu.city+', '+edu.state}</span>
    //                    <span className="fs-6 d-block fw-bold" style={{color:color}}> {start+ ' - '+end}</span>
    //                    <span className="fs-6 d-block fw-bold" style={{color:color}}> {edu.certificate}</span>
    //                </div>)
    //                    })}

    //             </div>
    //         </div>
    //     </div>
  )
}

export default ResumeStyle1
