import React, { Fragment, useEffect, useState } from 'react'
import Breadcrumb from '../components/common/breadcrumb'
import { getContentfulType } from '../api/contentful-client'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

const TermsOfService = () => {
  const [post, setPost] = useState(null)
  const [isloading, setIsloading] = useState(false)

  useEffect(() => {
    getContentfulType('beautyCareerContentPage').then((res) => {
      console.log(res)
      setPost(
        res.items.find((x) => x.sys.id == '1HM3DSoOdBlRcrOQDSJuE0').fields
      )
      setIsloading(true)
    })
  }, [])

  console.log(post)
  return (
    <Fragment>
      <Breadcrumb parent="Beauty Shop" title="Terms of Service" />
      {isloading && (
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-md-6 xl-50">
              <div className="card mt-0">
                <div className="card-header p-3">
                  <h1 className="mb-0">{post.pageTitle}</h1>
                </div>
                <div
                  className="p-3"
                  dangerouslySetInnerHTML={{
                    __html: documentToHtmlString(post.pageContent),
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default TermsOfService
