import React, { useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { ADD_JOB_OPENING, DELETE_JOB_OPENING } from "../../../api/db.js";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import useInput from "../../../hooks/useInput.js";
import { toast } from "react-toastify";
import { GetUserID } from "../../../api/session.js";
import SweetAlert from "sweetalert2";
import SimpleReactValidator from "simple-react-validator";

const JobManager = ({ locationID, jobs, refreshCallback }) => {
  console.log(jobs);
  const [deleteJobOpening, deleting, deleteError] = useMutation(
    DELETE_JOB_OPENING,
    {}
  );

  let navigate = useNavigate();
  var validator = useRef(new SimpleReactValidator({ autoForceUpdate: this }));
  const [, forceUpdate] = useState();
  const [modalExperience, setModalLocation] = useState(false);
  const locationId = useInput(locationID);
  const location = useInput("");
  const about = useInput("");
  const title = useInput("");
  const active = useInput(false);
  const phone = useInput("");

  const [addLociationJob] = useMutation(ADD_JOB_OPENING, {});

  console.log(jobs);
  const routeChange = (resumeId) => {
    let path = `${process.env.PUBLIC_URL}/job-edit`;
    let fullpath = resumeId ? path + "/" + resumeId : path;

    console.log(fullpath);
    navigate(fullpath);
  };

  const handleActiveJob = (e) => {
    console.log("This Triggered");
    active.setValue(!active.value);
  };

  const handleDeletejob = async (jobid) => {
    console.log(jobid);

    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        try {
          if (jobid) {
            await deleteJobOpening({
              variables: { employerOpeningsId: parseInt(jobid) },
            });

            refreshCallback();
          }
        } catch (err) {
          console.log(err);
        }
      }
    });
  };

  if (deleteError) return <p>{deleteError.message}</p>;
  const toggleLocationModal = () => {
    setModalLocation(!modalExperience);
  };

  const saveLocationJob = async () => {
    console.log("saving location job....");
    if (!validator.current.allValid() || !location.value) {
      validator.current.showMessages();
      forceUpdate(1);
      return toast.error("Cannot create job listing. Job Title required.");
    }

    try {
      //console.log({ variables: { employerId: GetUserID(), locationName: location.value, about: about.value, address1: address1.value, address2: address2.value, city: city.value, state: state.value, zipcode: zipcode.value, phone: phone.value  } });
      var result = await addLociationJob({
        variables: {
          employerId: parseInt(GetUserID()),
          employerUsersId: parseInt(GetUserID()),
          employerLocationsId: parseInt(locationId.value),
          title: location.value,
          active: true,
          public: active.value,
        },
      });

      if (result) {
        locationId.setValue(result.data.EmployerOpeningsID);
        var openingid = result.data.addEmployerOpening.EmployerOpeningsID;
        console.log(result);
        let path = `${process.env.PUBLIC_URL}/job-edit`;
        let fullpath = openingid ? path + "/" + openingid : path;
        refreshCallback();
        navigate(fullpath);
      }

      toggleLocationModal();

      toast("Your Job has been Saved", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="col-lg-5">
      <div className="card">
        <div className="card-header d-flex">
          <h4 className="card-title me-4 align-self-end">Job Listings</h4>
          <div className="card-options">
            <a
              className="card-options-collapse"
              href="javascript"
              data-toggle="card-collapse"
            >
              <i className="fe fe-chevron-up"></i>
            </a>
            <a
              className="card-options-remove"
              href="javascript"
              data-toggle="card-remove"
            >
              <i className="fe fe-x"></i>
            </a>
          </div>

          <Button color="success" onClick={toggleLocationModal}>
            {"Add Job Listing"}
          </Button>
        </div>
        <div className="table-responsive col-12">
          <table className="table card-table table-vcenter text-nowrap">
            <thead>
              <tr>
                <th>Title</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {jobs.map((job, i) => (
                <tr key={i}>
                  <td className="resume-title">{job.Title}</td>
                  <td className="text-end">
                    <button
                      className="btn btn-info btn-sm"
                      onClick={() => {
                        routeChange(job.EmployerOpeningsID);
                      }}
                    >
                      <i className="fa fa-pencil"></i> {"Edit"}
                    </button>
                  </td>
                  <td className="text-end">
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => {
                        handleDeletejob(job.EmployerOpeningsID);
                      }}
                    >
                      <i className="fa fa-trash"></i> {"Delete"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal isOpen={modalExperience} toggle={toggleLocationModal}>
        <ModalHeader toggle={toggleLocationModal}>New Job Listing</ModalHeader>
        <ModalBody>
          <form name="frmWorkExperience" id="frmWorkExperience">
            <div>
              <div className="mt-3">
                <label>Position Title:</label>
                <input
                  className="field form-control"
                  name="location"
                  id="location"
                  value={location.value}
                  onChange={location.onChange}
                />
                {validator.current.message(
                  "job title",
                  location.value,
                  "required"
                )}
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveLocationJob}>
            Create Job Listing
          </Button>
          <Button color="secondary" onClick={toggleLocationModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default JobManager;
