import { useState } from "react";
import LightBoxListTemplate from "./lightBoxItem";
import LightBoxModal from "./lightBoxModal";


const LightBox = ({images}) =>{

    const [selectedImageIndex, setSelectedImageIndex] = useState(null)
    console.log(selectedImageIndex);
   
    return (
        <div>
            
            <div className="row no-gutters">
                {images?.map((image, index) => <LightBoxListTemplate key={index} image={image} index={index} setSelectedIndex={setSelectedImageIndex} />)}
            </div>

            { selectedImageIndex != null &&  selectedImageIndex > -1 &&
            <LightBoxModal images={images} selectedImg={images[selectedImageIndex]} selectedIndex={selectedImageIndex} setSelectedIndex={setSelectedImageIndex} /> 
            }

            {/* <Modal images={images} selectedImg={images[selectedIndex]} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} /> */
            }
        </div>
    )
}
export default LightBox