import React, { Fragment, useState, useEffect, useRef } from "react";
import Header from "./common/header-component/header";
import Sidebar from "./common/sidebar-component/sidebar";
import RightSidebar from "./common/right-sidebar";
import Footer from "./common/footer";
import { ToastContainer } from "react-toastify";
import Loader from "./common/loader";
import { Outlet } from "react-router-dom";

const AppDesktopLayout = (props) => {
  const [sidebar, setSidebar] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(false);
  const wrapperRef = useRef(null);

  function showRightSidebar() {
    console.log("click");
    if (rightSidebar) {
      setRightSidebar(!rightSidebar);
      document.querySelector(".right-sidebar").classList.add("show");
    } else {
      setRightSidebar(!rightSidebar);
      document.querySelector(".right-sidebar").classList.remove("show");
    }
  }

  function closeRightSidebar() {
    setRightSidebar(false);
  }

  return (
    <Fragment>
      <Loader />
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          <Header messageclick={() => showRightSidebar()} />

          <Sidebar />

          <RightSidebar
            show={rightSidebar}
            onClickOutside={() => closeRightSidebar()}
          />
          <div className="page-body">
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer theme="dark" />
    </Fragment>
  );
};

export default AppDesktopLayout;
