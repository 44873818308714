import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useInput from '../../hooks/useInput.js'
import { useMutation } from '@apollo/client'
import { GetUserID } from '../../api/session.js'
import { Edit } from 'react-feather'
import { Link } from 'react-router-dom'
import {
  EDIT_EMPLOYER_LOCATION,
  UPDATE_LOCATION_PROFILE_IMAGE,
  UPDATE_LOCATION_BANNER_IMAGE,
  GET_EMPLOYER_LOCATION,
} from '../../api/db.js'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import CKEditors from 'react-ckeditor-component'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import getCroppedImg from '../../hooks/cropImage.js'
import Cropper from 'react-easy-crop'

const LocationContact = ({ form, onSave }) => {
  var profileurl = '/images/profile-employer.jpg'
  var bannerurl;
  const [updateRequired, setUpdateRequired] = useState(false)
  const [modelProfile, setModelProfile] = useState(false)
  const [imageFile, setImageFile] = useState()
  const [uploadProfileUrl, setUploadProfileUrl] = useState();
  const [modelBanner, setModelBanner] = useState(false);
  const [bannerFile, setBannerFile] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const CROP_AREA_ASPECT = 1;

  const toggleLocationImageUpload = () => {
    setModelProfile(!modelProfile)
  }

  const toggleBannerUpload = () => {
    setModelBanner(!modelBanner);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const locationName = useInput(form && form.locationName)
  const about = useInput(form && form.about)
  const address1 = useInput(form && form.address1)
  const address2 = useInput(form && form.address2)
  const city = useInput(form && form.city)
  const state = useInput(form && form.state)
  const zipcode = useInput(form && form.zip)
  const phone = useInput(form && form.phone)
  const lat = useInput(form && form.lat)
  const lon = useInput(form && form.lon)
  const active = useInput(form.active ?? true)
  //const distance = useInput(form && form.distance);
  //const profile = useInput((form && form.profile) ?? "/images/profile-employer.jpg");
  const videourl = useInput(form && form.videourl)
  const id = useInput(form.employerId ?? '')

  const handleFormChange = (data, e) => {
    data.onChange(e)

    setUpdateRequired(true)
  }

  const onChange = (evt) => {
    const newContent = evt.editor.getData()

    about.setValue(newContent)

    setUpdateRequired(true)
  }

  const [editLocationMutation, { saveloading }] = useMutation(
    EDIT_EMPLOYER_LOCATION,
    {
      refetchQueries: [
        {
          query: GET_EMPLOYER_LOCATION,
          variables: { employerLocationsId: form.locationId },
        },
      ],
    }
  )

  const toggleProfileUpload = () => {
    setModelProfile(!modelProfile);
  };

  const handleEditProfile = async (e) => {
    e.preventDefault()

    if (!locationName.value) {
      return toast.error('Location Name required')
    }

    try {
      await editLocationMutation({
        variables: {
          employerId: Number(id.value),
          employerLocationsId: Number(id.value),
          locationName: locationName.value,
          phone: phone.value,
          address1: address1.value,
          address2: address2.value,
          city: city.value,
          state: state.value,
          zipcode: zipcode.value,
          about: about.value,
          videoUrl: videourl.value,
        },
      })

      toast('Location changes have been saved', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      })

      onSave()
    } catch (err) {
      console.log(err)
    }
  }
  
    const handleImageSubmit = async () => {
      var imageUrl = "";
      const formData = new FormData();
  
      try {
        const croppedImage = await getCroppedImg(
          uploadProfileUrl,
          croppedAreaPixels,
          0
        );
  
        console.log("donee", { croppedImage });
  
        fetch(croppedImage)
          .then((res) => res.blob())
          .then((myBlob) => {
            const myFile = new File([myBlob], "image.jpeg", {
              type: myBlob.type,
            });
  
            formData.append("file", myFile);
  
            fetch("https://bc.empire-dev.com/api/upload", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.text())
          .then(async (result) => {
            imageUrl = result;
            
            try {
              await updateLocationProfileImage({
                variables: {
                  employerLocationsId: form.locationId,
                  profileImage: imageUrl,
                  caption: '',
                }});
  
              setBannerFile(null);
  
              onSave();
            } catch (err) {
              console.log(err);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
          });
  
        
        // if (!cropper) formData.append("file", bannerFile);
      } catch (e) {
        console.error(e);
      }
  
      if (imageUrl != "") {
        console.log(imageUrl);
      }
  
      
      setModelProfile(!modelProfile);
    };

    const handleBannerChange = (e) => {
      setBannerFile(e.target.files[0]);   
    };

  const handleBannerSubmit = async () => {

    var imageUrl = "";
    const formData = new FormData();

    formData.append("file", bannerFile);

    fetch("https://bc.empire-dev.com/api/upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then(async (result) => {
        imageUrl = result;

        try {
          
          await updateLocationBannerImage({
            variables: { employerLocationsId: form.locationId, bannerImage: imageUrl },
          });

          onSave();
        } catch (err) {
          console.log(err);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    if (imageUrl != "") {
      console.log(imageUrl);
    }

    setModelBanner(!modelBanner); 
  };

  const [updateLocationProfileImage, { inserting }] = useMutation(
    UPDATE_LOCATION_PROFILE_IMAGE,
    {
      refetchQueries: [
        {
          query: GET_EMPLOYER_LOCATION,
          variables: { employerLocationsId: form.locationId },
        },
      ],
    }
  )

  const [updateLocationBannerImage, { bannerinserting }] = useMutation(
    UPDATE_LOCATION_BANNER_IMAGE,
    {
      refetchQueries: [
        {
          query: GET_EMPLOYER_LOCATION,
          variables: { employerLocationsId: form.locationId },
        },
      ],
    }
  )

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
    setUploadProfileUrl(URL.createObjectURL(e.target.files[0]));
  }

  if (form.profile) {
    console.log(form.profile)
    profileurl = form.profile
  } else {
    profileurl = '/images/profile-employer.jpg'
  }

  if (form.banner && form.banner != undefined) {
    bannerurl = form.banner;
  } else {
    bannerurl = "/images/social-back-default.jpg";
  }


  return (
    <>
      <div className="col-lg-5">
        <form className="card">
          <div className="card-body">
            <div className="row">
              <div className="row mb-2">
                <div className="col-auto">
                  <div className="avartar">
                    <img
                      className="img-70 rounded-circle lazyloaded blur-up"
                      alt=""
                      src={profileurl}
                    />
                    <div className="profile-edit">
                      <Link
                        to="#"
                        onClick={(e) => toggleLocationImageUpload(e)}
                      >
                        <Edit />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <h3 className="mb-1">{locationName.value}</h3>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-label mb-3">Location Name</label>
                  <input
                    className="form-control mb-4"
                    name="locationName"
                    type="text"
                    placeholder="Company"
                    value={locationName.value}
                    onChange={(e) => handleFormChange(locationName, e)}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label className="form-label mb-3">Address 1</label>
                  <input
                    className="form-control mb-4"
                    type="text"
                    placeholder="Address 1"
                    name="address1"
                    value={address1.value}
                    onChange={(e) => handleFormChange(address1, e)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mb-5">
                  <label className="form-label mb-3">Address 2</label>
                  <input
                    className="form-control mb-4"
                    type="text"
                    placeholder="Address 2"
                    name="address2"
                    value={address2.value}
                    onChange={(e) => handleFormChange(address2, e)}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-5">
                <div className="form-group mb-3">
                  <label className="form-label mb-3">City</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="City"
                    name="city"
                    value={city.value}
                    onChange={(e) => handleFormChange(city, e)}
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label className="form-label mb-4">State</label>
                  <select
                    value={state.value}
                    name="state"
                    id="state"
                    onChange={(e) => handleFormChange(state, e)}
                    className="form-control btn-square"
                  >
                    <option value="0">Select A State</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA"> California</option>
                    <option value="CO"> Colorado</option>
                    <option value="CT"> Connecticut</option>
                    <option value="DE"> Delaware</option>
                    <option value="DC"> District of Columbia</option>
                    <option value="FL"> Florida</option>
                    <option value="GA"> Georgia</option>
                    <option value="HI"> Hawaii</option>
                    <option value="ID"> Idaho</option>
                    <option value="IL"> Illinois</option>
                    <option value="IN"> Indiana</option>
                    <option value="IA"> Iowa</option>
                    <option value="KS"> Kansas</option>
                    <option value="KY"> Kentucky</option>
                    <option value="LA"> Louisiana</option>
                    <option value="ME"> Maine</option>
                    <option value="MD"> Maryland</option>
                    <option value="MA"> Massachusetts</option>
                    <option value="MI"> Michigan</option>
                    <option value="MN"> Minnesota</option>
                    <option value="MS"> Mississippi</option>
                    <option value="MO"> Missouri</option>
                    <option value="MT"> Montana</option>
                    <option value="NE"> Nebraska</option>
                    <option value="NV"> Nevada</option>
                    <option value="NH"> New Hampshire</option>
                    <option value="NJ"> New Jersey</option>
                    <option value="NM"> New Mexico</option>
                    <option value="NY"> New York</option>
                    <option value="NC"> North Carolina</option>
                    <option value="ND"> North Dakota</option>
                    <option value="OH"> Ohio</option>
                    <option value="OK"> Oklahoma</option>
                    <option value="OR"> Oregon</option>
                    <option value="PA"> Pennsylvania</option>
                    <option value="RI"> Rhode Island</option>
                    <option value="SC"> South Carolina</option>
                    <option value="SD"> South Dakota</option>
                    <option value="TN"> Tennessee</option>
                    <option value="TX"> Texas</option>
                    <option value="UT"> Utah</option>
                    <option value="VT"> Vermont</option>
                    <option value="VA"> Virginia</option>
                    <option value="WA"> Washington</option>
                    <option value="WV"> West Virginia</option>
                    <option value="WI"> Wisconsin</option>
                    <option value="WY"> Wyoming</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="form-group mb-3">
                  <label className="form-label mb-3">Postal Code</label>
                  <input
                    className="form-control"
                    type="text"
                    name="zip"
                    placeholder="ZIP Code"
                    value={zipcode.value}
                    onChange={(e) => handleFormChange(zipcode, e)}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mb-3">
                  <label className="form-label mb-3">Phone</label>
                  <input
                    className="form-control mb-4"
                    type="text"
                    placeholder="Phone"
                    name="phone"
                    value={phone.value}
                    onChange={(e) => handleFormChange(phone, e)}
                  />
                </div>
              </div>
              {/*   <div className="col-sm-5 col-md-5">
                <div className="form-group mb-3">
                <label className="form-label mb-3">Lat</label>
                <input className="form-control mb-4" type="text" placeholder="Latitude" name="lat" value={lat.value} onChange={(e) =>handleFormChange(lat, e)} />
                </div>
            </div>
            <div className="col-sm-5 col-md-5">
                <div className="form-group mb-3">
                <label className="form-label mb-3">Lon</label>
                <input className="form-control mb-4" type="text" placeholder="Longitude" name="lon" value={lon.value} onChange={(e) =>handleFormChange(lon, e)} />
                </div>
            </div>  */}
            </div>
          </div>
          <div className="col-md-10 card-footer text-end"></div>
        </form>
      </div>
      <div className="col-lg-7">
        <div className="card">
          <div className="card-body">
            <form>
              <div className="form-group col-md-12">
                <label className="form-label">
                  <h3>About Us</h3>
                </label>
                <CKEditors
                  activeclassName="p10"
                  content={about.value}
                  events={{
                    change: onChange,
                  }}
                />
              </div>
              <div className="form-group col-md-12 videourl">
                <label className="form-label">Video Url</label>
                <p>
                  To retrieve the proper url:
                  <br />
                  go to your video on YouTube &gt; Right click the video &gt;
                  Choose "Copy Video URL" and paste it below.
                </p>
                <input
                  className="form-control"
                  placeholder="Video Url"
                  name="videoUrl"
                  value={videourl.value}
                  onChange={(e) => handleFormChange(videourl, e)}
                />
              </div>
              <div className="form-group mt-2">
                <label className="form-label d-block">Banner Image</label>
                <div className="btn btn-xs p-0 mt-5">
                  <Link
                    to="#"
                    className="position-absolute bg-white rounded"
                    onClick={(e) => toggleBannerUpload(e)}
                  >
                    <Edit className="w-100" />
                  </Link>
                </div>
                <img
                  className="img-100 rounded lazyloaded blur-up"
                  alt=""
                  src={bannerurl}
                />
              </div>
              <div className="form-group col-md-12 videourl">
                <div className="btn-showcase">
                  <button
                    className="btn btn-primary"
                    onClick={handleEditProfile}
                    disabled={saveloading || !updateRequired}
                    type="submit"
                  >
                    {' '}
                    {saveloading ? 'Updating Profile' : 'Update Profile'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal isOpen={modelProfile} toggle={toggleProfileUpload}>
          <ModalHeader toggle={toggleProfileUpload}>Add Image</ModalHeader>
          <ModalBody>
            <form>
              <div className="form-group">
                <label className="col-form-label" htmlFor="message-text">
                  Image:
                </label>
                <input
                  className="form-control"
                  type="file"
                  onChange={(e) => handleImageChange(e)}
                />
              </div>
              <div>
                  {imageFile && (
                    <div style={{ height: 400, width: 400 }} className="cropper">
                      <Cropper
                        image={uploadProfileUrl}                        
                        aspect={CROP_AREA_ASPECT}
                        cropShape="round"
                        crop={crop}
                        zoom={zoom}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}                       
                        onCropComplete={onCropComplete}                       
                      />
                    </div>
                  )}
                </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={(e) => handleImageSubmit(e)}>
              Update Image
            </Button>
            <Button color="secondary" onClick={(e) => toggleProfileUpload(e)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={modelBanner} toggle={toggleBannerUpload}>
          <ModalHeader toggle={toggleBannerUpload}>Add Image</ModalHeader>
          <ModalBody>
            <form>
              <div className="form-group">
                <label className="col-form-label" htmlFor="message-text">
                  Banner Image:
                </label>
                <input
                  className="form-control"
                  type="file"
                  onChange={(e) => handleBannerChange(e)}
                />
                <br />                
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={(e) => handleBannerSubmit(e)}>
              Update Image
            </Button>
            <Button color="secondary" onClick={(e) => toggleBannerUpload(e)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
    </>
  )
}
export default LocationContact
