import React, { Fragment } from "react";
import { Mail } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import {
  CONVERSATIONS,
  GET_MESSAGE_NOTIFICATION,
  GET_PORTFOLIO,
} from "../../../api/db.js";
import { GetUserID, GetUserRole } from "../../../api/session.js";
import useRefresh from "../../../hooks/refresh.js";

const Notifications = (props) => {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(CONVERSATIONS, {
    pollInterval: 5000,
  });

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );
  if (error) return <p>Error : {error.message}</p>;

  const goToMessages = () => {
    navigate("/messages");
  };

  var messageCount = 0;

  data.getConversations.map((x) => {
    if (x.UnReadCount != 0) messageCount = messageCount + x.UnReadCount;
  });

  return (
    <Fragment>
      <div className="d-block">
        {messageCount != 0 && (
          <ul className="notification-dropdown onhover-show-div p-0">
            <li>
              Notifications{" "}
              <span className="badge rounded-pill badge-primary pull-right">
                {"1"}
              </span>
            </li>
            <li>
              <div className="d-flex">
                <div className="flex-grow-1" onClick={() => goToMessages()}>
                  <h6 className="mt-0">
                    <span>
                      <Mail />
                    </span>
                    {"You have new messages"}
                  </h6>
                  <p className="mb-0">
                    {"You have " + messageCount + " unread messages"}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        )}

        {messageCount == 0 && (
          <ul className="notification-dropdown onhover-show-div p-0">
            <li>Notifications</li>
            <li>
              <div className="d-flex">
                <div className="flex-grow-1" onClick={() => goToMessages()}>
                  <h6 className="mt-0">
                    <span>
                      <Mail />
                    </span>
                    {"You have no new messages"}
                  </h6>
                  <p className="mb-0">{"You have no new messages"}</p>
                </div>
              </div>
            </li>
          </ul>
        )}
      </div>
    </Fragment>
  );
};

export default Notifications;
