import React, { Fragment } from "react";
import man from "../../../assets/images/dashboard/user.png";
import { Link } from "react-router-dom";
import { Edit } from "react-feather";
import { useQuery } from "@apollo/client";
import { GET_USER_INFO } from "../../../api/db.js";
import { GetUserID } from "../../../api/session.js";

const UserPanel = () => {
  var url = "";
  var firstName = "";
  var lastName = "";
  var role = "";
  var userId = GetUserID();

  const { loading, error, data } = useQuery(GET_USER_INFO, {
    variables: { userId: userId },
  });

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );
  if (error) return <p>Error : {error.message}</p>;

  if (data.user) {
    firstName = data.user.FirstName;
    lastName = data.user.LastName;
    role = data.user.UserType.Type;
  }

  if (data.user.ProfileImage) {
    url = data.user.ProfileImage;
  } else {
    url = "/images/profile-student.jpg";
  }

  return (
    <Fragment>
      <div className="sidebar-user text-center">
        <div className="avatar">
          <Link to={`${process.env.PUBLIC_URL}/settings/profile`}>
            <img
              className="img-60 rounded-circle lazyloaded blur-up"
              src={url ? url : man}
              alt="#"
            />
          </Link>
        </div>
        <h6 className="mt-3 f-14"> {firstName + " " + lastName}</h6>
        <p>{role}</p>
      </div>
    </Fragment>
  );
};

export default UserPanel;
