import React, { useState, useEffect, useRef } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { CONVERSATIONS, MARK_CONVERSATION_READ } from "../../api/db";
import Conversation from "../conversations/conversation";
import useInput from "../../hooks/useInput";
import { Link } from "react-router-dom";

const RightSidebar = (props) => {
  const navigate = useNavigate();
  const filter = useInput("");
  const [currentConsersation, setActiveConservation] = useState("");
  const { loading, error, data, refetch } = useQuery(CONVERSATIONS, {});
  const ref = useRef(null);
  const { onClickOutside } = props;

  useEffect(() => {
    const handleClickOutside = (event) => {
      var btn = document.getElementById("btn-mobile-conversations");
      if (!btn) {
        console.log(onClickOutside);
        refetch();
        if (ref.current && !ref.current.contains(event.target)) {
          onClickOutside && onClickOutside(false);
        }
      }
      document.addEventListener("click", handleClickOutside, true);
      /*return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };*/
    };
  }, [onClickOutside]);

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );
  if (error) return <p>Error: {error.message}</p>;

  const setConversation = (conversationid) => {
    setActiveConservation(conversationid);

    document.querySelector(".right-sidebar").classList.remove("show");

    navigate("/messages/" + conversationid, { replace: true });
  };

  function search(conversation) {
    if (filter.value === "") return true;

    return conversation.Users.some(
      (u) =>
        u.FirstName.toLowerCase().includes(filter.value.toLowerCase()) ||
        u.LastName.toLowerCase().includes(filter.value.toLowerCase())
    );
  }

  return (
    <div ref={ref}>
      <div
        className={"right-sidebar " + (props.show ? "show" : "")}
        id="right_side_bar"
      >
        <div className="friend-list-search mt-0">
          <input
            type="text"
            placeholder="search friend"
            value={filter.value}
            onChange={(e) => filter.onChange(e)}
          />
          <i className="fa fa-search"></i>
        </div>
        <div className="chat-box custom-scrollbar">
          <div className="people-list friend-list">
            <ul className="list">
              {data.getConversations
                .filter((x) => search(x))
                .map((conversationItem) => {
                  return (
                    <Conversation
                      key={conversationItem.ConversationID}
                      isActive={false}
                      conversation={conversationItem}
                      onPress={setConversation}
                    />
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSidebar;
