import React, { Fragment, useState, useEffect, useRef } from 'react'
import Header from './common/header-component/header'
import Sidebar from './common/sidebar-component/sidebar'
import RightSidebar from './common/right-sidebar'
import Footer from './common/footer'
import { ToastContainer } from 'react-toastify'
import Loader from './common/loader'
import { Outlet } from 'react-router-dom'
import Authorize from './shared/authorize'
import AppMobileLayout from './app-mobile'
import AppDesktopLayout from './app-desktop'

const AppLayout = (props) => {
  const [mobile, setMobile] = useState(window.innerWidth <= 510)

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return (
    <>
      {mobile && <AppMobileLayout />}

      {!mobile && <AppDesktopLayout />}
    </>
  )
}

export default AppLayout
