import React,{Fragment} from 'react';
import { useNavigate } from "react-router-dom";
import { Button} from 'reactstrap';

const LocationJobCard = ({location, job, callback, deleteJob}) => {
    console.log("location");
   console.log(location);
    let navigate = useNavigate(); 

  const editJobLocation = () =>
  {
    let path =  `${process.env.PUBLIC_URL}/job-edit`;
    let fullpath = job.EmployerOpeningsID ? path + "/" + job.EmployerOpeningsID : path;
             
    navigate(fullpath);     
  }

  const viewApplicants = () =>
  {
    let path =  `${process.env.PUBLIC_URL}/applicants`;
    let fullpath = job.EmployerOpeningsID ? path + "/" + job.EmployerOpeningsID : path;
             
    navigate(fullpath);     
  }

  let date = (new Date(job.ListingDate)).getTime();
  let today = (new Date()).getTime();
  let msDay = 24*60*60*1000;

  let days = Math.floor((today - date)/ msDay);

  let profile = location.ProfileImage;

  if(!profile)
{
    profile = "/images/profile-employer.jpg?w=165&amp;h=165&amp;mode=crop";
}

    return (
        <Fragment>
           <div className="col-12 col-md-4">
            <div className="card shadow-0 mt-3">
                <div className="card-header pb-0 pt-0 mb-1 px-0">
                    <div className='connection mb-0 mx-0 rounded-top'>
                    <div className="blur">
                        <img height={260} className='rounded-top-left' alt="" src="/images/blur2.jpg?h=260&amp;mode=crop&amp;anchor=topcenter"/>
                    </div>
                    <img  alt=""  src={profile} className="connect-left small"/>
                    <div className="grouped">
                        <h4 className='text-white'>{job.Title}</h4>
                        <p className="slant">{location.City}, {location.State} - {days} Days Ago</p>
                    </div>
                </div>
                                    
                </div>
                <div className="card-body pt-1">
                   
                    <p className="count">
                        {(job.ApplicationTargets && job.ApplicationTargets.Resumees.length) ?? 0} Applicants                                                         
                    </p>
                    <p className="count">
                       {job.Public ? "Public" : "Private"} Listing                                                         
                    </p>
                    <div className='btn-showcase'>
                    <Button className='btn-sm' color="primary" onClick={()=>editJobLocation()}>Edit Job</Button>  
                    {job.ApplicationTargets && job.ApplicationTargets.Resumees &&
                         <Button className='btn-sm' color="success" disabled={((job.ApplicationTargets && job.ApplicationTargets.Resumees.length) ?? 0) == 0} onClick={()=>viewApplicants()}>Applications</Button>
                    }
                    <Button className='btn-sm' color="danger" onClick={(e)=>deleteJob(job.EmployerOpeningsID)}>Delete</Button>
                    </div>                   
                </div>
            </div>
        </div>               
        </Fragment>
    );

}
export default LocationJobCard;