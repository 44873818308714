import React, { Fragment, useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  GET_EMPLOYER_LOCATION_JOB,
  EDIT_JOB_OPENING,
  GET_PORTFOLIO,
  DELETE_PORTFOLIO_ITEM,
} from "../../../../api/db.js";
import { GetUserID } from "../../../../api/session.js";
import Breadcrumb from "../../../common/breadcrumb.js";
import LocationJobContact from "../edit/location-job-info.js";
import JobPreferenceManager from "../edit/location-job-pref.js";
import useInput from "../../../../hooks/useInput.js";
import { Button } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JobEdit from "../edit/location-job-edit.js";
import { errorCheck } from "../../../common/functions.js";

const LocationJob = () => {
  var { id } = useParams();

  const { loading, error, data, refetch } = useQuery(
    GET_EMPLOYER_LOCATION_JOB,
    {
      variables: { employerOpeningsId: parseInt(id) },
      fetchPolicy: "network-only",
    }
  );

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );

  if (error) {
    return errorCheck(error);
  }

  if (data) {
    console.log("job-details", data.employerOpening);
    var job = data.employerOpening;
  }

  return (
    <Fragment>
      <JobEdit job={job} callback={refetch}></JobEdit>
    </Fragment>
  );
};

export default LocationJob;
