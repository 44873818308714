import React, { useState, Fragment, useEffect } from "react";
// import ChartistGraph from 'react-chartist';

import { useQuery } from "@apollo/client";

import { SCHOOL_STUDENTS } from "../../api/db";
import { errorCheck } from "../common/functions";

const SchoolUsers = (props) => {
  const { loading, error, data } = useQuery(SCHOOL_STUDENTS, {
    variables: { locid: parseInt(props.locationId) },
    fetchPolicy: "no-cache",
  });

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );

  console.log(data);
  return (
    <Fragment>
      <div className="col-xl-8 xl-50">
        <div className="card" data-intro="This is Our Topper List">
          <div className="card-header">
            <h5>Students</h5>
          </div>
          <div className="card-body">
            <div className="table-responsive topper-lists">
              <table className="table table-bordernone">
                <tbody>
                  {data.school.SchoolStudents &&
                    data.school.SchoolStudents.map((student) => (
                      <tr key={student.StudentID}>
                        <td>
                          <div className="d-inline-block align-self-center">
                            {student.ProfileImage && (
                              <img
                                className="img-radius img-40 align-top m-r-15 rounded-circle"
                                src={student.ProfileImage}
                                alt=""
                              />
                            )}

                            <div className="d-inline-block">
                              <span className="f-w-600">
                                {student.FirstName} {student.LastName}
                              </span>

                              {student.HoursCompleted && (
                                <p>{student.HoursCompleted} Hours Completed</p>
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-inline-block text-center">
                            {student.Phone && (
                              <>
                                <span className="f-w-600">{student.Phone}</span>
                                <p>Phone</p>
                              </>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="d-inline-block text-center">
                            {student.Phone && (
                              <>
                                <span className="f-w-600">
                                  {student.City}, {student.State}
                                </span>
                                <p>City, State</p>
                              </>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="d-inline-block text-center">
                            {student.HoursCompleted &&
                              student.HoursToGraduate && (
                                <span className="f-w-600">
                                  {student.HoursCompleted +
                                    "/" +
                                    student.HoursToGraduate}
                                </span>
                              )}
                            {!student.HoursCompleted &&
                              !student.HoursToGraduate && (
                                <span className="f-w-600">N/A</span>
                              )}

                            <p>Hrs Completed/Graduate</p>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SchoolUsers;
