import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './routes/home.js'
import Auth from './components/auth/auth.js'
import Register from './components/auth/auth-register.js'
import ResetPassword from './components/auth/auth-resetpassword.js'

const PublicRouter = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/signup" element={<Register />} />
        <Route exact path="/login" element={<Auth />} />
        <Route exact path="/resetpassword" element={<ResetPassword />} />
        <Route exact path="/verified" element={<Auth />} />
        <Route exact path="/verifyfailed" element={<Auth />} />
      </Routes>
    </>
  )
}

export default PublicRouter
