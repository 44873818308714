import React, { Fragment, useState } from 'react'
import { Collapse, UncontrolledTooltip } from 'reactstrap'

const EmployerViewLeftBar = ({ form }) => {
  const [isProfile, setisProfile] = useState(true)
  const [isMutual, setisMutual] = useState(true)
  const [isActivity, setisActivity] = useState(true)

  return (
    <Fragment>
      <div className="col-xl-12">
        <div className="card mt-0">
          <div className="card-header">
            <h5 className="mb-0">
              <button
                className="btn btn-link ps-0 border-0"
                onClick={() => setisProfile(!isProfile)}
                data-toggle="collapse"
                data-target="#collapseicon5"
                aria-expanded={isProfile}
                aria-controls="collapseicon5"
              >
                EMPLOYER PROFILE
              </button>
            </h5>
          </div>
          <Collapse isOpen={isProfile}>
            <div className="card-body socialprofile filter-cards-view">
              <h5 className="font-primary f-w-600">{form.locationName}</h5>
              <div className="social-btngroup">{form.about}</div>
              <div className="likes-profile text-start">
                <h6>
                  <i className="fa fa-store font-danger"></i>
                  {form.locationCount} Locations
                </h6>
              </div>
              <div className="text-start">
                {form.jobCount} {'Jobs Available'}
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </Fragment>
  )
}

export default EmployerViewLeftBar
