import React, { Fragment, useState } from 'react'
import { Alert } from 'reactstrap'
import { useQuery, gql, useLazyQuery, useMutation } from '@apollo/client'
import logo from '../../assets/images/endless-logo.png'
import {
  SetUserJWT,
  LoginSuccess,
  SaveLoginPageAlert,
} from '../../api/session.js'
import {
  GET_USER_AUTH,
  GET_USER_INFO,
  LOGIN_USER_GRAPH,
  RESET_PASSWORD,
} from '../../api/db.js'
import { useParams, useSearchParams } from 'react-router-dom'

const ResetPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const code = searchParams.get('code')
  const userid = searchParams.get('userid')
  const email = searchParams.get('email')

  const [username, setUsername] = useState(email)
  const [password, setPassword] = useState('')
  const [confirmpassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value)
  const handlePasswordChange = (e) => setPassword(e.target.value)

  const [resetPassword, { loading: loading, error: saveerror, data: data }] =
    useMutation(RESET_PASSWORD)

  if (saveerror) return <p>Error:{saveerror.message}</p>

  const handleReset = async (e) => {
    e.preventDefault()

    if (!password) return alert('Please enter a new password')

    if (password != confirmpassword) {
      setError('Password and Confirm Password do not match')
      return
    }

    try {
      var result = await resetPassword({
        variables: { email: email, password: password, userIdBase: userid },
      })
      
      if (result && result.data && result.data.resetPassword.Success) {
        SaveLoginPageAlert('Password Reset Successfully')

        window.location.href = '/login'
      }
    } catch (err) {
      console.log(err)

      setError(err.message)
      setPassword('')
      setConfirmPassword('')
    }
  }

  const showloader = loading || data
  const showForm = !success

  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="auth-bg">
          <div className="authentication-box">
            <div className="text-center">
              <img src={logo} alt="" />
            </div>
            <div className="card mt-4">
              <div className="card-body">
                <div className="text-center">
                  <h4>Reset Password</h4>
                  <h6>{'Enter your New Password'} </h6>
                  {success && (
                    <Alert color="success dark alert-dismissible">
                      <p>{success}</p>
                    </Alert>
                  )}
                  {error && (
                    <Alert color="danger dark alert-dismissible">
                      <p>{error}</p>
                    </Alert>
                  )}
                </div>
                {showloader && (
                  <div className="theme-form">
                    <div className="loader-box" style={{ display: 'flex' }}>
                      <div
                        className="loader"
                        style={{ alignItems: 'center', width: '100%' }}
                      >
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                      </div>
                    </div>
                  </div>
                )}

                {!showloader && (
                  <form className="theme-form" onSubmit={(e) => handleReset(e)}>
                    {showForm && (
                      <>
                        <div className="form-group">
                          <label className="col-form-label pt-0">
                            Password
                          </label>
                          <input
                            className="form-control"
                            type="password"
                            required=""
                            placeholder="Password"
                            onChange={(e) => handlePasswordChange(e)}
                          />
                        </div>
                        <div className="form-group">
                          <label className="col-form-label">
                            Confirm Password
                          </label>
                          <input
                            className="form-control"
                            type="password"
                            required=""
                            placeholder="Confirm Password"
                            onChange={(e) => handleConfirmPasswordChange(e)}
                          />
                        </div>
                        <div className="form-group form-row mt-3 mb-0 d-grid">
                          <button className="btn btn-primary" type="submit">
                            Reset Password
                          </button>
                        </div>
                      </>
                    )}
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default ResetPassword
