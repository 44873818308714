import React from 'react'
import {
  format,
  formatDistance,
  formatRelative,
  subDays,
  getUnixTime,
} from 'date-fns'
import ConversationUserIcon from './conversation-user-icon'
import Breadcrumb from '../common/breadcrumb'
import { IsUserLoggedIn } from '../../api/session'

const Conversation = ({ isActive, conversation, onPress, onRead }) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const lastItem = conversation.Messages[conversation.Messages.length - 1]
  const users = conversation.Users.filter((item) => item.UserID !== user.UserID)
  if (isActive && conversation.UnReadCount > 0) {
    onRead()
  }

  return users.map((x, i) => {
    var loggedIn = IsUserLoggedIn(x.UserID)
    console.log(conversation.UnReadCount)

    return (
      <li className={`clearfix ${isActive ? 'active' : ''}`} key={i}>
        <a
          key={x.UserID}
          className={isActive ? 'active' : ''}
          href="#!"
          onClick={() => onPress(conversation.ConversationID)}
        >
          <ConversationUserIcon
            user={{
              Name: x.FirstName + ' ' + x.LastName,
              ProfileImage: x.ProfileImage,
            }}
            unreadcount={conversation.UnReadCount}
            key={x.UserID}
          />
          <div
            className={`status-circle ${
              loggedIn == true ? 'online' : 'offline'
            }`}
          ></div>
          <div className="about">
            <div className="name">{x.FirstName + ' ' + x.LastName}</div>
            <div className="status">
              <span>
                {lastItem.Message.length > 16
                  ? `${lastItem.Message.substring(0, 16)}...`
                  : lastItem.Message}
              </span>
              {conversation.UnReadCount > 0 && !isActive && (
                <svg height={18} width={50}>
                  <circle cx={12} cy={12} r={5} fill="red"></circle>
                </svg>
              )}
            </div>
          </div>
        </a>
      </li>
    )
  })
}

export default Conversation
