import React, { useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { GetUserID } from "../../../api/session.js";
import { Edit } from "react-feather";
import { Link } from "react-router-dom";
import Cropper from "react-easy-crop";
import {
  EDIT_PROFILE_IMAGE,
  EDIT_BANNER_IMAGE,
  UPDATE_USER_PROFILE_IMAGE,
  UPDATE_USER_BANNER_IMAGE,
} from "../../../api/db.js";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import getCroppedImg from "../../../hooks/cropImage.js";
import { Image } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";

const ProfileImageEdit = ({
  isStudent,
  profile,
  banner,
  FirstName,
  LastName,
  RoleName,
  onSave,
  showLoading,
}) => {
  var profileurl;
  var bannerurl;

  const [modelProfile, setModelProfile] = useState(false);
  const [uploadProfileUrl, setUploadProfileUrl] = useState();
  const [uploadBannerUrl, setUploadBannerUrl] = useState();
  const [modelBanner, setModelBanner] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [bannerFile, setBannerFile] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [bannerError, setBannerError] = useState();
  const [imageError, setImageError] = useState();
  const CROP_AREA_ASPECT = 1;

  const toggleProfileUpload = () => {
    setModelProfile(!modelProfile);
  };

  const toggleBannerUpload = () => {
    setModelBanner(!modelBanner);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleImageChange = (e) => {
    let regex = new RegExp(/[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/);

    if (!regex.test(e.target.files[0].name)) {
      setImageError("File must be an image type: .png, .jpg, .jpeg, .gif");
      return toast.error("File Type not valid");
    }

    setImageFile(e.target.files[0]);
    setUploadProfileUrl(URL.createObjectURL(e.target.files[0]));
  };

  const handleBannerChange = (e) => {
    let regex = new RegExp(/[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/);

    if (!regex.test(e.target.files[0].name)) {
      setBannerError("File must be an image type: .png, .jpg, .jpeg, .gif");
      return toast.error("File Type not valid");
    }
    setBannerFile(e.target.files[0]);
    setUploadBannerUrl(URL.createObjectURL(e.target.files[0]));
  };

  const handleImageSubmit = async () => {
    var imageUrl = "";
    const formData = new FormData();

    try {
      const croppedImage = await getCroppedImg(
        uploadProfileUrl,
        croppedAreaPixels,
        0
      );

      console.log("donee", { croppedImage });

      fetch(croppedImage)
        .then((res) => res.blob())
        .then((myBlob) => {
          const myFile = new File([myBlob], "image.jpeg", {
            type: myBlob.type,
          });

          formData.append("file", myFile);

          fetch("https://bc.empire-dev.com/api/upload", {
            method: "POST",
            body: formData,
          })
            .then((response) => response.text())
            .then(async (result) => {
              imageUrl = result;

              try {
                if (isStudent)
                  await updateProfileImage({
                    variables: { userId: GetUserID(), profileImage: imageUrl },
                  });

                await updateUserProfileImage({
                  variables: { userId: GetUserID(), profileImage: imageUrl },
                });

                setBannerFile(null);

                onSave();
              } catch (err) {
                console.log(err);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        });

      // if (!cropper) formData.append("file", bannerFile);
    } catch (e) {
      console.error(e);
    }

    if (imageUrl != "") {
      console.log(imageUrl);
    }

    setModelProfile(!modelProfile);
  };

  const handleBannerSubmit = async () => {
    var imageUrl = "";
    const formData = new FormData();

    formData.append("file", bannerFile);

    fetch("https://bc.empire-dev.com/api/upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.text())
      .then(async (result) => {
        imageUrl = result;

        try {
          if (isStudent)
            await updateBannerImage({
              variables: { userId: GetUserID(), bannerImage: imageUrl },
            });

          await updateUserBannerImage({
            variables: { userId: GetUserID(), bannerImage: imageUrl },
          });

          onSave();
        } catch (err) {
          console.log(err);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    if (imageUrl != "") {
      console.log(imageUrl);
    }

    setModelBanner(!modelBanner);
  };

  const [updateProfileImage, { inserting }] = useMutation(
    EDIT_PROFILE_IMAGE,
    {}
  );

  const [updateBannerImage, { bannerInserting }] = useMutation(
    EDIT_BANNER_IMAGE,
    {}
  );

  const [updateUserProfileImage] = useMutation(UPDATE_USER_PROFILE_IMAGE, {});

  const [updateUserBannerImage] = useMutation(UPDATE_USER_BANNER_IMAGE, {});

  if (profile && profile != undefined) {
    profileurl = profile;
  } else {
    profileurl = "/images/profile-student.jpg";
  }

  if (banner && banner != undefined) {
    bannerurl = banner;
  } else {
    bannerurl = "/images/social-back-default.jpg";
  }

  return (
    <>
      <form>
        <div className="row mb-2">
          <div className="col-auto">
            <div className="avartar">
              <div className="btn btn-xs p-0 mt-5">
                <Link
                  to="#"
                  className="position-absolute bg-white rounded"
                  onClick={(e) => toggleProfileUpload(e)}
                >
                  <Edit className="w-75" />
                </Link>
              </div>
              <img
                className="img-80 rounded-circle lazyloaded blur-up"
                alt=""
                src={profileurl}
              />
            </div>
          </div>
          <div className="col">
            <h3 className="mb-1">
              {FirstName} {LastName}{" "}
            </h3>
            <p className="mb-4">{RoleName}</p>
          </div>
        </div>
        <div className="form-group mt-2">
          <label className="form-label d-inline pe-2">Banner Image</label>
          <Link
            onClick={(e) => toggleBannerUpload(e)}
            to="#"
            className="bg-white"
          >
            <Edit className="position-absolute py-1" />
          </Link>
          {!showLoading && (
            <Image
              src={bannerurl}
              fluid
              onClick={(e) => toggleBannerUpload(e)}
              className="mt-2"
            />
          )}

          {showLoading && (
            <div className="loader-box">
              <span className="rotate groove"></span>
            </div>
          )}
        </div>
      </form>

      <Modal isOpen={modelProfile} toggle={toggleProfileUpload}>
        <ModalHeader toggle={toggleProfileUpload}>Add Image</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                Image:
              </label>
              <input
                className="form-control"
                type="file"
                onChange={(e) => handleImageChange(e)}
              />
              {imageError && <span className="txt-danger">{imageError}</span>}
            </div>
            <div>
              {imageFile && (
                <div style={{ height: 400, width: 400 }} className="cropper">
                  <Cropper
                    image={uploadProfileUrl}
                    aspect={CROP_AREA_ASPECT}
                    cropShape="round"
                    crop={crop}
                    zoom={zoom}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                </div>
              )}
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={!imageFile}
            onClick={(e) => handleImageSubmit(e)}
          >
            Update Image
          </Button>
          <Button color="secondary" onClick={(e) => toggleProfileUpload(e)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modelBanner} toggle={toggleBannerUpload}>
        <ModalHeader toggle={toggleBannerUpload}>Add Image</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                Banner Image:
              </label>
              <input
                className="form-control"
                type="file"
                onChange={(e) => handleBannerChange(e)}
              />
              {bannerError && <span className="txt-danger">{bannerError}</span>}
              <br />
              {bannerurl && !uploadBannerUrl && <Image src={bannerurl} fluid />}
              {uploadBannerUrl && <Image src={uploadBannerUrl} fluid />}
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={!uploadBannerUrl}
            onClick={(e) => handleBannerSubmit(e)}
          >
            Update Image
          </Button>
          <Button color="secondary" onClick={(e) => toggleBannerUpload(e)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default ProfileImageEdit;
