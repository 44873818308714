import React, { Fragment, useState } from "react";
import { MoreVertical, ThumbsUp, UserPlus, MessageSquare } from "react-feather";
import LocationViewPhotosPanel from "../locations/view/location-view-photos-panel";
import { useQuery } from "@apollo/client";
import { GET_USER_JOB_APPLICATIONS } from "../../api/db";
import { Collapse } from "reactstrap";

const ProfileApplications = ({ id }) => {
  const [isApplicationHistory, setIsApplicationHistory] = useState(false);

  const { loading, error, data, refetch } = useQuery(
    GET_USER_JOB_APPLICATIONS,
    { variables: { userId: parseInt(id) } }
  );

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );

  return (
    <Fragment>
      <div className="col-sm-12 col-md-12 mb-3">
        <div className="card mt-0">
          <div className="card-header">
            <h4 className="card-title me-4 align-self-end">
              <button
                className="btn ps-0 border-0 "
                onClick={() => setIsApplicationHistory(!isApplicationHistory)}
                data-toggle="collapse"
                data-target="#collapseicon3"
                aria-expanded={isApplicationHistory}
                aria-controls="collapseicon3"
              >
                Application History
              </button>
            </h4>
          </div>
          <Collapse isOpen={isApplicationHistory}>
            <div
              className="collapse show"
              id="collapseicon3"
              data-parent="#accordion"
              aria-labelledby="collapseicon3"
            >
              <div className="card-body avatar-showcase">
                <div
                  className="default-according style-1 faq-accordion job-accordion"
                  id="accordionoc3"
                >
                  <table className="table">
                    <tr>
                      <th scope="col">Job Opening</th>
                      <th scope="col">Employer</th>
                      <th scope="col">Resumee Submitted</th>
                      <th scope="col">Date Submitted</th>
                    </tr>
                    {data.applications.map((app) => {
                      return (
                        <tr>
                          <td>{app.EmployerOpeningTitle}</td>
                          <td>
                            {app.EmployerName} - {app.LocationName}
                          </td>
                          <td>{app.ResumeeTitle} </td>
                          <td>
                            {new Date(app.DateTimeApplied).toLocaleString(
                              "en-US"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
    </Fragment>
  );
};

export default ProfileApplications;
