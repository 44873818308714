import React, { Fragment, useState, useEffect } from 'react'
import Breadcrumb from '../common/breadcrumb.js'
import { useQuery, useMutation, operationName } from '@apollo/client'
import {
  GET_EMPLOYER_LOCATIONS,
  ADD_LOCATION,
  EMPLOYER_JOB_SEARCH,
} from '../../api/db.js'
import { GetUserID } from '../../api/session.js'
import JobFilter from './filters/job-filter'
import useInput from '../../hooks/useInput.js'
import { snippet } from '../common/functions.js'
import { Alert } from 'reactstrap'
import JobFilterMobile from './filters/job-filter-mobile.js'

const JobSearch = ({ query }) => {
  const [mobile, setMobile] = useState(window.innerWidth <= 510)

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const textfilter = useInput('')
  const locationfilter = useInput(query)
  const employerfilter = useInput([])
  const citiesfilter = useInput([])
  const keywordsfilter = useInput([])
  const locationsfilter = useInput([])

  const { loading, error, data } = useQuery(EMPLOYER_JOB_SEARCH, {
    variables: { query: locationfilter.value, limit: 250, hasjobs: true },
  })

  console.log(data)

  let openings = []
  let locations = []
  let employers = []
  let cities = []
  let keywords = []
  if (data) {
    data.employers.map((employer) => {
      if (
        !employers.some((x) => x === employer.EmployerName.toUpperCase().trim())
      )
        employers.push(employer.EmployerName.toUpperCase().trim())

      employer.Locations.map((employerlocation) => {
        if (
          !locations.some(
            (x) => x === employerlocation.LocationName.toUpperCase().trim()
          )
        )
          locations.push(employerlocation.LocationName.toUpperCase().trim())

        let cityvalue =
          employerlocation.City.trim() + ', ' + employerlocation.State.trim()

        if (!cities.some((x) => x === cityvalue.toUpperCase().trim()))
          cities.push(cityvalue.toUpperCase().trim())

        if (employerlocation.Openings) {
          employerlocation.Openings.map((job) => {
            openings.push({ employerlocation, job, employer })
            job.Keywords.map((keyword) => {
              if (
                !keywords.some(
                  (x) => x === keyword.Keyword.toUpperCase().trim()
                )
              )
                keywords.push(keyword.Keyword.toUpperCase().trim())
            })
          })
        }
      })
    })
  }

  function search(openings) {
    return openings
      .filter((job) => {
        if (employerfilter.value.length >= 1) {
          return employerfilter.value.includes(
            job.employer.EmployerName.toUpperCase()
          )
        }
        return true
      })
      .filter((job) => {
        if (locationsfilter.value.length >= 1) {
          return locationsfilter.value.includes(
            job.employerlocation.LocationName.toUpperCase()
          )
        }

        return true
      })
      .filter((job) => {
        if (keywordsfilter.value.length >= 1) {
          return keywordsfilter.value.some((keyword) => {
            var exists = job.job.Keywords.map((k) =>
              k.Keyword.toUpperCase()
            ).includes(keyword)
            return exists
          })
        }
        return true
      })
      .filter((job) => {
        if (citiesfilter.value.length >= 1) {
          let jobcity =
            job.employerlocation.City.trim() +
            ', ' +
            job.employerlocation.State.trim()
          return citiesfilter.value.includes(jobcity.toUpperCase())
        }

        return true
      })
      .filter((job) => {
        if (textfilter.value.length >= 1) {
          return (
            job.employerlocation.LocationName.toUpperCase().includes(
              textfilter.value.toUpperCase()
            ) ||
            job.job.Title.toUpperCase().includes(textfilter.value.toUpperCase())
          )
        }

        return true
      })
  }
  console.log(openings)
  return (
    <Fragment>
      <Breadcrumb title="Jobs" />
      <div className="container-fluid">
        <div className="row">
          {mobile && (
            <JobFilterMobile
              locations={locations}
              employers={employers}
              cities={cities}
              keywords={keywords}
              employerfilter={employerfilter}
              textfilter={textfilter}
              locationfilter={locationfilter}
              locationsfilter={locationsfilter}
              keywordfilter={keywordsfilter}
              cityfilter={citiesfilter}
            />
          )}

          {!mobile && (
            <JobFilter
              locations={locations}
              employers={employers}
              cities={cities}
              keywords={keywords}
              employerfilter={employerfilter}
              textfilter={textfilter}
              locationfilter={locationfilter}
              locationsfilter={locationsfilter}
              keywordfilter={keywordsfilter}
              cityfilter={citiesfilter}
            />
          )}

          <div className="col-xl-9 xl-60">
            {loading && (
              <Alert color="secondary dark">
                <p>{'Search.....'}</p>
              </Alert>
            )}
            {!loading && search(openings).length == 0 && (
              <Alert color="secondary dark">
                <p>{'No Search Results found'}</p>
              </Alert>
            )}

            {search(openings).map((data, i) => {
              return (
                <div
                  key={'jsrc' + i}
                  className={`card ${data.badgeValue ? '' : ''} mt-0`}
                >
                  <div className="job-search">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        {data.employerlocation.ProfileImage && (
                          <img
                            className="img-40 img-fluid m-r-20"
                            src={data.employerlocation.ProfileImage}
                            alt=""
                          />
                        )}
                        <div className="flex-grow-1">
                          <h6 className="f-w-600">
                            <a href={'/job/' + data.job.EmployerOpeningsID}>
                              {data.job.Title}
                            </a>
                          </h6>
                          <p className="mb-0 fw-bold">
                            {data.employerlocation.LocationName}
                          </p>
                          <p>
                            {data.employerlocation.City},{' '}
                            {data.employerlocation.State}
                          </p>
                        </div>
                      </div>
                      <p className="mt-2">
                        {snippet(
                          data.job.About
                            ? new DOMParser().parseFromString(
                                data.job.About,
                                'text/html'
                              ).body.textContent || ''
                            : '',
                          500
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default JobSearch
