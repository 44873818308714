import { Html } from "react-pdf-html";
import ResumeStyle1 from "./resume-style1";
import ResumeStyle2 from "./resume-style2";
import ResumeStyle3 from "./resume-style3";

const ResumeViewer = ({resume}) => {
    console.log(resume);

if(resume.style == "1")
    return <ResumeStyle1 resume={resume} />

if(resume.style == "2")
    return <ResumeStyle2 resume={resume} />

if(resume.style == "3")
    return <ResumeStyle3 resume={resume} />

}

export default ResumeViewer;