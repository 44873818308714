import React from "react";
import "./styles/app.scss";
import AppEmployerRouter from "./routes/list/app-employer-routes.js";
import AppUserRouter from "./routes/list/app-user-routes.js";
import { GetUserType } from "./api/session.js";
import AppAdminRouter from "./routes/list/app-admin-routes.js";

function renderView(userType) {
  switch (userType) {
    case "Admin":
    case "Director":
      return <AppAdminRouter />;
    case "Student":
      return <AppUserRouter />;
    case "Employer":
      return <AppEmployerRouter />;
    default:
      return <AppUserRouter />;
  }
}
const AppRouter = () => {
  return renderView(GetUserType());
};

export default AppRouter;
