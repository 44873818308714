import  { split, ApolloClient, InMemoryCache, gql, createHttpLink  } from "@apollo/client";
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';


const cache = new InMemoryCache({resultCaching: false});
const currentToken =localStorage.getItem("token");
const url  = process.env.NODE_ENV === "production" ? 'https://bc.empire-dev.com/api/graphql' : 'https://bc.empire-dev.com/api/graphql';
const QUERY = gql`query getUserSession {  isLoggedIn, user @client  }`;

console.log(url);

const wsLink = new GraphQLWsLink(createClient({
  url: 'wss://bc.empire-dev.com/api/graphql',
  connectionParams: () => {

    const sessionToken =localStorage.getItem("token");
    if(!sessionToken)
    {
      return {};
    }

    return {Authorization: `Bearer ${sessionToken}`}
  }
}));

const authLink = setContext((_, { headers }) => {

  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  var token = currentToken;
   // return the headers to the context so httpLink can read them

  if (localStorage.getItem('token')) {
    token = localStorage.getItem('token');
  }
  token = currentToken; 
  return {
    headers: {
      ...headers,
      authorization: token 
    }
  }
});

const httpLink = createHttpLink({
    uri: url,
    headers: {
      authorization: currentToken
    }
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    authLink.concat(wsLink),
    httpLink
   // ,
   // authLink.concat(httpLink),
  );

 

const client = new ApolloClient({
  cache:cache,
  link: splitLink,
});

cache.writeQuery({
    query: QUERY,
  data: {
    isLoggedIn: !!localStorage.getItem("token"),
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null,
  },
});


export default client;