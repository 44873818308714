import React, { useState, Fragment, useEffect } from 'react'
import { Row, CardBody, Media } from 'reactstrap'
import LightBox from '../common/lightBox'

const PortfolioGallery = ({ portfolios }) => {
  var portfolioimages = []

  portfolios.map((items, i) => {
    portfolioimages.push(items.Src)
  })

  return <LightBox images={portfolioimages} />
}

export default PortfolioGallery
