import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { ADD_JOB_OPENING, DELETE_JOB_OPENING } from '../../api/db.js'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import useInput from '../../hooks/useInput.js'
import { toast } from 'react-toastify'
import { GetUserID } from '../../api/session.js'

const EmployerViewJobs = ({ jobs }) => {
  console.log(jobs)

  let navigate = useNavigate()

  console.log(jobs)
  const routeChange = (resumeId) => {
    let path = `${process.env.PUBLIC_URL}/job`
    let fullpath = resumeId ? path + '/' + resumeId : path

    console.log(fullpath)
    navigate(fullpath)
  }

  return (
    <div className="col-lg-4">
      <div className="card">
        <div className="card-header d-flex">
          <h4 className="card-title me-4 align-self-end">Job Openings</h4>
          <div className="card-options">
            <a
              className="card-options-collapse"
              href="javascript"
              data-toggle="card-collapse"
            >
              <i className="fe fe-chevron-up"></i>
            </a>
            <a
              className="card-options-remove"
              href="javascript"
              data-toggle="card-remove"
            >
              <i className="fe fe-x"></i>
            </a>
          </div>
        </div>
        <div className="table-responsive col-sm-5 col-md-10">
          <table className="table card-table table-vcenter text-nowrap">
            <thead>
              <tr>
                <th>Title</th>
              </tr>
            </thead>
            <tbody>
              {jobs.map((job, i) => (
                <tr key={i}>
                  <td className="resume-title">{job.Title}</td>
                  <td className="text-end">
                    <button
                      className="btn btn-info btn-sm"
                      onClick={() => {
                        routeChange(job.EmployerOpeningsID)
                      }}
                    >
                      {'View Job'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default EmployerViewJobs
