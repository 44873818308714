import { useQuery } from '@apollo/client'
import {
  GET_USER_INFO,
  GET_STUDENT_DETAILS,
  GET_STUDENT_DETAILS_BY_ID,
} from '../api/db.js'

export function GetUserID() {
  var userId = localStorage.getItem('userid')
  var userid = userId

  return Number(userid)
}

export function GetSessionToken() {
  var userId = localStorage.getItem('token')

  return userId
}

export function ClearLoginAlert() {
  localStorage.removeItem('loginAlert')
}

export function ClearFirstLogin() {
  localStorage.removeItem('firstLogin')
}

export function SaveFirstLogin(message) {
  localStorage.setItem('firstLogin', message)
}

export function SaveLoginPageAlert(message) {
  localStorage.setItem('loginAlert', message)
}

export function GetLoginPageAlert() {
  var message = localStorage.getItem('loginAlert')

  return message
}

export function GetFirstLogin() {
  var message = localStorage.getItem('firstLogin')

  return message
}

export function GetUserRole() {
  var user = GetUser()

  return user.Roles
}
export function GetUser() {
  var data = JSON.parse(localStorage.getItem('user'))

  return data
}

export function GetUserType() {
  var user = JSON.parse(localStorage.getItem('user'))

  if (user == undefined) return 'Home'

  return user.UserType.Type
}

export function Logout() {
  var userid = null
  localStorage.setItem('userid', userid)
  localStorage.setItem('user', userid)
  return userid
}

export function SetUserJWT(hash) {
  console.log('the token is : ' + hash)
  localStorage.setItem('token', hash)
}

export function LoginSuccess(logindata) {
  localStorage.setItem('user', JSON.stringify(logindata))
  localStorage.setItem('userid', logindata.UserID)
  ClearLoginAlert();
}

export function IsUserLoggedIn(userId) {
  const { loading, error, data } = useQuery(GET_USER_INFO, {
    variables: { userId: userId },
  })

  if (loading) return false

  if (error) console.log(error)

  return data && data.user.Online
}

export function GetUserInfo(userId) {
  const { loading, error, data } = useQuery(GET_USER_INFO, {
    variables: { userId: userId },
  })

  if (loading) return false

  if (error) console.log(error)

  return data && data.user
}

export function GetStudentId(userId) {
  const { loading, error, data } = useQuery(GET_STUDENT_DETAILS, {
    variables: { userId: parseInt(userId) },
  })

  if (loading) return false

  if (error) console.log(error)

  console.log(data.student.StudentID)

  return data && data.student.StudentID
}

export function GetStudent(studentId) {
  const { loading, error, data } = useQuery(GET_STUDENT_DETAILS_BY_ID, {
    variables: { studentId: parseInt(studentId) },
  })

  
  if (error) console.log(error)

  return data && data.student
}
