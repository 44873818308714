import { gql } from "@apollo/client";
//eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOjU1LCJ1c2VydHlwZSI6MSwicGVybWlzc2lvbnMiOnsiZW1wbG95ZXJzIjp7InJlYWQiOjF9fSwiaWF0IjoxNjc1Mjg4MjEzfQ.bl5svmPjiwMM8R2GLXlY1JQEyTuIiGwgMgG84oc6O1c
// test auth token
export const DELETE_PORTFOLIO_ITEM = gql`
  mutation DeletePortfolio($portfolioId: Int!) {
    deletePortfolio(PortfolioID: $portfolioId)
  }
`;

export const DELETE_EMPLOYER_IMAGE = gql`
  mutation DeleteEmployerImage($employerImagesId: Int!) {
    deleteEmployerImage(EmployerImagesID: $employerImagesId)
  }
`;

export const GET_USER_AUTH = gql`
  query Authtest {
    authtest {
      token
    }
  }
`;

export const RESET_PASSWORD_REQUEST = gql`
  mutation ResetPasswordRequest($email: Email!) {
    resetPasswordRequest(Email: $email) {
      Message
      Success
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $email: Email!
    $password: Password!
    $userIdBase: String!
  ) {
    resetPassword(Email: $email, Password: $password, UserIDBase: $userIdBase) {
      Message
      Success
    }
  }
`;

export const GET_STUDENT_DETAILS_BY_ID = gql`
  query Student($studentId: Int) {
    student(studentId: $studentId) {
      StudentID
      UserID
      FirstName
      LastName
      UserName
      RoleID
      RoleName
      LastLogin
      isprivate
      Email
      IsApproved
      ProfileImage
      BannerImage
      mustUpdatePassword
      LocID
      StatusID
      Address1
      Address2
      City
      State
      Zipcode
      Lat
      Lon
      Phone
      HoursToGraduate
      HoursCompleted
      ProgramID
      ProgramHours
      ContractedHours
      Certification
      RegistrationDate
      isPrivate
      Distance
      Portfolios {
        Src
        UserID
        Caption
        PortfolioID
      }
      Resumees {
        ResumeeID
        StudentID
        ResumeeStyleID
        ResumeeColorID
        CareerID
        Title
        Objective
        LastModified
        WorkScheduleID
        isPrimary
        Active
        DateCreated
        EmploymentHistory {
          EmploymentHistoryID
          EmployerID
          StudentID
          EmployerName
          EmployerCity
          EmployerState
          ResumeeID
          Title
          Description
          StartMonth
          StartYear
          EndMonth
          EndYear
          Current
        }
        EducationHistory {
          EducationHistoryID
          ResumeeID
          StudentID
          School
          City
          State
          Certificate
          StartMonth
          StartYear
          EndMonth
          EndYear
          Current
        }
      }
    }
  }
`;

export const GET_STUDENT_DETAILS = gql`
  query Student($userId: Int) {
    student(userId: $userId) {
      StudentID
      UserID
      FirstName
      LastName
      UserName
      RoleID
      RoleName
      LastLogin
      isprivate
      Email
      IsApproved
      ProfileImage
      BannerImage
      mustUpdatePassword
      LocID
      StatusID
      Address1
      Address2
      City
      State
      Zipcode
      Lat
      Lon
      Phone
      HoursToGraduate
      HoursCompleted
      ProgramID
      ProgramHours
      ContractedHours
      Certification
      RegistrationDate
      isPrivate
      Distance
      Portfolios {
        Src
        UserID
        Caption
        PortfolioID
      }
      Resumees {
        ResumeeID
        StudentID
        ResumeeStyleID
        ResumeeColorID
        CareerID
        Title
        Objective
        LastModified
        WorkScheduleID
        isPrimary
        Active
        DateCreated
        EmploymentHistory {
          EmploymentHistoryID
          EmployerID
          StudentID
          EmployerName
          EmployerCity
          EmployerState
          ResumeeID
          Title
          Description
          StartMonth
          StartYear
          EndMonth
          EndYear
          Current
        }
        EducationHistory {
          EducationHistoryID
          ResumeeID
          StudentID
          School
          City
          State
          Certificate
          StartMonth
          StartYear
          EndMonth
          EndYear
          Current
        }
      }
    }
  }
`;

export const GET_PORTFOLIO = gql`
  query portfolios($userid: ID!) {
    portfolios(userid: 82748) {
      Src
      UserID
      Caption
      PortfolioID
    }
  }
`;

export const STUDENT_SEARCH_EMPLOYERS = gql`
  query Employers($query: String) {
    employers(query: $query) {
      About
      Active
      BrandID
      Distances
      EmployerID
      EmployerName
      Locations {
        City
        Distance
        Openings {
          ListingDate
          Public
          Title
          About
          EmployerID
          EmployerOpeningsID
        }
      }
    }
  }
`;

export const GET_EMPLOYER_OPENING = gql`
  query EmployerOpening($employeropeningsid: Int!) {
    employerOpening(employeropeningsid: $employeropeningsid) {
      EmployerOpeningsID
      EmployerID
      EmployerLocationsID
      Title
      About
      ListingDate
      Active
      Public
      Keywords {
        KeywordID
        Keyword
      }
      ApplicationTargets {
        CoverLetters {
          CoverLetterID
          Title
        }
        Resumees {
          Active
          Title
          ResumeeID
          isPrimary
        }
      }
    }
  }
`;

export const LOGIN_USER_GRAPH = gql`
  mutation LoginUser($email: Email!, $password: String!) {
    loginUser(Email: $email, Password: $password) {
      UserID
      FirstName
      LastName
      Email
      RegistrationDate
      UserType {
        UserTypesID
        Type
      }
      Roles
      Verified
      mustUpdatePassword
      clientUUID
      token
    }
  }
`;

export const MESSAGES_SUBSCRIPTION = gql`
  subscription MessageAlert {
    getAlerts {
      ... on MessageAlert {
        type
        data {
          EventTime
          Message
          Name
          UserID
          UserType {
            UserTypesID
            Type
          }
        }
      }
    }
  }
`;

export const CONVERSATIONS = gql`
  query GetConversations {
    getConversations {
      ConversationID
      Label
      Messages {
        ConversationID
        Datestamp
        FromName
        FromUserID
        IsRead
        Message
        MessagingID
        ToName
        ToUserID
      }
      UnReadCount
      Users {
        ProfileImage
        BannerImage
        FirstName
        LastName
        UserID
        Online
      }
    }
  }
`;

export const CONVERSATION = gql`
  query Query($conversationId: String!) {
    getConversation(ConversationID: $conversationId) {
      ConversationID
      Label
      Messages {
        MessagingID
        ConversationID
        Datestamp
        FromUserID
        FromName
        ToUserID
        ToName
        Message
        IsRead
      }
      UnReadCount
      Users {
        UserID
        FirstName
        LastName
        Email
        IsApproved
        LastLogin
        ProfileImage
        BannerImage
        mustUpdatePassword
        UserType {
          Type
          UserTypesID
        }
      }
    }
  }
`;

export const MESSAGES = gql`
  query Query($conversationId: String!) {
    getMessages(conversationID: $conversationId) {
      ConversationID
      Datestamp
      FromName
      FromUserID
      IsRead
      Message
      MessagingID
      ToName
      ToUserID
    }
  }
`;

export const MARK_CONVERSATION_READ = gql`
  mutation SetConversationIsRead($conversationId: String!, $isRead: Boolean!) {
    setConversationIsRead(ConversationID: $conversationId, IsRead: $isRead) {
      ConversationID
      Label
      UnReadCount
    }
  }
`;

export const ADD_PORTFOLIO_IMAGE = gql`
  mutation AddPortfolioImage($userId: Int!, $src: String!, $caption: String) {
    addPortfolioImage(userId: $userId, Src: $src, Caption: $caption)
  }
`;

export const GET_USER_INFO = gql`
  query User($userId: Int) {
    user(userId: $userId) {
      UserID
      FirstName
      LastName
      Email
      IsApproved
      LastLogin
      ProfileImage
      BannerImage
      mustUpdatePassword
      RegistrationDate
      Online
      UserType {
        UserTypesID
        Type
      }
    }
  }
`;

export const UPDATE_USER_DETAILS = gql`
  mutation UpdateUser(
    $userId: Int!
    $firstName: NonEmptyString
    $lastName: NonEmptyString
    $phone: NonEmptyString
    $address1: NonEmptyString
    $address2: String
    $city: NonEmptyString
    $zipcode: NonEmptyString
    $state: NonEmptyString
  ) {
    updateUser(
      UserID: $userId
      FirstName: $firstName
      LastName: $lastName
      Phone: $phone
      Address1: $address1
      Address2: $address2
      City: $city
      Zipcode: $zipcode
      State: $state
    ) {
      Success
      Message
    }
  }
`;

export const UPDATE_BASIC_USER_DETAILS = gql`
  mutation UpdateUser(
    $userId: Int!
    $firstName: NonEmptyString
    $lastName: NonEmptyString
  ) {
    updateUser(UserID: $userId, FirstName: $firstName, LastName: $lastName) {
      Success
      Message
    }
  }
`;

export const UPDATE_USER_PROFILE_IMAGE = gql`
  mutation UpdateUserDetails($userId: Int!, $profileImage: String) {
    updateUserDetails(UserID: $userId, ProfileImage: $profileImage)
  }
`;

export const UPDATE_USER_BANNER_IMAGE = gql`
  mutation UpdateUserDetails($userId: Int!, $bannerImage: String) {
    updateUserDetails(UserID: $userId, BannerImage: $bannerImage)
  }
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage($toUserIDs: [Int]!, $message: String!) {
    sendMessage(ToUserIDs: $toUserIDs, Message: $message) {
      Message
      Success
    }
  }
`;

export const EDIT_PROFILE = gql`
  mutation UpdateStudent(
    $userId: ID!
    $firstName: String
    $lastName: String
    $email: String
    $address1: String
    $address2: String
    $city: String
    $state: String
    $zipcode: String
    $phone: String
    $isprivate: Boolean
  ) {
    updateStudent(
      userId: $userId
      FirstName: $firstName
      LastName: $lastName
      Email: $email
      Address1: $address1
      Address2: $address2
      City: $city
      State: $state
      Zipcode: $zipcode
      Phone: $phone
      isprivate: $isprivate
    ) {
      Address1
      Address2
      City
      State
      UserID
      RoleName
      Zipcode
      isprivate
    }
  }
`;

export const EDIT_PROFILE_IMAGE = gql`
  mutation UpdateUserDetails($userId: Int!, $profileImage: String) {
    updateUserDetails(UserID: $userId, ProfileImage: $profileImage)
  }
`;

export const EDIT_BANNER_IMAGE = gql`
  mutation UpdateUserDetails($userId: Int!, $bannerImage: String) {
    updateUserDetails(UserID: $userId, BannerImage: $bannerImage)
  }
`;

export const ADD_RESUME = gql`
  mutation Mutation(
    $studentId: Int!
    $resumeeStyleId: Int!
    $resumeeColorId: Int!
    $careerId: Int!
    $title: String!
    $objective: String!
    $isPrimary: Boolean!
    $active: Boolean!
  ) {
    addStudentResumee(
      StudentID: $studentId
      ResumeeStyleID: $resumeeStyleId
      ResumeeColorID: $resumeeColorId
      CareerID: $careerId
      Title: $title
      Objective: $objective
      isPrimary: $isPrimary
      Active: $active
    )
  }
`;

export const EDIT_RESUME = gql`
  mutation Mutation(
    $resumeeId: Int!
    $resumeeStyleId: Int!
    $resumeeColorId: Int!
    $careerId: Int!
    $title: String!
    $objective: String!
    $isPrimary: Boolean!
    $active: Boolean!
  ) {
    editStudentResumee(
      ResumeeID: $resumeeId
      ResumeeStyleID: $resumeeStyleId
      ResumeeColorID: $resumeeColorId
      CareerID: $careerId
      Title: $title
      Objective: $objective
      isPrimary: $isPrimary
      Active: $active
    )
  }
`;

export const DELETE_RESUME = gql`
  mutation DeleteStudentResume($resumeeId: Int!) {
    deleteStudentResume(ResumeeID: $resumeeId)
  }
`;

export const ADD_EMPLOYMENT = gql`
  mutation Mutation(
    $employerName: String!
    $employerCity: String!
    $employerState: String!
    $resumeeId: Int!
    $title: String!
    $description: String!
    $startMonth: Int!
    $startYear: Int!
  ) {
    addEmploymentHistory(
      EmployerName: $employerName
      EmployerCity: $employerCity
      EmployerState: $employerState
      ResumeeID: $resumeeId
      Title: $title
      Description: $description
      StartMonth: $startMonth
      StartYear: $startYear
    )
  }
`;

export const EDIT_EMPLOYMENT = gql`
  mutation Mutation(
    $employmentHistoryId: Int!
    $employerName: String
    $employerCity: String
    $employerState: String
    $title: String
    $description: String
    $startMonth: Int
    $startYear: Int
    $endMonth: Int
    $endYear: Int
    $current: Boolean
  ) {
    editEmploymentHistory(
      EmploymentHistoryID: $employmentHistoryId
      EmployerName: $employerName
      EmployerCity: $employerCity
      EmployerState: $employerState
      Title: $title
      Description: $description
      StartMonth: $startMonth
      StartYear: $startYear
      EndMonth: $endMonth
      EndYear: $endYear
      Current: $current
    )
  }
`;

export const DELETE_EMPLOYMENT = gql`
  mutation Mutation($employmentHistoryId: Int!) {
    deleteEmploymentHistory(EmploymentHistoryID: $employmentHistoryId)
  }
`;

export const ADD_EDUCATION = gql`
  mutation Mutation(
    $resumeeId: Int!
    $school: String!
    $city: String!
    $state: String!
    $startMonth: Int!
    $startYear: Int!
  ) {
    addEducationHistory(
      ResumeeID: $resumeeId
      School: $school
      City: $city
      State: $state
      StartMonth: $startMonth
      StartYear: $startYear
    )
  }
`;

export const EDIT_EDUCATION = gql`
  mutation Mutation(
    $educationHistoryId: Int!
    $school: String
    $city: String
    $state: String
    $certificate: String
    $startMonth: Int
    $startYear: Int
    $endMonth: Int
    $endYear: Int
    $current: Boolean
  ) {
    editEducationHistory(
      EducationHistoryID: $educationHistoryId
      School: $school
      City: $city
      State: $state
      Certificate: $certificate
      StartMonth: $startMonth
      StartYear: $startYear
      EndMonth: $endMonth
      EndYear: $endYear
      Current: $current
    )
  }
`;

export const DELETE_EDUCATION = gql`
  mutation DeleteEducationHistory($educationHistoryId: Int!) {
    deleteEducationHistory(EducationHistoryID: $educationHistoryId)
  }
`;

export const ADD_LOCATION = gql`
  mutation AddEmployerLocation(
    $employerId: Int!
    $locationName: String!
    $address1: String!
    $city: String!
    $state: String!
    $zipcode: String!
    $phone: String!
    $about: String
    $address2: String
  ) {
    addEmployerLocation(
      EmployerId: $employerId
      LocationName: $locationName
      Address1: $address1
      City: $city
      State: $state
      Zipcode: $zipcode
      Phone: $phone
      About: $about
      Address2: $address2
    ) {
      EmployerLocationsID
      Message
      Success
    }
  }
`;

export const GET_EMPLOYER_LOCATION_JOB = gql`
  query EmployerOpening($employerOpeningsId: Int!) {
    employerOpening(employerOpeningsId: $employerOpeningsId) {
      EmployerOpeningsID
      EmployerID
      EmployerLocationsID
      Title
      About
      EmployerLocationUsers {
        EmployerID
        EmployerLocationsID
        FirstName
        LastName
        Email
        UserID
        EmployerRole {
          EmployerRolesID
          Description
        }
      }
      ListingDate
      Active
      Public
      Keywords {
        KeywordID
        Keyword
      }
      ApplicationTargets {
        Resumees {
          ResumeeID
          StudentID
          ResumeeStyleID
          ResumeeColorID
          CareerID
          Title
          Objective
          LastModified
          WorkScheduleID
          isPrimary
          Active
          DateCreated
          EmploymentHistory {
            EmploymentHistoryID
            EmployerID
            StudentID
            EmployerName
            EmployerCity
            EmployerState
            ResumeeID
            Title
            Description
            StartMonth
            StartYear
            EndMonth
            EndYear
            Current
          }
          EducationHistory {
            EducationHistoryID
            ResumeeID
            StudentID
            School
            City
            State
            Certificate
            StartMonth
            StartYear
            EndMonth
            EndYear
            Current
          }
        }
      }
    }
  }
`;

export const GET_EMPLOYER_LOCATIONS = gql`
  query GetEmployerLocations($employerId: Int) {
    employer(employerId: $employerId) {
      Locations {
        EmployerLocationsID
        EmployerID
        LocationName
        About
        Address1
        Address2
        City
        State
        Zipcode
        Phone
        Lat
        Lon
        Active
        Distance
        VideoURL
        ProfileImage
        BannerImage
        EmployerImages {
          EmployerImagesID
          EmployerID
          EmployerLocationsID
          Src
          Caption
          Active
          DateCreated
        }
        Openings {
          EmployerOpeningsID
          EmployerID
          EmployerLocationsID
          Title
          About
          EmployerLocationUsers {
            EmployerID
            EmployerLocationsID
            FirstName
            LastName
            Email
            EmployerRole {
              EmployerRolesID
              Description
            }
          }
          Keywords {
            KeywordID
            Keyword
          }
          ApplicationTargets {
            Resumees {
              ResumeeID
              StudentID
              ResumeeStyleID
              ResumeeColorID
              CareerID
              Title
              Objective
              LastModified
              WorkScheduleID
              isPrimary
              Active
              DateCreated
            }
          }
          ListingDate
          Active
          Public
        }
      }
    }
  }
`;

export const GET_EMPLOYER_IMAGES = gql`
  query EmployerImages($employerId: Int) {
    employer(employerId: $employerId) {
      EmployerImages {
        EmployerImagesID
        EmployerID
        EmployerLocationsID
        Src
        Caption
        Active
        DateCreated
      }
    }
  }
`;

export const GET_EMPLOYER_LOCATION = gql`
  query EmployerLocation($employerLocationsId: Int!) {
    employerLocation(employerLocationsId: $employerLocationsId) {
      EmployerLocationsID
      EmployerID
      LocationName
      About
      Address1
      Address2
      City
      State
      Zipcode
      Phone
      Lat
      Lon
      Active
      Distance
      Openings {
        EmployerOpeningsID
        EmployerID
        EmployerLocationsID
        Title
        About
        EmployerLocationUsers {
          EmployerID
          EmployerLocationsID
          FirstName
          LastName
          Email
          UserID
          EmployerRole {
            EmployerRolesID
            Description
          }
        }
        ListingDate
        Active
        Public
        ApplicationTargets {
          Resumees {
            ResumeeID
            StudentID
            ResumeeStyleID
            ResumeeColorID
            CareerID
            Title
            Objective
            LastModified
            WorkScheduleID
            isPrimary
            Active
            DateCreated
            EmploymentHistory {
              EmploymentHistoryID
              EmployerID
              StudentID
              EmployerName
              EmployerCity
              EmployerState
              ResumeeID
              Title
              Description
              StartMonth
              StartYear
              EndMonth
              EndYear
              Current
            }
            EducationHistory {
              EducationHistoryID
              ResumeeID
              StudentID
              School
              City
              State
              Certificate
              StartMonth
              StartYear
              EndMonth
              EndYear
              Current
            }
          }
        }
      }
      ProfileImage
      BannerImage
      VideoURL
      EmployerImages {
        EmployerImagesID
        EmployerID
        EmployerLocationsID
        Src
        Caption
        Active
        DateCreated
      }
    }
  }
`;

export const ADD_EMPLOYER_LOCATION_IMAGE = gql`
  mutation AddEmployerImage(
    $employerId: Int!
    $src: String!
    $employerLocationsId: Int
    $caption: String
    $active: Boolean
  ) {
    addEmployerImages(
      EmployerID: $employerId
      Src: $src
      EmployerLocationsID: $employerLocationsId
      Caption: $caption
      Active: $active
    ) {
      EmployerImagesID
      Success
    }
  }
`;

export const ADD_EMPLOYER_IMAGE = gql`
  mutation AddEmployerImage(
    $employerId: Int!
    $src: String!
    $caption: String
    $active: Boolean
  ) {
    addEmployerImages(
      EmployerID: $employerId
      Src: $src
      Caption: $caption
      Active: $active
    ) {
      EmployerImagesID
      Success
    }
  }
`;

export const UPDATE_LOCATION_PROFILE_IMAGE = gql`
  mutation UpdateEmployerLocation(
    $employerLocationsId: Int!
    $profileImage: String
  ) {
    updateEmployerLocation(
      EmployerLocationsID: $employerLocationsId
      ProfileImage: $profileImage
    ) {
      Message
      Success
    }
  }
`;

export const UPDATE_LOCATION_BANNER_IMAGE = gql`
  mutation UpdateEmployerLocation(
    $employerLocationsId: Int!
    $bannerImage: String
  ) {
    updateEmployerLocation(
      EmployerLocationsID: $employerLocationsId
      BannerImage: $bannerImage
    ) {
      Message
      Success
    }
  }
`;

export const EDIT_EMPLOYER_LOCATION = gql`
  mutation UpdateEmployerLocation(
    $employerLocationsId: Int!
    $profileImage: String
    $locationName: String
    $about: String
    $address1: String
    $address2: String
    $city: String
    $state: String
    $zipcode: String
    $phone: String
    $videoUrl: String
    $active: Boolean
  ) {
    updateEmployerLocation(
      EmployerLocationsID: $employerLocationsId
      ProfileImage: $profileImage
      LocationName: $locationName
      About: $about
      Address1: $address1
      Address2: $address2
      City: $city
      State: $state
      Zipcode: $zipcode
      Phone: $phone
      VideoURL: $videoUrl
      Active: $active
    ) {
      Message
      Success
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation DeleteEmployerLocation($employerLocationsId: Int!) {
    deleteEmployerLocation(EmployerLocationsID: $employerLocationsId)
  }
`;

export const DELETE_JOB_OPENING = gql`
  mutation DeleteEmployerOpening($employerOpeningsId: Int!) {
    deleteEmployerOpening(EmployerOpeningsID: $employerOpeningsId)
  }
`;

export const ADD_JOB_OPENING = gql`
  mutation AddEmployerOpening(
    $employerId: Int!
    $employerLocationsId: Int!
    $title: String!
    $employerUsersId: Int!
  ) {
    addEmployerOpening(
      EmployerID: $employerId
      EmployerLocationsID: $employerLocationsId
      Title: $title
      EmployerUsersID: $employerUsersId
    ) {
      EmployerOpeningsID
      Success
    }
  }
`;

export const EDIT_JOB_OPENING = gql`
  mutation UpdateEmployerOpening(
    $employerOpeningsId: Int!
    $employerLocationsId: Int
    $title: String
    $about: String
    $employerUsersId: Int
    $active: Boolean
    $public: Boolean
  ) {
    updateEmployerOpening(
      EmployerOpeningsID: $employerOpeningsId
      EmployerLocationsID: $employerLocationsId
      Title: $title
      About: $about
      EmployerUsersID: $employerUsersId
      Active: $active
      Public: $public
    ) {
      Message
      Success
    }
  }
`;

export const EMPLOYER_JOB_SEARCH = gql`
  query Employers(
    $query: String
    $lat: Latitude
    $lon: Longitude
    $limit: Int
    $distance: Int
    $hasjobs: Boolean
    $keywordIds: [Int]
  ) {
    employers(
      query: $query
      lat: $lat
      lon: $lon
      limit: $limit
      distance: $distance
      hasjobs: $hasjobs
      keywordIds: $keywordIds
    ) {
      EmployerID
      BrandID
      AdminUserID
      EmployerName
      About
      RegistrationDate
      Active
      Locations {
        EmployerLocationsID
        EmployerID
        LocationName
        About
        Address1
        Address2
        City
        State
        Zipcode
        Phone
        Lat
        Lon
        Active
        Distance
        Openings {
          EmployerOpeningsID
          EmployerLocationsID
          Title
          About
          Keywords {
            KeywordID
            Keyword
          }
          ListingDate
          Active
          Public
          EmployerID
        }
        ProfileImage
        VideoURL
        EmployerImages {
          EmployerImagesID
          EmployerID
          EmployerLocationsID
          Src
          Caption
          Active
          DateCreated
        }
      }
      Distances
      EmployerImages {
        EmployerImagesID
        EmployerID
        EmployerLocationsID
        Src
        Caption
        Active
        DateCreated
      }
    }
  }
`;

export const STUDENT_SEARCH = gql`
  query Students(
    $name: String
    $employerlocationsid: Int
    $lat: Latitude
    $query: String
    $lon: Longitude
    $limit: Int
    $distance: Int
  ) {
    students(
      name: $name
      employerlocationsid: $employerlocationsid
      lat: $lat
      query: $query
      lon: $lon
      limit: $limit
      distance: $distance
    ) {
      Address1
      Address2
      City
      Distance
      EEGStudentID
      Email
      FirstName
      IsApproved
      LastLogin
      LastName
      Lat
      LocID
      Lon
      Phone
      HoursCompleted
      ProgramHours
      ProgramID
      Certification
      ContractedHours
      Portfolios {
        Src
        UserID
        Caption
        PortfolioID
      }
      ProfileImage
      RoleID
      RoleName
      State
      StudentID
      UserID
      UserName
      Zipcode
      isprivate
    }
  }
`;

export const GET_EMPLOYER_KEYWORD_LIST = gql`
  query EmployerKeywords {
    employerKeywords {
      Keyword
      KeywordID
    }
  }
`;

export const ADD_JOB_KEYWORD = gql`
  mutation AddEmployerOpeningsKeywords(
    $employerOpeningsId: Int!
    $keywordId: Int!
  ) {
    addEmployerOpeningsKeywords(
      EmployerOpeningsID: $employerOpeningsId
      KeywordID: $keywordId
    ) {
      EmployerOpeningsKeywordsID
      Success
    }
  }
`;

export const DELETE_JOB_KEYWORD = gql`
  mutation DeleteEmployerOpeningsKeywords(
    $employerOpeningsId: Int!
    $keywordId: Int!
  ) {
    deleteEmployerOpeningsKeywords(
      EmployerOpeningsID: $employerOpeningsId
      KeywordID: $keywordId
    )
  }
`;

export const APPLY_FOR_OPENING = gql`
  mutation Application(
    $employerId: Int!
    $employerlocationsId: Int!
    $employeropeningsId: Int!
    $resumeeId: Int!
  ) {
    application(
      employerId: $employerId
      employerlocationsId: $employerlocationsId
      employeropeningsId: $employeropeningsId
      resumeeId: $resumeeId
    ) {
      Success
      Message
    }
  }
`;

export const GET_STUDENT_RESUMEES = gql`
  query Student($userId: Int) {
    student(userId: $userId) {
      Resumees {
        ResumeeID
        Title
        StudentID
        Active
      }
    }
  }
`;

export const GET_MESSAGE_NOTIFICATION = gql`
  subscription MessageAlert {
    getAlerts {
      MessageAlert {
        type
        data {
          UserID
          UserType {
            UserTypesID
            Type
          }
          Name
          Message
          EventTime
        }
      }
    }
  }
`;

export const GET_EMPLOYER = gql`
  query Employer($employerId: Int) {
    employer(employerId: $employerId) {
      EmployerID
      BrandID
      AdminUserID
      EmployerName
      About
      RegistrationDate
      Active
      Locations {
        EmployerLocationsID
        EmployerID
        LocationName
        About
        Address1
        Address2
        City
        State
        Zipcode
        Phone
        Lat
        Lon
        Active
        Distance
        Openings {
          EmployerOpeningsID
          EmployerID
          EmployerLocationsID
          Title
          About
          ListingDate
          Active
          Public
          Keywords {
            KeywordID
            Keyword
          }
        }
        ProfileImage
        BannerImage
        VideoURL
        EmployerImages {
          EmployerImagesID
          EmployerID
          EmployerLocationsID
          Src
          Caption
          Active
          DateCreated
        }
      }
      Distances
      EmployerImages {
        EmployerImagesID
        EmployerID
        EmployerLocationsID
        Src
        Caption
        Active
        DateCreated
      }
    }
  }
`;

export const IS_AUTHORIZE = gql`
  query Authtest {
    authtest {
      token
    }
  }
`;

export const REGISTER_USER = gql`
  mutation RegisterUser(
    $userTypesId: Int!
    $firstName: NonEmptyString!
    $lastName: NonEmptyString!
    $phone: NonEmptyString!
    $address1: NonEmptyString!
    $city: NonEmptyString!
    $zipcode: NonEmptyString!
    $state: NonEmptyString!
    $email: Email!
    $password: Password!
    $address2: String
  ) {
    registerUser(
      UserTypesID: $userTypesId
      FirstName: $firstName
      LastName: $lastName
      Phone: $phone
      Address1: $address1
      City: $city
      Zipcode: $zipcode
      State: $state
      Email: $email
      Password: $password
      Address2: $address2
    ) {
      UsersClearingID
      UserTypesID
      Success
      Message
    }
  }
`;

export const REGISTER_STUDENT = gql`
  mutation RegisterStudent(
    $usersClearingId: Int!
    $studentId: Int!
    $birthDay: Int!
    $birthMonth: Int!
  ) {
    registerStudent(
      UsersClearingID: $usersClearingId
      StudentID: $studentId
      BirthDay: $birthDay
      BirthMonth: $birthMonth
    ) {
      UserID
      Success
      Message
    }
  }
`;

export const REGISTER_EMPLOYER = gql`
  mutation RegisterEmployer($usersClearingId: Int!, $employerName: String!) {
    registerEmployer(
      UsersClearingID: $usersClearingId
      EmployerName: $employerName
    ) {
      EmployerID
      Message
      Success
      UserID
    }
  }
`;

export const CHECK_STUDENT_ID = gql`
  query CheckStudent($studentId: Int!, $birthDay: Int!, $birthMonth: Int!) {
    checkStudent(
      studentID: $studentId
      birthDay: $birthDay
      birthMonth: $birthMonth
    ) {
      studentDumpID
      isValid
    }
  }
`;

export const GET_USER_JOB_APPLICATIONS = gql`
  query Applications($userId: ID!) {
    applications(userId: $userId) {
      ResumeeID
      EmployerID
      EmployerName
      LocationName
      ResumeeTitle
      DateTimeApplied
      EmployerOpeningsID
      EmployerLocationsID
      EmployerOpeningTitle
      ApplicationsActivityID
    }
  }
`;

export const UPDATE_USER_EMAIL = gql`
  mutation UpdateUserEmail(
    $userId: Int!
    $email: Email!
    $password: Password!
  ) {
    updateUserEmail(UserID: $userId, Email: $email, Password: $password) {
      Message
      Success
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation ChangePassword(
    $userId: ID!
    $email: Email!
    $password1: Password!
    $password2: Password!
  ) {
    changePassword(
      UserID: $userId
      Email: $email
      Password1: $password1
      Password2: $password2
    ) {
      Success
      Message
    }
  }
`;

export const UPDATE_STUDENT_EMAIL = gql`
  mutation UpdateStudent($userId: ID!, $email: String) {
    updateStudent(userId: $userId, Email: $email) {
      Email
      UserID
      StudentID
    }
  }
`;

export const SCHOOL_SEARCH = gql`
  query Schools(
    $query: String
    $lat: Latitude
    $lon: Longitude
    $limit: Int
    $distance: Int
  ) {
    schools(
      query: $query
      lat: $lat
      lon: $lon
      limit: $limit
      distance: $distance
    ) {
      LocID
      School
      Address
      City
      State
      Zipcode
      Lat
      Lon
      Phone
      Distance
      StudentStats {
        TotalStudents
        TotalAlumni
        TotalActive
        TotalRegistered
        TotalAlumniRegistered
        TotalActiveRegistered
        PercentRegisteredActive
      }
    }
  }
`;

export const SCHOOL = gql`
  query School($locid: ID!) {
    school(locid: $locid) {
      LocID
      School
      Address
      City
      State
      Zipcode
      Lat
      Lon
      Phone
      Distance
      StudentStats {
        TotalStudents
        TotalAlumni
        TotalActive
        TotalRegistered
        TotalAlumniRegistered
        TotalActiveRegistered
        PercentRegisteredActive
      }
    }
  }
`;

export const SCHOOL_STUDENTS = gql`
  query School($locid: ID!) {
    school(locid: $locid) {
      LocID
      SchoolStudents {
        ProfileImage
        StudentID
        UserID
        LastName
        FirstName
        Email
        State
        City
        HoursCompleted
        HoursToGraduate
        ProgramHours
        Phone
      }
    }
  }
`;

export const SCHOOL_BY_DIRECTOR = gql`
  query DirectorSchools($userId: Int!) {
    directorSchools(userId: $userId) {
      LocID
      School
      Address
      City
      State
      Zipcode
      Lat
      Lon
      Phone
      Distance
      StudentStats {
        TotalStudents
        TotalAlumni
        TotalActive
        TotalRegistered
        TotalAlumniRegistered
        TotalActiveRegistered
        PercentRegisteredActive
      }
    }
  }
`;
