import React, { useState, useEffect, useRef } from "react";
import {
  format,
  formatDistance,
  formatRelative,
  subDays,
  getUnixTime,
} from "date-fns";
import { useQuery } from "@apollo/client";
import { CONVERSATION } from "../../api/db";
import ConversationHeader from "./conversation-header";
import MessageInput from "../shared/message-input";
import refreshData from "../../hooks/refresh";
import MessageUserIcon from "./message-user-icon";
import ChatMobile from "./chat-mobile";
import ChatDesktop from "./chat-desktop";

const ConversationDetail = ({ conversationid }) => {
  const [mobile, setMobile] = useState(window.innerWidth <= 510);

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      {mobile && <ChatMobile conversationid={conversationid} />}

      {!mobile && <ChatDesktop conversationid={conversationid} />}
    </>
  );
};

export default ConversationDetail;
