import React, { Fragment, useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { GetUserRole } from '../../../api/session.js'
import ProfileEditStudent from './profile-edit-student.js'
import ProfileEditUser from './profile-edit-user.js'

const EditProfile = () => {
  var roles = GetUserRole()
  let view = roles.includes('student')

  if (view) return <ProfileEditStudent />
  else return <ProfileEditUser />
}

export default EditProfile
