import React, { Fragment, useRef, useState } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import {
  EDIT_JOB_OPENING,
  ADD_JOB_KEYWORD,
  DELETE_JOB_KEYWORD,
  GET_EMPLOYER_KEYWORD_LIST,
  DELETE_LOCATION,
  DELETE_JOB_OPENING,
} from "../../../../api/db.js";
import { GetUserID } from "../../../../api/session.js";
import Breadcrumb from "../../../common/breadcrumb.js";
import LocationJobContact from "./location-job-info.js";
import useInput from "../../../../hooks/useInput.js";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EventEmitter from "EventEmitter";
import SimpleReactValidator from "simple-react-validator";

const JobEdit = ({ job, callback }) => {
  console.log(job);
  const emmitter = new EventEmitter();
  var validator = useRef(new SimpleReactValidator({ autoForceUpdate: this }));

  const title = useInput(job.Title);
  const about = useInput(job.About);
  const isactive = useInput((job.Active ?? false) == true);
  const ispublic = useInput((job.Public ?? false) == true);
  const keywordids = useInput(job.Keywords.map((x) => x.KeywordID) ?? Array());
  const [, forceUpdate] = useState();

  console.log(job.Public);
  console.log(ispublic);
  let keywordList = Array();
  const listingdate = useInput(job.listingDate);
  let navigate = useNavigate();

  const [
    deleteJobOpening,
    { data: deleteData, loading: deleteLoading, error: deleteError },
  ] = useMutation(DELETE_JOB_OPENING, {});

  const { data } = useQuery(GET_EMPLOYER_KEYWORD_LIST, {});

  if (data) {
    console.log(data);
    keywordList = data.employerKeywords;
  }

  const [
    editjobMutation,
    { data: editData, loading: editLoading, error: editError },
  ] = useMutation(EDIT_JOB_OPENING, {});

  const [addjobKeywordMutation] = useMutation(ADD_JOB_KEYWORD, {});

  const [deletejobKeywordMutation] = useMutation(DELETE_JOB_KEYWORD, {});

  const handleDeletejob = (locationid) => {
    let path = `${process.env.PUBLIC_URL}/careers`;

    navigate(path);
  };

  const saveJobInfo = async (e) => {
    e.preventDefault();

    if (!validator.current.allValid() || !title.value) {
      validator.current.showMessages();
      forceUpdate(1);
      return toast.error("Cannot save changes. review errors and try again");
    }

    try {
      console.log(ispublic);
      console.log(isactive);
      await editjobMutation({
        variables: {
          employerId: Number(job.EmployerID),
          employerLocationsId: Number(job.EmployerLocationsID),
          employerUsersId: parseInt(GetUserID()),
          employerOpeningsId: parseInt(job.EmployerOpeningsID),
          title: title.value,
          listingDate: listingdate.value,
          about: about.value,
          active: isactive.value,
          public: ispublic.value,
        },
      });

      if (editError) {
        toast.error(editError.message);
      }

      const existingkeywords = job.Keywords.map((x) => x.KeywordID);

      const additions = keywordids.value.filter(
        (x) => !existingkeywords.includes(x)
      );
      const deletions = existingkeywords.filter(
        (x) => !keywordids.value.includes(x)
      );

      additions.map(async (x) => {
        await addjobKeywordMutation({
          variables: {
            employerOpeningsId: Number(job.EmployerOpeningsID),
            keywordId: Number(x),
          },
        });
      });

      deletions.map(async (x) => {
        await deletejobKeywordMutation({
          variables: {
            employerOpeningsId: Number(job.EmployerOpeningsID),
            keywordId: Number(x),
          },
        });
      });

      toast("Job Opening changes have been saved", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      let path = `${process.env.PUBLIC_URL}/careers`;

      navigate(path);
    } catch (err) {
      console.log(err);
    }
  };

  console.log(keywordids);
  var form = {
    employerId: parseInt(job.EmployerID),
    employerLocationsId: parseInt(job.EmployerLocationsID),
    employerOpeningsId: parseInt(job.EmployerOpeningsID),
    listingDate: listingdate,
    isPublic: ispublic,
    isActive: isactive,
    about: about,
    title: title,
    keywords: keywordids,
    keywordlist: keywordList,
  };

  return (
    <Fragment>
      <Breadcrumb parent="Beauty Career" title="Edit Job" />
      <div className="container-fluid">
        <div className="edit-profile">
          <div className="row">
            <LocationJobContact form={form} validator={validator} />
          </div>
          <div className="row">
            <div className="col-sm-12 mb-3">
              <Button
                color="success"
                className="me-3"
                onClick={(e) => {
                  saveJobInfo(e);
                }}
                name="Save"
              >
                Save Changes
              </Button>
              <Button
                color="danger"
                onClick={() => handleDeletejob(job.EmployerOpeningsID)}
              >
                Cancel{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default JobEdit;
