import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_PASSWORD } from "../../../api/db.js";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PasswordChecklist from "react-password-checklist";
import { css } from "styled-components";

const ProfileEditPassword = ({
  userId,
  email,
  onSave,
  showPasswordModal,
  currentPassword,
  newPassword,
  confirmNewPassword,
  canChangePassword,
}) => {
  const [passwordError, setPasswordError] = useState(false);

  const toggleCanChangePassword = (e) => {
    canChangePassword.setValue(e);
    setPasswordError(!currentPassword.value);
  };

  const toggleChangePassword = () => {
    showPasswordModal.setValue(!showPasswordModal.value);
  };

  const [editUserPassword, { error }] = useMutation(UPDATE_USER_PASSWORD, {});

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (
      !newPassword.value ||
      !confirmNewPassword.value ||
      !currentPassword.value
    ) {
      return toast.error("All Password fields are required");
    }
    try {
      await editUserPassword({
        variables: {
          userId: Number(userId),
          email: email,
          currentPassword: currentPassword.value,
          password1: newPassword.value,
          password2: confirmNewPassword.value,
        },
      });

      if (!error) {
        toast("Profile Password has been updated", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        onSave();
        toggleChangePassword();
      } else {
        if (error.message) toast.error(error.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="form-group mb-1">
        <label className="form-label mb-0">Password</label>
        <input
          className="form-control mb-2"
          placeholder="Password"
          name="password"
          value={"**********"}
          disabled={true}
        />
      </div>
      <Button
        className="btn btn-sm mt-0 mb-4"
        onClick={(e) => toggleChangePassword(e)}
      >
        Change Password
      </Button>
      <Modal isOpen={showPasswordModal.value}>
        <ModalHeader>Change Password</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                Current Password:
              </label>
              <input
                className="form-control"
                type="text"
                onChange={(e) => currentPassword.onChange(e)}
              />
              {passwordError && (
                <span className="txt-danger">Current Password is required</span>
              )}
            </div>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                New Password:
              </label>
              <input
                className="form-control"
                type="text"
                onChange={(e) => newPassword.onChange(e)}
              />
            </div>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                Confirm New Password:
              </label>
              <input
                className="form-control"
                type="text"
                onChange={(e) => confirmNewPassword.onChange(e)}
              />
              <PasswordChecklist
                rules={[
                  "minLength",
                  "specialChar",
                  "number",
                  "capital",
                  "match",
                  "notEmpty",
                ]}
                minLength={7}
                value={newPassword.value}
                valueAgain={confirmNewPassword.value}
                onChange={(isValid) => {
                  toggleCanChangePassword(isValid);
                }}
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!canChangePassword.value || !currentPassword.value}
            color="primary"
            onClick={(e) => handleChangePassword(e)}
          >
            Update Password
          </Button>
          <Button color="secondary" onClick={(e) => toggleChangePassword(e)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default ProfileEditPassword;
