import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../components/common/breadcrumb";
import { getContentfulType } from "../api/contentful-client";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

const Reports = () => {
  const [post, setPost] = useState(null);
  const [isloading, setIsloading] = useState(false);

  return (
    <Fragment>
      <Breadcrumb parent="Beauty Shop" title="Reports" />
      {isloading && (
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-md-6 xl-50">
              <div className="card mt-0">
                <div className="card-header p-3">
                  <h1 className="mb-0">{post.pageTitle}</h1>
                </div>
                <p>Coming Soon</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Reports;
