import React, { useRef, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import useInput from "../../../hooks/useInput";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";

const ResumeBuilderExperience = ({
  resumeid,
  experiences,
  onSaveExperience,
  onDeleteExperience,
  step,
  onChangeStep,
}) => {
  const [modalExperience, setModalExperience] = useState(false);
  var validator = useRef(new SimpleReactValidator({ autoForceUpdate: this }));
  const [, forceUpdate] = useState();
  const employerId = useInput();
  const employer = useInput("");
  const jobttile = useInput("");
  const jobdescription = useInput("");
  const startdatemonth = useInput("");
  const startdateyear = useInput("");
  const enddatemonth = useInput("");
  const enddateyear = useInput("");
  const city = useInput("");
  const experienceindex = useInput(0);
  const state = useInput("");
  const current = useInput(false);

  const deleteExperience = (index) => {
    console.log(index);
    onDeleteExperience(index);
    reset();
    validator.purgeFields();
  };

  const editExperience = (exp, i) => {
    console.log(i);
    experienceindex.setValue(i);
    employerId.setValue(exp.employmentHistoryID ?? exp.EmploymentHistoryID);
    employer.setValue(exp.employerName ?? exp.EmployerName);
    jobttile.setValue(exp.title ?? exp.Title);
    jobdescription.setValue(exp.description ?? exp.Description);
    startdatemonth.setValue(exp.startMonth ?? exp.StartMonth);
    startdateyear.setValue(exp.startYear ?? exp.StartYear);
    enddatemonth.setValue(exp.endMonth ?? exp.EndMonth);
    enddateyear.setValue(exp.endYear ?? exp.EndYear);
    city.setValue(exp.employerCity ?? exp.EmployerCity);
    state.setValue(exp.employerState ?? exp.EmployerState);
    current.setValue(exp.current ?? false);
    console.log(experienceindex.value);
    toggleExperienceModal();
  };

  const toggleExperienceModal = () => {
    setModalExperience(!modalExperience);
  };

  const reset = () => {
    employerId.setValue();
    employer.setValue("");
    jobttile.setValue("");
    jobdescription.setValue("");
    startdatemonth.setValue("");
    startdateyear.setValue("");
    enddatemonth.setValue("");
    enddateyear.setValue("");
    city.setValue("");
    state.setValue("");
    current.setValue(false);
    experienceindex.setValue(0);
  };

  const saveExperience = () => {
    if (!validator.current.allValid()) {
      validator.current.showMessages();
      forceUpdate(1);
      return toast.error("Cannot Save Resume");
    }

    onSaveExperience(
      {
        resumeeId: resumeid,
        employmentHistoryID: employerId.value,
        employerName: employer.value,
        title: jobttile.value,
        description: jobdescription.value,
        startMonth: startdatemonth.value,
        startYear: startdateyear.value,
        endMonth: enddatemonth.value,
        endYear: enddateyear.value,
        employerCity: city.value,
        employerState: state.value,
        current: current.value,
      },
      experienceindex.value
    );
    reset();
    toggleExperienceModal();
  };

  return (
    <>
      <div className="row">
        <div className="col-12 mb-5">
          <div className="main-bar">
            <h2>Experience</h2>
            <Button color="success" onClick={toggleExperienceModal}>
              {"+ Add Experience"}
            </Button>
          </div>
          <div className="slider">
            <p id="we_status"></p>
            {experiences &&
              experiences.map((exp, i) => (
                <div key={i} className="w-10-cont mb-3 border-bottom pb-2">
                  <div className="text-start w-10 pb-2 pb-md-0">
                    <span className="d-block fw-bold">Employer</span>
                    <span>{exp.employerName ?? exp.EmployerName}</span>
                  </div>
                  <div className="text-start w-10 pb-2 pb-md-0">
                    <span className="d-block fw-bold">Title</span>
                    <span>{exp.title ?? exp.EmployerName}</span>
                  </div>
                  <div className="text-start w-10 pb-2 pb-md-0">
                    <span className="d-block fw-bold">Date</span>
                    <span>
                      {exp.startYear ?? exp.StartYear} -
                      {exp.endYear == "1"
                        ? "Present"
                        : exp.endYear ?? exp.EndYear}
                    </span>
                  </div>
                  <div className="text-start w-10 pb-2 pb-md-0">
                    <span className="d-block fw-bold">Location</span>
                    <span>
                      {exp.employerCity ?? exp.EmployerCity},{" "}
                      {exp.employerState ?? exp.EmployerState}
                    </span>
                  </div>
                  <div className="text-start w-10 pb-2 pb-md-0">
                    <a
                      className=" me-3 btn btn-info gray raised small editworkexperience"
                      onClick={() => editExperience(exp, i)}
                    >
                      Edit
                    </a>
                    <a
                      className="btn btn-danger red raised small deleteworkexperience"
                      onClick={() => deleteExperience(i)}
                    >
                      Delete
                    </a>
                  </div>
                </div>
              ))}
          </div>
          <Modal isOpen={modalExperience} toggle={toggleExperienceModal}>
            <ModalHeader toggle={toggleExperienceModal}>
              Add Experience
            </ModalHeader>
            <ModalBody>
              <form name="frmWorkExperience" id="frmWorkExperience">
                <div>
                  <div className="mt-3">
                    <label>Employer:</label>
                    <input
                      className="field form-control"
                      name="employer"
                      id="employer"
                      value={employer.value}
                      onChange={employer.onChange}
                    />
                    {validator.current.message(
                      "employer",
                      employer.value,
                      "required"
                    )}
                  </div>
                  <div className="mt-3">
                    <label>Job Title:</label>
                    <input
                      className="field form-control"
                      name="jobtitle"
                      id="jobtitle"
                      value={jobttile.value}
                      onChange={jobttile.onChange}
                    />
                    {validator.current.message(
                      "job title",
                      jobttile.value,
                      "required"
                    )}
                  </div>
                  <div className="mt-3 d-none">
                    <label>Description:</label>
                    <textarea
                      className="textarea form-control"
                      id="job_description"
                      name="job_description"
                      placeholder="Write a description of your experience"
                      value={jobdescription.value}
                      onChange={jobdescription.onChange}
                    ></textarea>
                  </div>
                  <div className="mt-3">
                    <label>Start Date:</label>
                    <div className="drop_line month">
                      <span className="drop">
                        <select
                          name="sd_month"
                          className="form-control"
                          id="sd_month"
                          value={startdatemonth.value}
                          onChange={startdatemonth.onChange}
                        >
                          <option value="">Month</option>
                          <option value="1">January</option>
                          <option value="2">February</option>
                          <option value="3">March</option>
                          <option value="4">April</option>
                          <option value="5">May</option>
                          <option value="6">June</option>
                          <option value="7">July</option>
                          <option value="8">August</option>
                          <option value="9">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                        </select>
                        {validator.current.message(
                          "start month",
                          startdatemonth.value,
                          "required"
                        )}
                      </span>
                    </div>
                    <div className="drop_line year">
                      <span className="drop">
                        <span className="drop">
                          <select
                            name="sd_year"
                            id="sd_year"
                            className="form-control"
                            value={startdateyear.value}
                            onChange={startdateyear.onChange}
                          >
                            <option value="">Year</option>
                            <option value="2024">2024</option>
                            <option value="2023">2023</option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                            <option value="2020">2020</option>
                            <option value="2019">2019</option>
                            <option value="2018">2018</option>
                            <option value="2017">2017</option>
                            <option value="2016">2016</option>
                            <option value="2015">2015</option>
                            <option value="2014">2014</option>
                            <option value="2013">2013</option>
                            <option value="2012">2012</option>
                            <option value="2011">2011</option>
                            <option value="2010">2010</option>
                            <option value="2009">2009</option>
                            <option value="2008">2008</option>
                            <option value="2007">2007</option>
                            <option value="2006">2006</option>
                            <option value="2005">2005</option>
                            <option value="2004">2004</option>
                            <option value="2003">2003</option>
                            <option value="2002">2002</option>
                            <option value="2001">2001</option>
                            <option value="2000">2000</option>
                            <option value="1999">1999</option>
                            <option value="1998">1998</option>
                            <option value="1997">1997</option>
                            <option value="1996">1996</option>
                            <option value="1995">1995</option>
                            <option value="1994">1994</option>
                            <option value="1993">1993</option>
                            <option value="1992">1992</option>
                            <option value="1991">1991</option>
                            <option value="1990">1990</option>
                            <option value="1989">1989</option>
                            <option value="1988">1988</option>
                            <option value="1987">1987</option>
                            <option value="1986">1986</option>
                            <option value="1985">1985</option>
                            <option value="1984">1984</option>
                            <option value="1983">1983</option>
                            <option value="1982">1982</option>
                            <option value="1981">1981</option>
                            <option value="1980">1980</option>
                            <option value="1979">1979</option>
                            <option value="1978">1978</option>
                            <option value="1977">1977</option>
                            <option value="1976">1976</option>
                            <option value="1975">1975</option>
                            <option value="1974">1974</option>
                            <option value="1973">1973</option>
                            <option value="1972">1972</option>
                            <option value="1971">1971</option>
                            <option value="1970">1970</option>
                          </select>
                          {validator.current.message(
                            "start year",
                            startdateyear.value,
                            "required"
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <label>End Date:</label>
                    <div className="w-25 d-inline drop_line month">
                      <span className="drop">
                        <select
                          className="form-control"
                          name="ed_month"
                          id="ed_month"
                          value={enddatemonth.value}
                          onChange={enddatemonth.onChange}
                        >
                          <option value="">Month</option>
                          <option value="13">Present</option>
                          <option value="1">January</option>
                          <option value="2">February</option>
                          <option value="3">March</option>
                          <option value="4">April</option>
                          <option value="5">May</option>
                          <option value="6">June</option>
                          <option value="7">July</option>
                          <option value="8">August</option>
                          <option value="9">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                        </select>
                        {validator.current.message(
                          "end month",
                          enddatemonth.value,
                          "required"
                        )}
                      </span>
                    </div>
                    <div className="w-25 d-inline drop_line year">
                      <span className="drop">
                        <select
                          className="form-control"
                          name="ed_year"
                          id="ed_year"
                          value={enddateyear.value}
                          onChange={enddateyear.onChange}
                        >
                          <option value="0">Year</option>
                          <option value="1">Present</option>
                          <option value="2024">2024</option>
                          <option value="2023">2023</option>
                          <option value="2022">2022</option>
                          <option value="2021">2021</option>
                          <option value="2020">2020</option>
                          <option value="2019">2019</option>
                          <option value="2018">2018</option>
                          <option value="2017">2017</option>
                          <option value="2016">2016</option>
                          <option value="2015">2015</option>
                          <option value="2014">2014</option>
                          <option value="2013">2013</option>
                          <option value="2012">2012</option>
                          <option value="2011">2011</option>
                          <option value="2010">2010</option>
                          <option value="2009">2009</option>
                          <option value="2008">2008</option>
                          <option value="2007">2007</option>
                          <option value="2006">2006</option>
                          <option value="2005">2005</option>
                          <option value="2004">2004</option>
                          <option value="2003">2003</option>
                          <option value="2002">2002</option>
                          <option value="2001">2001</option>
                          <option value="2000">2000</option>
                          <option value="1999">1999</option>
                          <option value="1998">1998</option>
                          <option value="1997">1997</option>
                          <option value="1996">1996</option>
                          <option value="1995">1995</option>
                          <option value="1994">1994</option>
                          <option value="1993">1993</option>
                          <option value="1992">1992</option>
                          <option value="1991">1991</option>
                          <option value="1990">1990</option>
                          <option value="1989">1989</option>
                          <option value="1988">1988</option>
                          <option value="1987">1987</option>
                          <option value="1986">1986</option>
                          <option value="1985">1985</option>
                          <option value="1984">1984</option>
                          <option value="1983">1983</option>
                          <option value="1982">1982</option>
                          <option value="1981">1981</option>
                          <option value="1980">1980</option>
                          <option value="1979">1979</option>
                          <option value="1978">1978</option>
                          <option value="1977">1977</option>
                          <option value="1976">1976</option>
                          <option value="1975">1975</option>
                          <option value="1974">1974</option>
                          <option value="1973">1973</option>
                          <option value="1972">1972</option>
                          <option value="1971">1971</option>
                          <option value="1970">1970</option>
                        </select>
                        {validator.current.message(
                          "end year",
                          enddateyear.value,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="mt-3">
                    <label>City:</label>
                    <input
                      className="form-control"
                      name="city"
                      id="city"
                      value={city.value}
                      onChange={city.onChange}
                    />
                    {validator.current.message("city", city.value, "required")}
                  </div>
                  <div className="mt-3">
                    <label>State:</label>
                    <div className="drop_line">
                      <span className="drop">
                        <select
                          name="state"
                          id="state"
                          className="form-control ui search selection dropdown"
                          value={state.value}
                          onChange={state.onChange}
                        >
                          <option value="">Select A State</option>
                          <option value="AK">Alaska</option>
                          <option value="AZ">Arizona</option>
                          <option value="AR">Arkansas</option>
                          <option value="CA"> California</option>
                          <option value="CO"> Colorado</option>
                          <option value="CT"> Connecticut</option>
                          <option value="DE"> Delaware</option>
                          <option value="DC"> District of Columbia</option>
                          <option value="FL"> Florida</option>
                          <option value="GA"> Georgia</option>
                          <option value="HI"> Hawaii</option>
                          <option value="ID"> Idaho</option>
                          <option value="IL"> Illinois</option>
                          <option value="IN"> Indiana</option>
                          <option value="IA"> Iowa</option>
                          <option value="KS"> Kansas</option>
                          <option value="KY"> Kentucky</option>
                          <option value="LA"> Louisiana</option>
                          <option value="ME"> Maine</option>
                          <option value="MD"> Maryland</option>
                          <option value="MA"> Massachusetts</option>
                          <option value="MI"> Michigan</option>
                          <option value="MN"> Minnesota</option>
                          <option value="MS"> Mississippi</option>
                          <option value="MO"> Missouri</option>
                          <option value="MT"> Montana</option>
                          <option value="NE"> Nebraska</option>
                          <option value="NV"> Nevada</option>
                          <option value="NH"> New Hampshire</option>
                          <option value="NJ"> New Jersey</option>
                          <option value="NM"> New Mexico</option>
                          <option value="NY"> New York</option>
                          <option value="NC"> North Carolina</option>
                          <option value="ND"> North Dakota</option>
                          <option value="OH"> Ohio</option>
                          <option value="OK"> Oklahoma</option>
                          <option value="OR"> Oregon</option>
                          <option value="PA"> Pennsylvania</option>
                          <option value="RI"> Rhode Island</option>
                          <option value="SC"> South Carolina</option>
                          <option value="SD"> South Dakota</option>
                          <option value="TN"> Tennessee</option>
                          <option value="TX"> Texas</option>
                          <option value="UT"> Utah</option>
                          <option value="VT"> Vermont</option>
                          <option value="VA"> Virginia</option>
                          <option value="WA"> Washington</option>
                          <option value="WV"> West Virginia</option>
                          <option value="WI"> Wisconsin</option>
                          <option value="WY"> Wyoming</option>
                        </select>
                        {validator.current.message(
                          "state",
                          state.value,
                          "required"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => saveExperience(e)}>
                {employerId && employerId.value
                  ? "Save Changes"
                  : "Add Experience"}{" "}
              </Button>
              <Button
                color="secondary"
                onClick={(e) => toggleExperienceModal(e)}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
      <div class="footer-buttons">
        <button
          type="button"
          class="btn btn-next btn-primary btn-lg pull-left"
          id="prev-button"
          onClick={() => onChangeStep(step.value - 1)}
        >
          Previous
        </button>
        <button
          type="button"
          class="btn btn-prev btn-primary btn-lg pull-right"
          id="next-button"
          onClick={() => onChangeStep(step.value + 1)}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default ResumeBuilderExperience;
