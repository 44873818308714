import React, { Fragment, useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { GET_STUDENT_DETAILS } from "../../../api/db.js";
import {
  ClearFirstLogin,
  GetFirstLogin,
  GetUserID,
} from "../../../api/session.js";
import ContactInformation from "./profile-edit-contact";
import ResumeManager from "./profile-edit-resume";
import Portfolio from "./profile-edit-portfolio";
import Breadcrumb from "../../common/breadcrumb.js";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ProfileImageEdit from "./profile-edit-image.js";
import ProfileEditEmail from "./profile-edit-email.js";
import useInput from "../../../hooks/useInput.js";
import ProfileEditPassword from "./profile-edit-password.js";
import SimpleReactValidator from "simple-react-validator";

const ProfileEditStudent = () => {
  var form = {
    userid: "",
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
  };

  const hasApproved = useInput(false);
  const showModal = useInput(false);
  const newEmail = useInput();
  const showPasswordModal = useInput(false);
  const currentPassword = useInput("");
  const newPassword = useInput("");
  const confirmNewPassword = useInput("");
  const canChangePassword = useInput(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const { loading, error, data, refetch } = useQuery(GET_STUDENT_DETAILS, {
    variables: { userId: GetUserID() },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
  });

  /*useEffect(() => {
    console.log(data);
    toggleFirstLocationModal();
  }, [data]);
*/
  if (loading && !isRefreshing)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );
  if (error) return <p>Error : {error.message}</p>;

  const handleReloading = () => {
    setIsRefreshing(true);
    refetch();
  };

  if (data) {
    console.log(data);
    form = {
      userid: data.student.UserID,
      firstName: data.student.FirstName ?? "",
      lastName: data.student.LastName ?? "",
      address1: data.student.Address1 ?? "",
      address2: data.student.Address2 ?? "",
      city: data.student.City ?? "",
      state: data.student.State,
      zip: data.student.Zipcode ?? "",
      phone: data.student.Phone ?? "",
      email: data.student.Email ?? "",
      password: "",
      openForWork: Boolean(data.student.isprivate ?? false),
    };
  }
  return (
    <Fragment>
      <Breadcrumb title="Profile" />
      <div className="container-fluid">
        <div className="edit-profile">
          <div className="row">
            <div className="col-lg-4">
              <div className="card mt-0">
                <div className="card-header">
                  <h4 className="card-title mb-0">My Profile</h4>
                  <div className="card-options">
                    <a
                      className="card-options-collapse"
                      href="javascript"
                      data-toggle="card-collapse"
                    >
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a
                      className="card-options-remove"
                      href="javascript"
                      data-toggle="card-remove"
                    >
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <ProfileImageEdit
                    isStudent={true}
                    profile={data.student.ProfileImage}
                    banner={data.student.BannerImage}
                    FirstName={data.student.FirstName}
                    LastName={data.student.LastName}
                    RoleName={data.student.RoleName}
                    onSave={handleReloading}
                    showLoading={loading && isRefreshing}
                  />
                  <ProfileEditEmail
                    form={form}
                    isStudent={true}
                    onSave={refetch}
                    hasApproved={hasApproved}
                    showModal={showModal}
                    newEmail={newEmail}
                  />
                  <ProfileEditPassword
                    userId={form.userid}
                    email={form.email}
                    onSave={refetch}
                    showPasswordModal={showPasswordModal}
                    currentPassword={currentPassword}
                    newPassword={newPassword}
                    confirmNewPassword={confirmNewPassword}
                    canChangePassword={canChangePassword}
                  />
                </div>
              </div>
            </div>
            <ContactInformation form={form} isStudent={true} onSave={refetch} />
            <ResumeManager
              resumees={data.student.Resumees}
              refreshCallback={refetch}
            />
            <Portfolio
              portfolios={data.student.Portfolios}
              refreshCallback={refetch}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProfileEditStudent;
