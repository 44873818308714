import React, { useState } from 'react'
import Login from './auth-login.js'
import Signup from './auth-register.js'

const Auth = () => {
  const [authAction, setAuthAction] = useState('LOGIN')
  const changeToLogin = () => setAuthAction('LOGIN')
  const changeToSignup = () => setAuthAction('SIGNUP')

  return (
    <>
      {authAction === 'LOGIN' ? (
        <Login changeToSignup={changeToSignup} />
      ) : (
        <Signup changeToLogin={changeToLogin} />
      )}
    </>
  )
}

export default Auth
