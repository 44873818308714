import React, { useState } from 'react'
import LightBox from '../../common/lightBox'

const LocationViewPhotosPanel = ({ portfolios }) => {
  var portfolioimages = []

  portfolios.map((items, i) => {
    portfolioimages.push(items.Src)
  })
  return <LightBox images={portfolioimages} />
}

export default LocationViewPhotosPanel
