import BackToTop from "./assets/BackToTop";
import Contact from "./assets/Contact";
import Cta from "./assets/Cta";
import Faqs from "./assets/Faqs";
import Features1 from "./assets/Features1";
import Features2 from "./assets/Features2";
import Hero2  from "./assets/Hero2";
import HowItWork from "./assets/HowItWork";
import Testimonial from "./assets/Testimonial";
import Login from "./assets/Login";
import Signin from "./assets/Signin";
import Slider from "./assets/Slider";
import Pricing from "./assets/Pricing";
import Footer from "./assets/Footer";
import Counter from "./assets/Counter";
import  Navbar1  from "./assets/navbar/Navbar1";
import "./assets/css/style.css";

const HomePage = () => {
  return (
    <>
      {/* navbar */}
      <Navbar1 isLogoDark={true} />
      {/* header and hero */}
      <div id="home">
      <Hero2 />
      {/* how it work */}
      <HowItWork />
      </div>
      <div id="features">
      {/* features1 */}
      <Features1 />
      {/* fetures2 */}
      <Features2 />
      {/* counter */}
      <Counter />
      </div>
      <div id="screenshot">
      {/* slider */}
      <Slider />
      </div>
      {/* testimonial */}
      <Testimonial />
      <div id="faq">
      {/* faqs */}
      <Faqs />
      {/* cta */}
      <Cta />
      </div>
     
      {/* footer */}
      <Footer />
      {/* back to top button */}
      <BackToTop />
      {/* login form */}
      <Login />
      {/* signin form */}
      <Signin />
    </>
  );
};

export default HomePage;
