import React, { Fragment, useState, useEffect } from "react";
import man from "../../../assets/images/dashboard/user.png";
import { User, LogOut } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import {
  GET_STUDENT_DETAILS,
  GET_PORTFOLIO,
  GET_USER_INFO,
} from "../../../api/db.js";
import { GetUserID, GetUserRole } from "../../../api/session.js";

const UserMenu = () => {
  const [profile, setProfile] = useState("");
  const authenticated = localStorage.getItem("authenticated");
  const apitoken = localStorage.getItem("token");
  const auth0_profile = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  var profileurl;

  useEffect(() => {
    var profileurl = localStorage.getItem("profileURL");

    setProfile(profileurl ?? man);
  }, []);

  const Logout_From_App = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.setItem("authenticated", false);
    window.location.href = "/";
  };

  const Logout_To_LoginPage = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.setItem("authenticated", false);
    window.location.href = "/login";
  };

  if (!apitoken) Logout_To_LoginPage();

  const { loading, error, data } = useQuery(GET_USER_INFO, {
    variables: { userId: GetUserID() },
  });

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );
  if (error) {
    console.log("fired");
    console.log(error);
    if (error) {
      Logout_To_LoginPage();
    } else {
      return <p>Error : {error.message}</p>;
    }
  }

  if (data.user.ProfileImage) {
    profileurl = data.user.ProfileImage;
  } else {
    profileurl = "/images/profile-student.jpg";
  }

  return (
    <Fragment>
      <li className="nav-item onhover-dropdown me-3">
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          <li>
            <Link to={`${process.env.PUBLIC_URL}/settings/profile`}>
              <User />
              Edit Profile
            </Link>
          </li>
          <li>
            <a onClick={Logout_From_App} href="/">
              <LogOut /> {"Log out"}
            </a>
          </li>
        </ul>
        <div className="d-flex align-items-center">
          <img
            className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
            src={profileurl}
            alt="header-user"
          />
        </div>
      </li>
    </Fragment>
  );
};

export default UserMenu;
