import React, { Fragment, useState } from 'react'
import { Alert } from 'reactstrap'
import { useQuery, gql, useLazyQuery, useMutation } from '@apollo/client'
import logo from '../../assets/images/endless-logo.png'
import { SetUserJWT, LoginSuccess, GetFirstLogin, GetLoginPageAlert } from '../../api/session.js'
import { GET_USER_AUTH, LOGIN_USER_GRAPH } from '../../api/db.js'
import ForgotPassword from './auth-forgotpassword'
import Register from './auth-register.js'

let email
let password
//let Gqllogin;

const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [showForgotpassword, setForgotPassword] = useState('')
  const [showRegister, setRegister] = useState(false)
  const isFirstLogin = GetFirstLogin()
  const hasLoginAlert = GetLoginPageAlert()
  var isVerified = false
  var verifiedFailed = false
  const handleUserNameChange = (e) => setUsername(e.target.value)
  const handleForgotPassword = (e) => setForgotPassword(true)
  const handlePasswordChange = (e) => setPassword(e.target.value)
  const handleRegister = (e) => {
    var shouldregister = !showRegister
    setRegister(shouldregister)
  }

  const [logUserIn, { loading, saveerror, data }] =
    useMutation(LOGIN_USER_GRAPH)

  //console.log('handlesubmit!');

  if (saveerror) return <p>Error:{saveerror.message}</p>
  const handleLogin = async (e) => {
    e.preventDefault()

    if (!username) return alert('Write something')

    try {
      await logUserIn({ variables: { email: username, password: password } })
    } catch (err) {
      console.log(err)

      setError(err.message)
      setUsername('')
      setPassword('')
    }
  }

  if (window.location.href.indexOf('verified') > -1) {
    isVerified = true
  }

  if (window.location.href.indexOf('verifyfailed') > -1) {
    isVerified = false
    verifiedFailed = true;
    //setError("Cannot verify email. Please contact support")
  }

  if (data && data.loginUser.token) {
    console.log('logged in successfully now redirecting the application')
    console.log(data)
    SetUserJWT(data.loginUser.token)
    LoginSuccess(data.loginUser)

    if (isFirstLogin) window.location.href = '/settings/profile'
    else window.location.href = '/'
  }
  const showloader = loading || data

  if (showForgotpassword) {
    return <ForgotPassword />
  }

  if (showRegister) {
    return <Register login={handleRegister} />
  }
  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="auth-bg">
          <div className="authentication-box">
            <div className="text-center">
              <a href="/">
                <img src={logo} alt="" />
              </a>
            </div>
            <div className="card mt-4 mb-2">
              <div className="card-body">
                <div className="text-center">
                  <h4>Login</h4>
                  <h6>{'Enter your Username and Password'} </h6>
                  {error && (
                    <Alert color="danger dark alert-dismissible">
                      <p>{error}</p>
                    </Alert>
                  )}
                  {verifiedFailed && (
                    <Alert color="danger dark alert-dismissible">
                      <p>{"Cannot verify email. Please contact support"}</p>
                    </Alert>
                  )}
                  {isFirstLogin && !isVerified && (
                    <Alert color="success dark alert-dismissible">
                      <p>
                        {
                          'Thank you for signing up! Please check your email for a verification link to log in'
                        }
                      </p>
                    </Alert>
                  )}
                   {hasLoginAlert && !isVerified && (
                    <Alert color="success dark alert-dismissible">
                      <p>
                        {
                         hasLoginAlert
                        }
                      </p>
                    </Alert>
                  )}
                  {isVerified && (
                    <Alert color="success dark alert-dismissible">
                      <p>{'Account Verified. Please sign in'}</p>
                    </Alert>
                  )}
                </div>
                {showloader && (
                  <div className="theme-form">
                    <div className="loader-box" style={{ display: 'flex' }}>
                      <div
                        className="loader"
                        style={{ alignItems: 'center', width: '100%' }}
                      >
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                      </div>
                    </div>
                  </div>
                )}

                {!showloader && (
                  <form className="theme-form" onSubmit={(e) => handleLogin(e)}>
                    <div className="form-group">
                      <label className="col-form-label pt-0">
                        Email Address
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        required=""
                        placeholder="test@gmail.com"
                        onChange={(e) => handleUserNameChange(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">Password</label>
                      <input
                        className="form-control"
                        type="password"
                        required=""
                        placeholder="password"
                        onChange={(e) => handlePasswordChange(e)}
                      />
                    </div>
                    <div className="form-group form-row mt-3 mb-0 d-grid">
                      <button className="btn btn-primary" type="submit">
                        Login
                      </button>
                      <div className="py-2">
                        <a href="#" onClick={(e) => handleForgotPassword(e)}>
                          Forgot Password
                        </a>
                      </div>
                      <a href="/signup">Register</a>
                    </div>
                    <div className="login-divider d-none"></div>
                    <div className="social mt-3 d-none">
                      <div className="form-group btn-showcase d-flex">
                        <button className="btn social-btn btn-fb d-inline-block">
                          {' '}
                          <i className="fa fa-facebook"></i>
                        </button>
                        <button className="btn social-btn btn-twitter d-inline-block">
                          <i className="fa fa-google"></i>
                        </button>
                        <button className="btn social-btn btn-google d-inline-block">
                          <i className="fa fa-twitter"></i>
                        </button>
                        <button className="btn social-btn btn-github d-inline-block">
                          <i className="fa fa-github"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
            <div className="text-end">
              <a href="/">
                <span>
                  <i className="fa fa-angle-left pe-2"></i>Go Back
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default Login
