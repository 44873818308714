import React, { useState, Fragment, Component } from "react";
import { Breadcrumb } from "reactstrap";
import StepZilla from "react-stepzilla";
import { useMutation } from "@apollo/client";
import ResumeBuilderExperience from "./resume-builder-experience";
import ResumeBuilderEducation from "./resume-builder-education";
import ResumeBuilderSettings from "./resume-builder-settings";
import ResumeBuilderComplete from "./resume-builder-complete";
import {
  ADD_RESUME,
  EDIT_RESUME,
  ADD_EMPLOYMENT,
  EDIT_EMPLOYMENT,
  DELETE_EMPLOYMENT,
  ADD_EDUCATION,
  EDIT_EDUCATION,
  DELETE_EDUCATION,
} from "../../../api/db";
import { GetUserID, GetStudentId } from "../../../api/session";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useInput from "../../../hooks/useInput";

const ResumeBuilder = ({ resume }) => {
  console.log(resume);
  const step = useInput(1);
  const [studentId, setStudentId] = useState(GetStudentId(GetUserID()) ?? "");
  const [resumeId, setResumeId] = useState((resume && resume.resumeId) ?? "");
  const [isPrimary, setPrimary] = useState(
    (resume && resume.isPrimary) ?? false
  );
  const [title, setTitle] = useState((resume && resume.Title) ?? "");
  const [careerType, setCareerType] = useState(
    (resume && resume.CareerID) ?? "1"
  );
  const [objective, setObjective] = useState(
    (resume && resume.Objective) ?? ""
  );
  const [color, setColor] = useState((resume && resume.ResumeeColorID) ?? "1");
  const [style, setStyle] = useState((resume && resume.ResumeeStyleID) ?? "1");
  const [experiences, setExperience] = useState(
    (resume && resume.EmploymentHistory) ?? []
  );
  const [educations, setEducation] = useState(
    (resume && resume.EducationHistory) ?? []
  );
  const [colorValue, setColorValue] = useState(
    (resume && resume.ColorValue) ?? "blue"
  );

  const [addResume] = useMutation(ADD_RESUME, {});

  const [editResume] = useMutation(EDIT_RESUME, {});

  const [addEducation] = useMutation(ADD_EDUCATION, {});

  const [editEducation] = useMutation(EDIT_EDUCATION, {});

  const [deleteEducation] = useMutation(DELETE_EDUCATION, {});

  const [addEmployment] = useMutation(ADD_EMPLOYMENT, {});

  const [editEmployment] = useMutation(EDIT_EMPLOYMENT, {});

  const [deleteEmployment] = useMutation(DELETE_EMPLOYMENT, {});

  const handleSaveExperience = (experience, i) => {
    saveEmployment(experience, i);
  };

  const handleSaveEducation = (education, i) => {
    saveEducation(education, i);
  };

  const handleRemoveExperience = (i) => {
    var exp = experiences[i];
    console.log(exp);
    if (exp) {
      removeEmployment(exp.employmentHistoryID, i);
    }
  };

  const removeEmployment = async (id, i) => {
    console.log(id);
    try {
      if (id) {
        await deleteEmployment({ variables: { employmentHistoryId: id } });
        experiences.splice(i, 1);
        setExperience(experiences);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemoveEducation = (i) => {
    var exp = educations[i];
    console.log(i);
    console.log(exp);
    if (exp) {
      removeEducation(exp.educationHistoryId, i);
    }
  };

  const removeEducation = async (id, i) => {
    console.log(i);
    try {
      if (id) {
        await deleteEducation({ variables: { educationHistoryId: id } });
        educations.splice(i, 1);

        setEducation(educations);

        console.log(educations);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const changeStep = (newstep) => {
    step.setValue(newstep);
  };

  const handleResumeSave = (resume) => {
    console.log(resume);
    setPrimary(resume.isPrimary);
    setColor(resume.color);
    setStyle(resume.style);
    setCareerType(resume.careerType);
    setObjective(resume.objective);
    setTitle(resume.title);

    if (resume.color == "1") setColorValue("blue");

    if (resume.color == "2") setColorValue("green");

    if (resume.color == "3") setColorValue("pink");

    saveResume(resume);
  };

  const saveResume = async (settings) => {
    console.log("saving settings....");
    console.log(settings.isPrimary == true);
    console.log(settings);
    try {
      if (resumeId) {
        await editResume({
          variables: {
            resumeeId: resumeId,
            resumeeStyleId: parseInt(settings.style),
            resumeeColorId: parseInt(settings.color),
            careerId: parseInt(settings.careerType),
            title: settings.title,
            objective: settings.objective,
            isPrimary: settings.isPrimary == true,
            active: true,
          },
        });
      } else {
        var result = await addResume({
          variables: {
            studentId: studentId,
            resumeeStyleId: parseInt(settings.style),
            resumeeColorId: parseInt(settings.color),
            careerId: parseInt(settings.careerType),
            title: settings.title,
            objective: settings.objective,
            isPrimary: settings.isPrimary == true,
            active: true,
          },
        });

        if (result) {
          setResumeId(result.data.addStudentResumee);
        }
      }

      toast("Your Resume has been Saved", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const saveEducation = async (education, i) => {
    console.log("saving education....");
    try {
      console.log(education);
      if (education.educationHistoryId) {
        await editEducation({
          variables: {
            educationHistoryId: education.educationHistoryId,
            school: education.school,
            city: education.city,
            state: education.state,
            startMonth: parseInt(education.startMonth),
            startYear: parseInt(education.startYear),
            certificate: education.certificate,
            endMonth: parseInt(education.endMonth),
            endYear: parseInt(education.endYear),
            current: education.current == "true",
          },
        });

        educations[i] = education;

        setEducation(educations);
      } else {
        var result = await addEducation({
          variables: {
            resumeeId: resumeId,
            school: education.school,
            city: education.city,
            state: education.state,
            startMonth: parseInt(education.startMonth),
            startYear: parseInt(education.startYear),
            certificate: education.certificate,
            endMonth: parseInt(education.endMonth),
            endYear: parseInt(education.endYear),
            current: education.current == "true",
          },
        });

        if (result) {
          education.educationHistoryId = result.data.addEducationHistory;

          if (!educations) {
            setEducation([education]);
          } else {
            setEducation((educations) => [...educations, education]);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const saveEmployment = async (employment, i) => {
    console.log("saving employment....");
    console.log(employment);
    try {
      if (employment.employmentHistoryID) {
        console.log("saving employment edit....");
        await editEmployment({
          variables: {
            employmentHistoryId: employment.employmentHistoryID,
            employerName: employment.employerName,
            employerCity: employment.employerCity,
            employerState: employment.employerState,
            startMonth: parseInt(employment.startMonth),
            startYear: parseInt(employment.startYear),
            title: employment.title,
            description: employment.description,
            endMonth: parseInt(employment.endMonth),
            endYear: parseInt(employment.endYear),
            current: employment.current == "true",
          },
        });

        console.log(i);
        experiences[i] = employment;

        console.log(experiences);

        setExperience(experiences);
      } else {
        var result = await addEmployment({
          variables: {
            resumeeId: resumeId,
            employerName: employment.employerName,
            employerCity: employment.employerCity,
            employerState: employment.employerState,
            startMonth: parseInt(employment.startMonth),
            startYear: parseInt(employment.startYear),
            title: employment.title,
            description: employment.description,
            endMonth: parseInt(employment.endMonth),
            endYear: parseInt(employment.endYear),
            current: employment.current == "true",
          },
        });

        if (result) {
          console.log(result);
          employment.employmentHistoryID = result.data.addEmploymentHistory;

          if (!experiences) {
            setExperience([employment]);
          } else {
            setExperience((experiences) => [...experiences, employment]);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <div className="container-fluid g-0">
        <div className="row">
          <div className="col-sm-12">
            <div className="card mt-0">
              <div className="card-header">
                <h5>Resume Builder</h5>
              </div>
              <div className="card-body">
                {step.value <= 1 && (
                  <ResumeBuilderSettings
                    settings={{
                      isPrimary,
                      title,
                      careerType,
                      objective,
                      color,
                      style,
                      colorValue,
                    }}
                    step={step}
                    onChangeStep={changeStep}
                    onSettingsSave={handleResumeSave}
                  />
                )}
                {step.value == 2 && (
                  <ResumeBuilderExperience
                    resumeid={resumeId}
                    experiences={experiences}
                    onSaveExperience={handleSaveExperience}
                    onDeleteExperience={handleRemoveExperience}
                    step={step}
                    onChangeStep={changeStep}
                  />
                )}
                {step.value == 3 && (
                  <ResumeBuilderEducation
                    resumeid={resumeId}
                    educations={educations}
                    experienceCount={experiences.length}
                    onSaveEducation={handleSaveEducation}
                    onDeleteEducation={handleRemoveEducation}
                    step={step}
                    onChangeStep={changeStep}
                  />
                )}
                {step.value >= 4 && (
                  <ResumeBuilderComplete
                    resume={{
                      resumeId,
                      isPrimary,
                      title,
                      careerType,
                      objective,
                      color,
                      style,
                      experiences,
                      educations,
                      firstName: resume.FirstName,
                      lastName: resume.LastName,
                      email: resume.Email,
                      phone: resume.phone,
                      studentId,
                    }}
                    step={step}
                    onChangeStep={changeStep}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ResumeBuilder;
