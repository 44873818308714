import React, { useState } from "react";
import ConversationUserIcon from "./conversation-user-icon";
import { useQuery, gql } from "@apollo/client";
import { GET_USER_INFO } from "../../api/db.js";
import { GetUserID } from "../../api/session.js";

const ConversationHeader = ({ user, conversationid }) => {
  const [menuToggle, setMenuToggle] = useState(false);

  const chatMenuToggle = () => {
    setMenuToggle(!menuToggle);
  };
  var userId = GetUserID();
  const { loading, error, data } = useQuery(GET_USER_INFO, {
    variables: { userId: userId },
  });

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );
  if (error) return <p>Error : {error.message}</p>;

  return (
    <div className="chat-header clearfix">
      <ConversationUserIcon
        user={{
          Name: user.FirstName + " " + user.LastName,
          ProfileImage: user.ProfileImage,
        }}
        key={user.UserID}
      />
      <div className="about">
        <div className="name">{user.FirstName + " " + user.LastName}</div>
        <div className="status digits">
          {data.user && data.user.Online == true ? "online" : ""}
        </div>
      </div>
      <ul className="list-inline float-start float-sm-end chat-menu-icons"></ul>
    </div>
  );
};

export default ConversationHeader;
