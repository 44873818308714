//image
import { Col, Container, Row } from "react-bootstrap";
import features1 from "../assets/images/features-1.png";

const Features1 = () => {
  return (
    <section className="section bg-light" >
      <Container>
        <Row className="justify-content-center mb-5">
          <Col md={8} lg={6} className="text-center">
            <h6 className="subtitle">
              Our <span className="fw-bold">Features</span>
            </h6>
            <h2 className="title">Student / Stylist Features</h2>
            <p className="text-muted">
            BeautyCareer.com is a website for our students and alumni seeking career opportunities. Stylists can create resumes, search for career opportunities and apply for open positions through one simple site.
            </p>
          </Col>
        </Row>

        <Row className="align-items-center">
          <Col lg={6}>
            <img
              src={features1}
              alt=""
              className="img-fluid d-block mx-auto ms-lg-auto"
            />
          </Col>
          <Col lg={5} className="offset-lg-1">
            <h1 className="mb-4 title">On Beautycareer.com you can:</h1>
            <p className="text-muted">
           Beautycareer.com simplifies the job search process for stlists and salon owners. Here are a few ways we make it easier for you:
            </p>

            <div className="d-flex mb-3">
              <div className="flex-shrink-0">
                <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                  <i className="fa fa-check fs-4 pt-1"></i>
                </span>
              </div>
              <div className="flex-grow-1 ms-4">
                <p className="text-muted">
                  <span className="text-dark fw-bold">
                    Resume builder:
                  </span>{" "}
                   Follow an easy-to-use step by step resume builder to create a professional resume in minutes.
                </p>
              </div>
            </div>
            <div className="d-flex mb-3">
              <div className="flex-shrink-0">
                <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                  <i className="fa fa-image fs-5"></i>
                </span>
              </div>
              <div className="flex-grow-1 ms-4">
                <p className="text-muted">
        
                  <span className="text-dark fw-bold">
                    Portfolio builder:
                  </span>{" "}
                  Upload photos of your work to create a digital portfolio that employers can review.
                </p>
              </div>
            </div>

            <div className="d-flex mb-3">
              <div className="flex-shrink-0">
                <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                <i class="fa fa-eye fs-5"></i>
                </span>
              </div>
              <div className="flex-grow-1 ms-4">
                <p className="text-muted">
                  <span className="text-dark fw-bold">
                    Search & apply for jobs:
                  </span>{" "}
                  Search our extensive database of stlist jobs and apply for open positions.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Features1;
