import React from "react";
import { Routes, Route } from "react-router-dom";
import AppLayout from "../../components/app";

import Careers from "../../routes/careers";
import Locations from "../../routes/locations";
import Location from "../../components/locations/location";
import JobEdit from "../../components/locations/jobs/view/location-job";
import History from "../../routes/history";
import Messages from "../../routes/messages";
import Activity from "../../routes/activity";
import Help from "../../routes/help";
import Auth from "../../components/auth/auth";
import EditProfile from "../../components/profiles/profile-edit/profile-edit";
import ForgotPassword from "../../components/auth/auth-forgotpassword";
import ResetPassword from "../../components/auth/auth-resetpassword";
import JobApplicants from "../../components/locations/jobs/view/location-job-applicants";
import Profile from "../../components/profiles/profile";
import Students from "../students";
import TermsOfService from "../terms";
import PrivacyPolicy from "../privacy";
import School from "../school";
import Schools from "../schools";
import Reports from "../reports";
import Employers from "../employers";

const AppAdminRouter = () => {
  return (
    <>
      <Routes>
        <Route element={<AppLayout />} key={1}>
          <Route exact path="/" element={<Schools key={1} />} />
        </Route>
        <Route element={<AppLayout />} key={1}>
          <Route exact path="/careers" element={<Careers key={2} />} />
        </Route>
        <Route element={<AppLayout />} key={1}>
          <Route exact path="/careers/:id" element={<Careers />} />
        </Route>
        <Route element={<AppLayout />} key={7}>
          <Route exact path="/settings/profile" element={<EditProfile />} />
        </Route>
        <Route element={<AppLayout />} key={1}>
          <Route exact path="/applicants/:id" element={<JobApplicants />} />
        </Route>
        <Route element={<AppLayout />} key={2}>
          <Route exact path="/locations" element={<Locations />} />
        </Route>
        <Route element={<AppLayout />} key={4}>
          <Route exact path="/location/:id" element={<Location />} />
        </Route>
        <Route element={<AppLayout />} key={2}>
          <Route exact path="/jobs" element={<JobEdit />} />
        </Route>
        <Route element={<AppLayout />} key={4}>
          <Route exact path="/job-edit/:id" element={<JobEdit />} />
        </Route>
        <Route element={<AppLayout />} key={3}>
          <Route exact path="/activity" element={<Activity />} />
        </Route>
        <Route element={<AppLayout />} key={14}>
          <Route exact path="/school/:id" element={<School />} />
        </Route>
        <Route element={<AppLayout />} key={15}>
          <Route exact path="/schools" element={<Schools />} />
        </Route>
        <Route element={<AppLayout />} key={4}>
          <Route exact path="/messages/:conversation" element={<Messages />} />
        </Route>
        <Route element={<AppLayout />} key={5}>
          <Route exact path="/messages" element={<Messages />} />
        </Route>
        <Route element={<AppLayout />} key={6}>
          <Route exact path="/history" element={<History />} />
        </Route>
        <Route element={<AppLayout />} key={8}>
          <Route exact path="/help" element={<Help />} />
        </Route>
        <Route element={<AppLayout />} key={9}>
          <Route exact path="/login" element={<Auth />} />
        </Route>
        <Route element={<AppLayout />} key={10}>
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
        </Route>
        <Route element={<AppLayout />} key={11}>
          <Route exact path="/reset-password" element={<ResetPassword />} />
        </Route>
        <Route element={<AppLayout />} key={9}>
          <Route exact path="/students/:id" element={<Profile />} />
        </Route>
        <Route element={<AppLayout />} key={9}>
          <Route exact path="/students-search" element={<Students />} />
        </Route>
        <Route element={<AppLayout />} key={9}>
          <Route exact path="/terms" element={<TermsOfService />} />
        </Route>
        <Route element={<AppLayout />} key={9}>
          <Route exact path="/privacy" element={<PrivacyPolicy />} />
        </Route>
        <Route element={<AppLayout />} key={9}>
          <Route exact path="/employers" element={<Employers />} />
        </Route>
        <Route element={<AppLayout />} key={9}>
          <Route exact path="/reports" element={<Reports />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppAdminRouter;
