import React from "react";
import { useQuery } from "@apollo/client";
import { GET_STUDENT_DETAILS } from "../api/db.js";
import { GetUserID } from "../api/session.js";
import JobSearch from "../components/search-jobs/job-search";
import { errorCheck } from "../components/common/functions.js";

const Jobs = () => {
  const { loading, error, data } = useQuery(GET_STUDENT_DETAILS, {
    variables: { userId: parseInt(GetUserID()) },
  });

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );

  if (error) {
    return errorCheck(error);
  }

  return <JobSearch query={data.student.Zipcode} />;
};

export default Jobs;
