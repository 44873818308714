import React, { Fragment } from 'react'
import { MoreVertical, ThumbsUp, UserPlus, MessageSquare } from 'react-feather'
import EmployerViewPhotosPanel from './employer-view-photos-panel'
import EmployerViewLeftBar from './employer-view-info-left'
import { snippet } from '../common/functions'

const EmployerViewInfo = ({ form, locations, openings, portfolios }) => {
  console.log(form)
  console.log(locations)
  console.log(openings)

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-3 xl-40 col-lg-12 col-md-5">
          <div
            className="default-according style-1 faq-accordion job-accordion"
            id="accordionoc2"
          >
            <div className="row">
              <EmployerViewLeftBar form={form} />
            </div>
          </div>
        </div>
        <div className="col-xl-5 xl-60 col-lg-12 col-md-7">
          <div className="row">
            {form.about && (
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h5>About Us</h5>
                  </div>
                  <div className="card-body avatar-showcase">
                    <p dangerouslySetInnerHTML={{ __html: form.about }}></p>
                  </div>
                </div>
              </div>
            )}

            <div className="col-sm-12">
              <div className="card mt-0">
                <div className="card-header social-header">
                  <h5>{'Locations'}</h5>
                </div>
                <div className="card-body">
                  <div className="row details-about">
                    {locations.map((data, i) => {
                      return (
                        <div key={i} className="col-sm-6">
                          <div className="your-details">
                            <span className="f-w-600">
                              <a
                                href={
                                  '/location/' +
                                  data.location.EmployerLocationsID
                                }
                              >
                                {data.location.LocationName}
                              </a>
                            </span>
                            <p className="slant">
                              {data.location.City}, {data.location.State}
                            </p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 xl-100">
          <div
            className="default-according style-1 faq-accordion job-accordion"
            id="accordionoc3"
          >
            <div className="row">
              <div className="col-sm-12">
                <div className="card mt-0">
                  <div className="card-header social-header">
                    <h5>
                      <span>{'Available Job Openings'}</span>
                      <span className="pull-right"></span>
                    </h5>
                  </div>
                  <div className="card-body">
                    {openings.map((data, i) => {
                      return (
                        <div className="row details-about" key={i}>
                          <div className="col-sm-12">
                            <div className="your-details">
                              <span className="f-w-600">
                                <a href={'/job/' + data.job.EmployerOpeningsID}>
                                  {data.job.Title}
                                </a>
                              </span>
                              <p>{data.location.LocationName}</p>
                              <p>
                                {' '}
                                {data.job.About
                                  ? snippet(
                                      new DOMParser().parseFromString(
                                        data.job.About,
                                        'text/html'
                                      ).body.textContent || '',
                                      200
                                    )
                                  : ''}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              {portfolios && portfolios.length >= 1 && (
                <EmployerViewPhotosPanel portfolios={portfolios} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default EmployerViewInfo
