import React, { useState, Fragment, useEffect } from "react";
// import ChartistGraph from 'react-chartist';
import { Command, Navigation, Users } from "react-feather";
import round from "../assets/images/round.png";
import Breadcrumb from "../components/common/breadcrumb";
import CountUp from "react-countup";
import { GetUserID } from "../api/session";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { SCHOOL } from "../api/db";
import SchoolUsers from "../components/schools/school-users";
import { errorCheck } from "../components/common/functions";

var Knob = require("knob"); // browserify require
var primary = localStorage.getItem("primary_color") || "#4466f2";

const School = (props) => {
  var query = useParams();
  const userId = GetUserID();

  console.log(query.id);
  const { loading, error, data, refetch } = useQuery(SCHOOL, {
    variables: { locid: parseInt(query.id) },
    fetchPolicy: "no-cache",
  });
  const [value, onChange] = useState(new Date());
  //
  useEffect(() => {
    if (data && data.school.StudentStats.TotalActive) {
      console.log(Math.ceil(data.school.StudentStats.TotalActive * 10) / 10);
      var student = Knob({
        value: data.school.StudentStats.PercentRegisteredActive,
        angleOffset: 80,
        angleArc: 360,
        className: "review",
        lineCap: "round",
        thickness: 0.1,
        width: 180,
        height: 180,
        fgColor: "#fff",
        bgColor: primary,
      });
      document.getElementById("student").appendChild(student);
    }
  }, data);

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );

  if (error) {
    return errorCheck(error);
  }
  console.log(data);

  return (
    <Fragment>
      <Breadcrumb parent="Schools" title={"School: " + data.school.School} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-6 xl-100">
            <div className="card card-gradient">
              <div className="card-body text-center o-hidden">
                <div className="knob-header">
                  <h5>Active Students</h5>
                  <div className="d-inline-block pull-right f-16">
                    {data.school.StudentStats.TotalActive + " /"}{" "}
                    <span>{data.school.StudentStats.TotalStudents}</span>
                  </div>
                </div>
                <div className="knob-block text-center knob-center university-knob">
                  <div className="knob" id="student"></div>
                </div>
                <img className="round-image" src={round} alt="" />
              </div>
            </div>
          </div>
          <div className="col-xl-6 xl-100">
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex feather-main">
                      <div className="feather-icon-block flex-shrink-0">
                        <Command />
                      </div>
                      <div className="flex-grow-1 align-self-center">
                        <h6>Total Active Students</h6>
                        <p>{data.school.StudentStats.TotalActive}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex feather-main">
                      <div className="feather-icon-block flex-shrink-0">
                        <Navigation />
                      </div>
                      <div className="flex-grow-1 align-self-center">
                        <h6>Total Alumni</h6>
                        <p>{data.school.StudentStats.TotalAlumni}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex chart-university">
                      <div className="flex-grow-1">
                        <h3 className="mb-0">
                          <Users />
                          <span>
                            <CountUp
                              className="counter"
                              end={
                                data.school.StudentStats.TotalActiveRegistered
                              }
                            />
                          </span>
                        </h3>
                        <p>Total Registered</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex chart-university">
                      <div className="flex-grow-1">
                        <h3 className="mb-0">
                          <Users />
                          <span className="counter">
                            <CountUp
                              end={
                                data.school.StudentStats.TotalAlumniRegistered
                              }
                            />
                          </span>
                        </h3>
                        <p>Registered Alumni</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 xl-100">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex chart-university">
                      <div className="flex-grow-1">
                        <h3 className="mb-0">
                          <Users />
                        </h3>
                        <p>Admin Users</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-12 xl-50">
            <SchoolUsers locationId={data.school.LocID} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default School;
