import React from 'react'
import { Routes, Route } from 'react-router-dom'
import AppLayout from '../../components/app'
import Home from '../../routes/home'

import Profile from '../../components/profiles/profile'
import History from '../../routes/history'
import Resume from '../../routes/resume'
import Messages from '../../routes/messages'
import Activity from '../../routes/activity'
import Help from '../../routes/help'
import EditProfile from '../../components/profiles/profile-edit/profile-edit'
import Auth from '../../components/auth/auth'
import ForgotPassword from '../../components/auth/auth-forgotpassword'
import ResetPassword from '../../components/auth/auth-resetpassword'
import Jobs from '../jobs'
import Job from '../job'
import LocationView from '../../components/locations/view/location-view'
import EmployerView from '../../components/employers/employer-view'
import PrivacyPolicy from '../privacy'
import TermsOfService from '../terms'

const AppUserRouter = () => {
  return (
    <>
      <Routes>
        <Route element={<AppLayout />} key={1}>
          <Route exact path="/" element={<Profile />} />
        </Route>
        <Route element={<AppLayout />} key={2}>
          <Route exact path="/activity" element={<Activity />} />
        </Route>
        <Route element={<AppLayout />} key={3}>
          <Route exact path="/messages/:conversation" element={<Messages />} />
        </Route>
        <Route element={<AppLayout />} key={3}>
          <Route exact path="/messages" element={<Messages />} />
        </Route>
        <Route element={<AppLayout />} key={4}>
          <Route exact path="/history" element={<History />} />
        </Route>
        <Route element={<AppLayout />} key={6}>
          <Route exact path="/help" element={<Help />} />
        </Route>
        <Route element={<AppLayout />} key={7}>
          <Route exact path="/settings/profile" element={<EditProfile />} />
        </Route>
        <Route element={<AppLayout />} key={9}>
          <Route exact path="/students/:id" element={<Profile />} />
        </Route>
        <Route element={<AppLayout />} key={10}>
          <Route exact path="/profile" element={<Profile />} />
        </Route>
        <Route element={<AppLayout />} key={11}>
          <Route exact path="/login" element={<Auth />} />
        </Route>
        <Route element={<AppLayout />} key={12}>
          <Route exact path="/student-resume/:id" element={<Resume />} />
        </Route>
        <Route element={<AppLayout />} key={13}>
          <Route exact path="/student-resume/" element={<Resume />} />
        </Route>
        <Route element={<AppLayout />} key={14}>
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
        </Route>
        <Route element={<AppLayout />} key={15}>
          <Route exact path="/reset-password" element={<ResetPassword />} />
        </Route>
        <Route element={<AppLayout />} key={16}>
          <Route exact path="/jobs" element={<Jobs />} />
        </Route>
        <Route element={<AppLayout />} key={16}>
          <Route exact path="/job/:id" element={<Job />} />
        </Route>
        <Route element={<AppLayout />} key={16}>
          <Route exact path="/location/:id" element={<LocationView />} />
        </Route>
        <Route element={<AppLayout />} key={16}>
          <Route exact path="/employer/:id" element={<EmployerView />} />
        </Route>
        <Route element={<AppLayout />} key={9}>
          <Route exact path="/terms" element={<TermsOfService />} />
        </Route>
        <Route element={<AppLayout />} key={9}>
          <Route exact path="/privacy" element={<PrivacyPolicy />} />
        </Route>
      </Routes>
    </>
  )
}

export default AppUserRouter
