import { Html } from "react-pdf-html";
import ResumePrintStyle1 from "./resume-print-style1";
import ResumePrintStyle2 from "./resume-print-style2";
import ResumePrintStyle3 from "./resume-print-style3";

const ResumePrintViewer = ({ resume }) => {
  console.log(resume);

  if (resume.style == "1") return <ResumePrintStyle1 resume={resume} />;

  if (resume.style == "2") return <ResumePrintStyle2 resume={resume} />;

  if (resume.style == "3") return <ResumePrintStyle3 resume={resume} />;
};

export default ResumePrintViewer;
