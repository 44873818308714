import React, { Fragment, useState } from "react";
import { Search, MapPin } from "react-feather";
import { Collapse } from "reactstrap";

const JobFilter = ({
  locations,
  employers,
  cities,
  keywords,
  employerfilter,
  locationfilter,
  locationsfilter,
  cityfilter,
  keywordfilter,
  textfilter,
}) => {
  const [isFilter, setIsFilter] = useState(true);
  const [location, setLocation] = useState(false);
  const [isJobTitle, setisJobTitle] = useState(false);
  const [isIndustry, setisIndustry] = useState(false);
  const [isLocationValid, setisSkill] = useState(true);
  let locationtext = locationfilter.value;
  const onChangeEmployer = (e) => {
    let arr = employerfilter.value;
    if (arr.includes(e.target.value)) {
      employerfilter.setValue(arr.filter((item) => item !== e.target.value));
    } else {
      employerfilter.setValue([...arr, e.target.value]);
    }

    console.log(employerfilter.value);
  };

  const onChangeLocations = (e) => {
    console.log(e.target.value);
    let arr = locationsfilter.value;
    if (arr.includes(e.target.value)) {
      locationsfilter.setValue(arr.filter((item) => item !== e.target.value));
    } else {
      locationsfilter.setValue([...arr, e.target.value]);
    }

    console.log(locationsfilter.value);
  };

  const onChangeCities = (e) => {
    let arr = cityfilter.value;
    if (arr.includes(e.target.value)) {
      cityfilter.setValue(arr.filter((item) => item !== e.target.value));
    } else {
      cityfilter.setValue([...arr, e.target.value]);
    }

    console.log(cityfilter.value);
  };

  const onChangeKeywords = (e) => {
    let arr = keywordfilter.value;
    if (arr.includes(e.target.value)) {
      keywordfilter.setValue(arr.filter((item) => item !== e.target.value));
    } else {
      keywordfilter.setValue([...arr, e.target.value]);
    }

    console.log(keywordfilter.value);
  };

  const clearKeywords = () => {
    keywordfilter.setValue([]);
  };

  const clearCities = () => {
    cityfilter.setValue([]);
  };

  const clearLocations = () => {
    locationsfilter.setValue([]);
  };

  const clearEmployer = () => {
    employerfilter.setValue([]);
  };

  const onChangeLocation = (e) => {
    let arr = e.target.value;

    locationfilter.setValue(arr);
  };
  return (
    <Fragment>
      <div className="col-xl-3 xl-40">
        <div
          className="default-according style-1 faq-accordion job-accordion"
          id="accordionoc"
        >
          <div className="row">
            <div className="col-xl-12">
              <div className="card mt-0">
                <div className="card-header">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link ps-0 border-0"
                      data-toggle="collapse"
                      onClick={() => setIsFilter(!isFilter)}
                      data-target="#collapseicon"
                      aria-expanded={isFilter}
                      aria-controls="collapseicon"
                    >
                      Filters
                    </button>
                  </h5>
                </div>
                <Collapse isOpen={isFilter}>
                  <div className="card-body filter-cards-view animate-chk">
                    <div className="job-filter">
                      <div className="faq-form">
                        <input
                          className="form-control"
                          type="text"
                          onChange={(e) => textfilter.onChange(e)}
                          placeholder="Search.."
                        />
                        <Search className="search-icon" />
                      </div>
                    </div>
                    <div className="job-filter">
                      <div className="faq-form">
                        <input
                          className="form-control"
                          type="text"
                          onChange={(e) => onChangeLocation(e)}
                          value={locationtext}
                          placeholder="location.."
                        />
                        <MapPin className="search-icon" />
                      </div>
                    </div>
                    <div className="checkbox-animated">
                      {keywords.map((keyword, i) => {
                        return (
                          <label
                            key={"chk-ani" + i}
                            className="d-block"
                            htmlFor={"chk-ani" + i}
                          >
                            <input
                              onChange={(e) => onChangeKeywords(e)}
                              className="checkbox_animated"
                              value={keyword}
                              checked={keywordfilter.value.includes(keyword)}
                              id={"chk-ani" + i}
                              type="checkbox"
                            />
                            {keyword}
                          </label>
                        );
                      })}
                    </div>
                    <button
                      onClick={(e) => clearKeywords()}
                      className="btn btn-primary text-center"
                      type="button"
                    >
                      Find Jobs
                    </button>
                  </div>
                </Collapse>
              </div>
            </div>

            <div className="col-xl-12">
              {cities && cities.length > 0 && (
                <div className="card mt-0">
                  <div className="card-header">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link ps-0 border-0"
                        data-toggle="collapse"
                        onClick={() => setLocation(!location)}
                        data-target="#collapseicon1"
                        aria-expanded={location}
                        aria-controls="collapseicon1"
                      >
                        Cities
                      </button>
                    </h5>
                  </div>
                  <Collapse isOpen={location}>
                    <div className="card-body animate-chk">
                      <div className="location-checkbox">
                        {cities.map((city, i) => {
                          return (
                            <label
                              key={"chk-ani6" + i}
                              className="d-block"
                              htmlFor={"chk-ani6" + i}
                            >
                              <input
                                onChange={(e) => onChangeCities(e)}
                                className="checkbox_animated"
                                checked={cityfilter.value.includes(city)}
                                value={city}
                                id={"chk-ani6" + i}
                                type="checkbox"
                              />
                              {city}
                            </label>
                          );
                        })}
                      </div>
                    </div>
                    <button
                      onClick={(e) => clearCities()}
                      className="btn btn-block btn-primary text-center"
                      type="button"
                    >
                      All Cities
                    </button>
                  </Collapse>
                </div>
              )}
            </div>
            <div className="col-xl-12">
              {employers && employers.length > 0 && (
                <div className="card mt-0">
                  <div className="card-header">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link ps-0 border-0"
                        onClick={() => setisJobTitle(!isJobTitle)}
                        data-toggle="collapse"
                        data-target="#collapseicon2"
                        aria-expanded={isJobTitle}
                        aria-controls="collapseicon2"
                      >
                        Employers
                      </button>
                    </h5>
                  </div>
                  <Collapse isOpen={isJobTitle}>
                    <div className="card-body animate-chk">
                      {employers.map((employer, i) => {
                        return (
                          <label
                            key={"chk-ani11" + i}
                            className="d-block"
                            htmlFor={"chk-ani11" + i}
                          >
                            <input
                              onChange={(e) => onChangeEmployer(e)}
                              className="checkbox_animated"
                              checked={employerfilter.value.includes(employer)}
                              value={employer}
                              id={"chk-ani11" + i}
                              type="checkbox"
                            />
                            {employer}
                          </label>
                        );
                      })}
                    </div>
                    <button
                      onClick={(e) => clearEmployer()}
                      className="btn btn-block btn-primary text-center"
                      type="button"
                    >
                      All Employers
                    </button>
                  </Collapse>
                </div>
              )}
            </div>
            <div className="col-xl-12">
              {locations && locations.length > 0 && (
                <div className="card mt-0">
                  <div className="card-header">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link ps-0 border-0 "
                        onClick={() => setisIndustry(!isIndustry)}
                        data-toggle="collapse"
                        data-target="#collapseicon3"
                        aria-expanded={isIndustry}
                        aria-controls="collapseicon3"
                      >
                        Locations
                      </button>
                    </h5>
                  </div>
                  <Collapse isOpen={isIndustry}>
                    <div
                      className="collapse show"
                      id="collapseicon3"
                      data-parent="#accordion"
                      aria-labelledby="collapseicon3"
                    >
                      <div className="card-body animate-chk">
                        {locations.map((location, i) => {
                          return (
                            <label
                              key={"chk-ani23" + i}
                              className="d-block"
                              htmlFor={"chk-ani23" + i}
                            >
                              <input
                                className="checkbox_animated"
                                onChange={(e) => onChangeLocations(e)}
                                checked={locationsfilter.value.includes(
                                  location
                                )}
                                value={location}
                                id={"chk-ani23" + i}
                                type="checkbox"
                              />
                              {location}
                            </label>
                          );
                        })}
                      </div>
                      <button
                        onClick={(e) => clearLocations()}
                        className="btn btn-block btn-primary text-center"
                        type="button"
                      >
                        All Location
                      </button>
                    </div>
                  </Collapse>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default JobFilter;
