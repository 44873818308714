import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { DELETE_RESUME } from "../../../api/db";
import { Button } from "reactstrap";

const ResumeManager = ({ resumees, refreshCallback }) => {
  const [deleteResume, deleting, deleteError] = useMutation(DELETE_RESUME, {});

  let navigate = useNavigate();

  const routeChange = (resumeId) => {
    let path = `${process.env.PUBLIC_URL}/student-resume`;
    let fullpath = resumeId ? path + "/" + resumeId : path;

    console.log(fullpath);
    navigate(fullpath);
  };

  const handleDeleteResumee = async (resumeeid) => {
    console.log(resumeeid);

    try {
      if (resumeeid) {
        await deleteResume({ variables: { resumeeId: parseInt(resumeeid) } });

        refreshCallback();
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (deleteError) return <p>{deleteError.message}</p>;

  return (
    <div className="col-lg-4">
      <div className="card mt-0">
        <div className="card-header d-flex justify-content-between">
          <h4 className="card-title me-4 align-self-end">Resumes</h4>
          <div className="card-options">
            <a
              className="card-options-collapse"
              href="javascript"
              data-toggle="card-collapse"
            >
              <i className="fe fe-chevron-up"></i>
            </a>
            <a
              className="card-options-remove"
              href="javascript"
              data-toggle="card-remove"
            >
              <i className="fe fe-x"></i>
            </a>
          </div>
          <Button
            className="btn btn-secondary btn-sm"
            color="success"
            onClick={() => routeChange()}
          >
            <i className="fa fa-plus"></i> {"Create Resume"}
          </Button>
        </div>
        <div className="table-responsive col-12 p-3">
          <table className="table card-table table-vcenter text-nowrap">
            <tbody>
              {resumees.map((resumee, i) => (
                <tr key={i}>
                  <td className="w-100">
                    <table className="w-100">
                      <tr>
                        <td className="resume-title p-0 pb-2">
                          {resumee.Title}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start p-0">
                          <button
                            className="btn btn-info btn-sm me-3 "
                            onClick={() => {
                              routeChange(resumee.ResumeeID);
                            }}
                          >
                            <i className="fa fa-pencil"></i> {"Edit"}
                          </button>
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => {
                              handleDeleteResumee(resumee.ResumeeID);
                            }}
                          >
                            <i className="fa fa-trash"></i> {"Delete"}
                          </button>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ResumeManager;
