import React, { useRef, useState } from "react";
import useInput from "../../../hooks/useInput.js";
import { useMutation } from "@apollo/client";
import { GetUserID } from "../../../api/session.js";
import { UPDATE_STUDENT_EMAIL, UPDATE_USER_EMAIL } from "../../../api/db.js";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SimpleReactValidator from "simple-react-validator";
import { css } from "styled-components";

const ProfileEditEmail = ({
  form,
  isStudent,
  onSave,
  hasApproved,
  showModal,
  newEmail,
}) => {
  var validator = useRef(new SimpleReactValidator({ autoForceUpdate: this }));
  const [, forceUpdate] = useState();
  const [password, setPassword] = useState();
  const toggleHasApproved = (e) => {
    if (!validator.current.allValid() || !newEmail.value) {
      validator.current.showMessages();
      forceUpdate(1);
    }

    hasApproved.setValue(!hasApproved.value);
  };

  const toggleChangeEmailModal = () => {
    var modal = showModal.value;

    showModal.setValue(!modal);

    console.log(showModal.value);
  };

  const handleNewEmail = (e) => {
    newEmail.onChange(e);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const [editProfileEmail, { loading, error }] = useMutation(
    UPDATE_USER_EMAIL,
    {}
  );

  const [editStudentEmail, { loading: studentloading, error: studenterror }] =
    useMutation(UPDATE_STUDENT_EMAIL, {});

  const handleChangeEmail = async (e) => {
    e.preventDefault();

    if (!validator.current.allValid() || !newEmail.value) {
      validator.current.showMessages();
      forceUpdate(1);
      return toast.error("Email or Password not valid");
    }

    try {
      await editProfileEmail({
        variables: {
          userId: Number(form.userid),
          email: newEmail.value,
          password: password,
        },
      });

      if (!error) {
        if (isStudent) {
          await editStudentEmail({
            variables: {
              userId: Number(form.userid),
              email: newEmail.value,
            },
          });
        }

        toast("Email has been updated");
        toggleChangeEmailModal();
        onSave();
      } else {
        if (error.message) toast.error(error.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div className="form-group mb-2 mt-3">
        <label className="form-label mb-0">Email Address</label>
        <input
          className="form-control"
          placeholder="your-email@domain.com"
          name="email"
          value={form.email}
          disabled={true}
        />
      </div>
      <Button
        className="btn btn-sm mt-0 mb-4"
        onClick={(e) => toggleChangeEmailModal(e)}
      >
        Change Email
      </Button>
      <Modal isOpen={showModal.value}>
        <ModalHeader>Change Email</ModalHeader>
        <ModalBody>
          <form>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                New Email:
              </label>
              <input
                className="form-control"
                type="text"
                onChange={(e) => handleNewEmail(e)}
              />
              {validator.current.message(
                "email",
                newEmail.value,
                "required|email"
              )}
            </div>
            <div className="form-group">
              <label className="col-form-label" htmlFor="message-text">
                Enter Current Password:
              </label>
              <input
                className="form-control"
                type="password"
                onChange={(e) => handlePassword(e)}
              />
              {validator.current.message("password", password, "required")}
            </div>
            <div className="form-group m-b-15 ms-1">
              <input
                id="inline-sqr-1"
                type="checkbox"
                name="appoveEmailUpdate"
                onChange={(e) => toggleHasApproved(e)}
                className="checkbox checkbox-dark m-squar mt-3 me-2"
              />
              <label className="form-label" htmlFor="inline-sqr-1">
                By updating profile email address, you understand that your
                authentication information will be updated.
              </label>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={!hasApproved.value}
            onClick={(e) => handleChangeEmail(e)}
          >
            Update Email
          </Button>
          <Button color="secondary" onClick={(e) => toggleChangeEmailModal(e)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default ProfileEditEmail;
