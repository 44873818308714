import React, { Fragment, useState } from "react";
import Breadcrumb from "../common/breadcrumb.js";
import { useQuery, useMutation } from "@apollo/client";
import { CONVERSATIONS, SEND_MESSAGE, STUDENT_SEARCH } from "../../api/db.js";
import useInput from "../../hooks/useInput.js";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { toast } from "react-toastify";
import { Alert } from "reactstrap";
import StudentFilter from "./filters/student-filter";

const StudentSearch = ({ query }) => {
  const textfilter = useInput("");
  const locationfilter = useInput(query ?? "");
  const employerfilter = useInput([]);
  const citiesfilter = useInput([]);
  const keywordsfilter = useInput([]);
  const locationsfilter = useInput([]);
  const certificationfilter = useInput(false);
  const hoursfilter = useInput([]);
  const message = useInput("");
  const messageUserId = useInput("");
  const messageUser = useInput("");
  const [messagemodal, setMessageModal] = useState();

  const toggleMessageModal = (e, userId, name) => {
    setMessageModal(!messagemodal);
    console.log(userId);
    messageUserId.setValue(userId);
    messageUser.setValue(name);
  };

  console.log(textfilter.value);
  const { loading, error, data, refetch } = useQuery(STUDENT_SEARCH, {
    variables: {
      name: textfilter.value,
      query: locationfilter.value,
      limit: 1000,
    },
  });
  const [newMessageMutation, { saveloading, saveerror, savedata }] =
    useMutation(SEND_MESSAGE, {
      refetchQueries: [{ query: CONVERSATIONS, variables: {} }],
    });

  const handleNewMessage = async (e) => {
    const userIds = [];
    userIds.push(parseInt(messageUserId.value));

    try {
      await newMessageMutation({
        variables: { toUserIDs: userIds, message: message.value },
      });
      message.setValue("");
    } catch (err) {
      console.log(err);
    }
    setMessageModal(!messagemodal);

    toast("Message Sent", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  if (saveloading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );

  if (saveerror) return <p>Error : {saveerror.message}</p>;

  let openings = [];
  let locations = [];
  let employers = [];
  let cities = [];
  let keywords = [];
  let hours = [];
  if (data) {
    data.students.map((student) => {
      let cityvalue = student.City.trim() + ", " + student.State.trim();

      if (!cities.some((x) => x === cityvalue.toUpperCase().trim()))
        cities.push(cityvalue.toUpperCase().trim());

      if (student.HoursCompleted) {
        let hourvalue = student.HoursCompleted;

        if (!hours.some((x) => x === hourvalue)) hours.push(hourvalue);
      }

      if (openings) {
        openings.push(student);
      }
    });
  }

  function search(openings) {
    return openings
      .filter((student) => {
        if (citiesfilter.value.length >= 1) {
          return citiesfilter.value.includes(
            (student.City.trim() + ", " + student.State.trim()).toUpperCase()
          );
        }

        return true;
      })
      .filter((student) => {
        if (certificationfilter.value == true) {
          return student.Certification == true;
        }

        return true;
      })
      .filter((student) => {
        if (hoursfilter.value.length >= 1) {
          return hoursfilter.value.includes(student.HoursCompleted);
        }

        return true;
      });
  }
  console.log(openings);
  return (
    <Fragment>
      <Breadcrumb title="Students" />
      <div className="container-fluid">
        <div className="row">
          <StudentFilter
            locations={locations}
            employers={employers}
            cities={cities}
            keywords={keywords}
            hours={hours}
            hoursfilter={hoursfilter}
            certificationfilter={certificationfilter}
            employerfilter={employerfilter}
            textfilter={textfilter}
            locationfilter={locationfilter}
            locationsfilter={locationsfilter}
            keywordfilter={keywordsfilter}
            cityfilter={citiesfilter}
          />
          <div className="col-xl-9 xl-60">
            {loading && (
              <Alert color="secondary dark">
                <p>{"Search....."}</p>
              </Alert>
            )}
            {!loading &&
              (locationfilter.value.length != 0 ||
                textfilter.value.length != 0) &&
              search(openings).length == 0 && (
                <Alert color="secondary dark">
                  <p>{"No Search Results found"}</p>
                </Alert>
              )}

            {!loading &&
              locationfilter.value.length == 0 &&
              textfilter.value.length == 0 &&
              search(openings).length == 0 && (
                <Alert color="secondary dark">
                  <p>{"Enter a location to search for students in the area"}</p>
                </Alert>
              )}
            {search(openings).map((data, i) => {
              return (
                <div
                  key={"jsrc" + i}
                  className={`card ${data.badgeValue ? "" : ""} mt-0`}
                >
                  <div className="job-search">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        {data.ProfileImage && (
                          <img
                            className="img-70 img-fluid m-r-20 rounded"
                            src={data.ProfileImage}
                            alt=""
                          />
                        )}
                        <div className="flex-grow-1">
                          <h6 className="f-w-600">
                            <a
                              href={`${process.env.PUBLIC_URL}/students/${data.StudentID}`}
                            >
                              {data.FirstName} {data.LastName}
                            </a>
                          </h6>
                          <p>
                            <b>Hours Completed:</b>{" "}
                            {data.HoursCompleted ?? "N/A"}
                            <br />
                            <b>Has Certification:</b>{" "}
                            {(data.Certification ?? "N/A") == "true"
                              ? "YES"
                              : (data.Certification ?? "N/A") == "N/A"
                              ? "N/A"
                              : "NO"}
                          </p>
                          <p>
                            {data.City}, {data.State}
                          </p>
                        </div>
                      </div>
                      <div className="buttons text-center text-md-start">
                        <Button
                          className="me-3 btn-sm"
                          color="primary"
                          onClick={(e) =>
                            toggleMessageModal(
                              e,
                              data.UserID,
                              data.FirstName + " " + data.LastName
                            )
                          }
                        >
                          Send Message
                        </Button>
                        <Button
                          className="btn-sm"
                          color="primary"
                          onClick={(e) => {
                            window.location = `${process.env.PUBLIC_URL}/students/${data.StudentID}`;
                          }}
                        >
                          View Profile
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Modal isOpen={messagemodal} toggle={toggleMessageModal}>
        <ModalHeader toggle={toggleMessageModal}>
          Send {messageUser.value} A Message
        </ModalHeader>
        <ModalBody>
          <form>
            <textarea
              value={message.value}
              onChange={message.onChange}
              className="form-control"
              rows="5"
              col="5"
            ></textarea>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleNewMessage("send")}>
            Send
          </Button>
          <Button color="secondary" onClick={(e) => toggleMessageModal(e)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default StudentSearch;
