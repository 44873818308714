import React, { Fragment, useState } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_EMPLOYER_LOCATION } from "../../../api/db.js";
import Breadcrumb from "../../common/breadcrumb.js";
import LocationViewInfo from "./location-view-info.js";
import LocationViewJobs from "./location-view-jobs.js";
import LocationViewPhotos from "./location-view-photos.js";
import { TabContent, TabPane, Nav } from "reactstrap";

const LocationView = () => {
  const [activeTab, setActiveTab] = useState("1");
  var { id } = useParams();
  var socialbg = "/images/social-back-default.jpg";
  var form = {
    employerId: "",
    locationName: "",
    about: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    lat: "",
    lon: "",
    active: "",
    distance: "",
  };

  const { loading, error, data, refetch } = useQuery(GET_EMPLOYER_LOCATION, {
    variables: { employerLocationsId: parseInt(id) },
  });

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );
  if (error) return <p>Error : {error.message}</p>;

  if (data) {
    console.log(data);

    var location = data.employerLocation;
    form = {
      employerId: location.EmployerID,
      locationId: location.EmployerLocationsID,
      locationName: location.LocationName ?? "",
      about: location.About ?? "",
      address1: location.Address1 ?? "",
      address2: location.Address2 ?? "",
      city: location.City ?? "",
      state: location.State,
      zip: location.Zipcode ?? "",
      phone: location.Phone ?? "",
      lat: location.Lat ?? "",
      lon: location.Lon ?? "",
      active: location.Active ?? "",
      distance: location.Distance ?? "",
      profile: location.ProfileImage,
      banner: location.BannerImage,
      videourl: location.VideoURL ?? "",
    };

    var profile = location.ProfileImage;
    var banner = location.BannerImage;

    if (profile && profile != undefined) {
      form.profileurl = profile;
    } else {
      form.profileurl = "/images/profile-student.jpg";
    }

    if (banner && banner != undefined) {
      form.bannerurl = banner;
    } else {
      form.bannerurl = "/images/social-back-default.jpg";
    }

    if (location.EmployerImages && location.EmployerImages.length >= 1) {
      socialbg = location.EmployerImages[0].Src;
    }
  }

  return (
    <Fragment>
      <div className="container-fluid px-0">
        <div className="page-header py-0">
          <div className="row">
            <div className="col">
              <div className="page-header-left">
                <Breadcrumb title={form.locationName} parent="Locations" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mx-0">
        <div className="user-profile social-app-profile">
          <div className="row">
            {/* <!-- user profile first-style start--> */}
            <div className="col-sm-12">
              <div className="card hovercard text-center mt-0">
                <div
                  className="cardheader socialheader"
                  style={{
                    background: "url(" + form.bannerurl + ")",
                    backgroundPosition: "50% 12%",
                  }}
                ></div>
                <div className="user-image">
                  <div className="avatar">
                    {form.profile && <img alt="user" src={form.profile} />}
                  </div>
                </div>
                <div className="info market-tabs p-0">
                  <Nav tabs className="tabs-social nav-primary market-tabs">
                    {/* <NavItem className="nav nav-tabs " id="myTab" role="tablist">
                                        <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                           About
                                        </NavLink>
                                    </NavItem>                                  
                                    */}
                    <li className="nav-item my-3">
                      <div className="title">
                        <a href="#javascript">{form.locationName}</a>
                      </div>
                      <div className="desc mt-2">
                        {form.city}, {form.state}
                      </div>
                    </li>
                    {/* <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                                        <NavLink className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                                           Photos
                                        </NavLink>
                                    </NavItem> */}
                  </Nav>
                </div>
              </div>
            </div>
          </div>
          <TabContent activeTab={activeTab} className="tab-content">
            <TabPane tabId="1">
              <LocationViewInfo
                form={form}
                openings={location.Openings}
                portfolios={location.EmployerImages}
              />
            </TabPane>
            <TabPane tabId="2">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <LocationViewJobs
                        locationID={form.locationId}
                        jobs={location.Openings}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="3">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <LocationViewPhotos
                        portfolios={location.EmployerImages}
                        locationid={location.EmployerLocationsID}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </Fragment>
  );
};

export default LocationView;
