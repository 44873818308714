import React, { Fragment, useRef, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  APPLY_FOR_OPENING,
  GET_EMPLOYER,
  GET_EMPLOYER_LOCATION,
  SEND_MESSAGE,
  GET_STUDENT_DETAILS,
  CONVERSATIONS,
} from "../../../../api/db.js";
import Breadcrumb from "../../../common/breadcrumb.js";
import useInput from "../../../../hooks/useInput.js";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { toast } from "react-toastify";
import Slider from "react-slick";
import { GetUserID } from "../../../../api/session.js";
import "react-toastify/dist/ReactToastify.css";
import { Check } from "react-feather";
import { snippet } from "../../../common/functions.js";
import SimpleReactValidator from "simple-react-validator";

const JobView = ({ job, resumeelist, keywordlist }) => {
  const [modalJobApplication, setModalJobApplication] = useState(false);
  var validator = useRef(new SimpleReactValidator({ autoForceUpdate: this }));
  const [, forceUpdate] = useState();
  const message = useInput("");
  const messageUserId = useInput("");
  const messageUser = useInput("");
  const [messagemodal, setMessageModal] = useState();
  const resumees = useInput(resumeelist ?? []);
  let keywordList;
  let keywords = [];
  console.log(job);
  console.log(resumeelist);
  console.log(keywordlist);
  const resumeeSelected = useInput();
  var basic_settings = {
    dots: true,
    infinite: true,
    speed: 100,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    adaptiveHeight: true,
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const toggleJobApplicationModal = () => {
    setModalJobApplication(!modalJobApplication);
  };

  const toggleMessageModal = (e, userId, name) => {
    setMessageModal(!messagemodal);
    console.log("send message:");
    console.log(userId);
    messageUserId.setValue(userId);
    messageUser.setValue(name);
    message.setValue(`Message from: 
        ${studentdata.student.FirstName} ${studentdata.student.LastName}
        ${studentdata.student.City} ${studentdata.student.State} ${studentdata.student.Zipcode}
        `);
  };

  console.log(parseInt(GetUserID()));
  const [applyforjobmutation] = useMutation(APPLY_FOR_OPENING, {});
  const {
    loading: studentloading,
    error: studenterror,
    data: studentdata,
  } = useQuery(GET_STUDENT_DETAILS, {
    variables: { userId: parseInt(GetUserID()) },
  });

  const {
    loading,
    error,
    data: employerdata,
  } = useQuery(GET_EMPLOYER_LOCATION, {
    variables: { employerLocationsId: parseInt(job.EmployerLocationsID) },
  });

  const {
    loading: employerload,
    error: employerError,
    data: employeedata,
  } = useQuery(GET_EMPLOYER, {
    variables: { employerId: parseInt(job.EmployerID) },
  });

  const [newMessageMutation, { saveloading, saveerror, savedata }] =
    useMutation(SEND_MESSAGE, {
      refetchQueries: [{ query: CONVERSATIONS, variables: {} }],
    });

  console.log(employerdata);
  console.log(employeedata);
  console.log(studentdata);
  if (loading || employerload || studentloading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );
  if (error || employerError || studenterror)
    return <p>Error : {error.message}</p>;

  const handleNewMessage = async (e) => {
    if (!validator.current.allValid() || !message.value) {
      validator.current.showMessages();
      forceUpdate(1);
      return toast.error("Cannot apply for job. Please select a resume.");
    }

    const userIds = [];
    userIds.push(parseInt(messageUserId.value));
    console.log(messageUserId);
    try {
      await newMessageMutation({
        variables: { toUserIDs: userIds, message: message.value },
      });
      message.setValue("");
    } catch (err) {
      console.log(err);
    }
    setMessageModal(!messagemodal);

    toast("Message Sent", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const applyForJob = async (e) => {
    e.preventDefault();

    if (!validator.current.allValid() || !resumeeSelected.value) {
      validator.current.showMessages();
      forceUpdate(1);
      return toast.error("Cannot apply for job. Please select a resume.");
    }

    try {
      await applyforjobmutation({
        variables: {
          employerId: Number(job.EmployerID),
          employerlocationsId: Number(job.EmployerLocationsID),
          resumeeId: parseInt(resumeeSelected.value),
          employeropeningsId: parseInt(job.EmployerOpeningsID),
        },
      });

      toggleJobApplicationModal();

      toast("Resume Submitted", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (err) {
      console.log(err);
    }
  };

  console.log(job);
  console.log(keywordList);

  job.Keywords.map((keyword) => {
    if (!keywords.some((x) => x === keyword.Keyword.toUpperCase().trim()))
      keywords.push(keyword.Keyword.toUpperCase().trim());
  });
  console.log(keywords);
  return (
    <Fragment>
      <Breadcrumb parent="Jobs" title={job.Title} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card mt-0">
              <div className="job-search">
                <div className="card-body">
                  <div className="col-12">
                    <div className="row g-0">
                      <div className="col-12 col-md-6 text-start">
                        <h6 className="f-w-600">
                          <a href={"/location/" + job.EmployerLocationsID}>
                            {employerdata.employerLocation.LocationName}
                          </a>
                        </h6>
                        <p className="my-0">
                          {employerdata.employerLocation.City} -{" "}
                          {employerdata.employerLocation.State}
                        </p>
                        <p className="mb-2 mt-0">
                          Employer:{" "}
                          <a
                            href={
                              "/employer/" + employeedata.employer.EmployerID
                            }
                          >
                            {employeedata.employer.EmployerName}
                          </a>
                        </p>
                      </div>
                      <div className="col-12 col-md-6 text-start text-md-end">
                        <div className="mb-2">
                          {job.EmployerLocationUsers.length >= 1 && (
                            <button
                              className="btn btn-secondary"
                              type="button"
                              onClick={(e) =>
                                toggleMessageModal(
                                  e,
                                  job.EmployerLocationUsers[0].UserID,
                                  employeedata.employer.EmployerName
                                )
                              }
                            >
                              {"Message Employer"}
                            </button>
                          )}
                        </div>
                        <div className="ms-0 ms-md-2 mt-2 mt-md-0">
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => toggleJobApplicationModal()}
                          >
                            {"Apply for this job"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="job-description mt-3">
                      {keywords && keywords.length >= 1 && (
                        <p>
                          <b>Benefits</b>
                          <ListGroup>
                            {keywords.map((k, i) => {
                              return (
                                <ListGroupItem key={i}>
                                  <Check></Check>
                                  {k}
                                </ListGroupItem>
                              );
                            })}
                          </ListGroup>
                        </p>
                      )}

                      <p dangerouslySetInnerHTML={{ __html: job.About }}></p>
                    </div>
                  </div>
                  <div className="job-description">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => toggleJobApplicationModal()}
                    >
                      {"Apply for this job"}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {employerdata.employerLocation.EmployerImages &&
              employerdata.employerLocation.EmployerImages.length >= 1 && (
                <div className="card">
                  <div className="card-header">
                    <h5>
                      Photos from {employerdata.employerLocation.LocationName}
                    </h5>
                  </div>
                  <div className="card-body">
                    <Slider {...basic_settings}>
                      {employerdata.employerLocation.EmployerImages.map(
                        (photos, i) => {
                          return (
                            <img
                              className="p-2"
                              src={photos.Src}
                              alt={photos.Caption}
                            />
                          );
                        }
                      )}
                    </Slider>
                  </div>
                </div>
              )}
            {employerdata.employerLocation.Openings.filter(
              (data) => data.EmployerOpeningsID !== job.EmployerOpeningsID
            ).length >= 1 && (
              <>
                <div className="header-faq">
                  <h6 className="mb-0 f-w-600">
                    More Jobs from {employerdata.employerLocation.LocationName}
                  </h6>
                </div>
                <div className="row">
                  {employerdata.employerLocation.Openings.filter(
                    (data) => data.EmployerOpeningsID !== job.EmployerOpeningsID
                  )
                    .slice(0, 4)
                    .map((data, i) => {
                      return (
                        <div className="col-xl-6 xl-100" key={i}>
                          <div className="card mt-0">
                            <div className="job-search">
                              <div className="card-body">
                                <div className="d-flex align-items-center">
                                  {employerdata.employerLocation
                                    .ProfileImage && (
                                    <img
                                      className="img-40 img-fluid m-r-20"
                                      src={
                                        employerdata.employerLocation
                                          .ProfileImage
                                      }
                                      alt=""
                                    />
                                  )}
                                  <div className="flex-grow-1">
                                    <h6 className="f-w-600">
                                      <a
                                        href={"/job/" + data.EmployerOpeningsID}
                                      >
                                        {data.Title}
                                      </a>
                                    </h6>
                                    <p>
                                      {
                                        employerdata.employerLocation
                                          .LocationName
                                      }{" "}
                                      <br />
                                      {
                                        employerdata.employerLocation.City
                                      } - {employerdata.employerLocation.State}
                                      <span></span>
                                    </p>
                                  </div>
                                </div>
                                <p>
                                  {" "}
                                  {snippet(
                                    data.About
                                      ? new DOMParser().parseFromString(
                                          data.About,
                                          "text/html"
                                        ).body.textContent || ""
                                      : "",
                                    500
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={messagemodal} toggle={toggleMessageModal}>
        <ModalHeader toggle={toggleMessageModal}>
          Send {messageUser.value} A Message
        </ModalHeader>
        <ModalBody>
          <form>
            <textarea
              autoFocus={true}
              value={message.value}
              onChange={message.onChange}
              className="form-control"
              rows="5"
              col="5"
            ></textarea>
            {validator.current.message(
              "message",
              resumeeSelected.value,
              "required"
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleNewMessage("send")}>
            Send
          </Button>
          <Button color="secondary" onClick={(e) => toggleMessageModal(e)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalJobApplication} toggle={toggleJobApplicationModal}>
        <ModalHeader toggle={toggleJobApplicationModal}>Add Resume</ModalHeader>
        <ModalBody>
          <form name="frmJobApplication" id="frmJobApplication">
            <div>
              <div className="mt-3">
                <label>Resumees:</label>
                <div className="drop_line">
                  <span className="drop">
                    <select
                      name="ed_state"
                      id="ed_state"
                      className="form-control ui search selection dropdown"
                      value={resumeeSelected.value}
                      onChange={resumeeSelected.onChange}
                    >
                      <option value="0">Select A Resume</option>
                      {resumees &&
                        resumees.value.map((x, i) => {
                          console.log(x.Title);
                          return (
                            <option value={"" + x.ResumeeID + ""}>
                              {x.Title}
                            </option>
                          );
                        })}
                    </select>
                    {validator.current.message(
                      "resume",
                      resumeeSelected.value,
                      "required"
                    )}
                  </span>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={(e) => {
              applyForJob(e);
            }}
          >
            {"Submit Resumee"}{" "}
          </Button>
          <Button color="secondary" onClick={toggleJobApplicationModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default JobView;
