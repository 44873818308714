import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../components/common/breadcrumb";
import { useQuery, useMutation } from "@apollo/client";
import { GET_EMPLOYER_LOCATIONS, ADD_LOCATION } from "../api/db.js";
import { GetUserID } from "../api/session.js";
import LocationCard from "../components/locations/locationCard";
import useInput from "../hooks/useInput";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { errorCheck } from "../components/common/functions.js";

const Locations = () => {
  let navigate = useNavigate();

  const [modalExperience, setModalLocation] = useState(false);
  const [modalFirstExperience, setModalFirstLocation] = useState(false);
  const locationId = useInput();
  const location = useInput("");
  const about = useInput("");
  const address1 = useInput("");
  const address2 = useInput("");
  const city = useInput("");
  const zipcode = useInput("");
  const state = useInput("");
  const phone = useInput("");

  const [addLocation] = useMutation(ADD_LOCATION, {});

  const { loading, error, data, refetch } = useQuery(GET_EMPLOYER_LOCATIONS, {
    variables: { employerId: GetUserID() },
  });

  const toggleFirstLocationModal = () => {
    if (
      data &&
      data.employer != undefined &&
      data.employer.Locations != null &&
      data.employer.Locations.length == 0
    ) {
      setModalFirstLocation(!modalFirstExperience);
    }
  };

  useEffect(() => {
    console.log(data);
    toggleFirstLocationModal();
  }, [data]);

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );

  if (error) {
    return errorCheck(error);
  }

  const toggleLocationModal = () => {
    setModalLocation(!modalExperience);
  };

  console.log(data);

  const saveLocation = async () => {
    console.log("saving location....");

    if (location.value === undefined) {
    }
    try {
      console.log({
        variables: {
          employerId: GetUserID(),
          locationName: location.value,
          about: about.value,
          address1: address1.value,
          address2: address2.value,
          city: city.value,
          state: state.value,
          zipcode: zipcode.value,
          phone: phone.value,
        },
      });
      var result = await addLocation({
        variables: {
          employerId: GetUserID(),
          locationName: location.value,
          about: about.value,
          address1: address1.value,
          address2: address2.value,
          city: city.value,
          state: state.value,
          zipcode: zipcode.value,
          phone: phone.value,
        },
      });

      if (result) {
        console.log("saved location result");
        console.log(result);
        var id = result.data.addEmployerLocation.EmployerLocationsID;
        locationId.setValue(id);

        console.log(id);
        let path = `${process.env.PUBLIC_URL}/location`;
        let fullpath = id ? path + "/" + id : path;
        refetch();

        navigate(fullpath);
      }

      toggleLocationModal();

      toast("Your Location has been Saved", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="Locations" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 mb-3">
            <Button color="success" onClick={toggleLocationModal}>
              {"Add Location"}
            </Button>
          </div>
        </div>
        <div className="row">
          {data.employer.Locations.map((employerlocation) => {
            return (
              <LocationCard
                location={employerlocation}
                refreshCallback={refetch}
              />
            );
          })}
        </div>
      </div>
      <Modal isOpen={modalExperience} toggle={toggleLocationModal}>
        <ModalHeader toggle={toggleLocationModal}>Add Experience</ModalHeader>
        <ModalBody>
          <form name="frmWorkExperience" id="frmWorkExperience">
            <div>
              <div className="mt-3">
                <label>Location Name:</label>
                <input
                  className="field form-control"
                  name="location"
                  id="location"
                  value={location.value}
                  onChange={location.onChange}
                />
              </div>
              <div className="mt-3">
                <label>About:</label>
                <textarea
                  className="textarea form-control"
                  id="about"
                  name="about"
                  placeholder="Write a description about your location"
                  value={about.value}
                  onChange={about.onChange}
                ></textarea>
              </div>
              <div className="mt-12">
                <label>Address 1:</label>
                <input
                  className="field form-control"
                  name="address1"
                  id="address1"
                  value={address1.value}
                  onChange={address1.onChange}
                />
              </div>
              <div className="mt-12">
                <label>Address 2:</label>
                <input
                  className="field form-control"
                  name="address2"
                  id="address2"
                  value={address2.value}
                  onChange={address2.onChange}
                />
              </div>
              <div className="mt-3">
                <label>City:</label>
                <input
                  className="form-control"
                  name="city"
                  id="city"
                  value={city.value}
                  onChange={city.onChange}
                />
              </div>
              <div className="mt-3">
                <label>State:</label>
                <div className="drop_line">
                  <span className="drop">
                    <select
                      name="state"
                      id="state"
                      className="form-control ui search selection dropdown"
                      value={state.value}
                      onChange={state.onChange}
                    >
                      <option value="0">Select A State</option>
                      <option value="0">Select A State</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA"> California</option>
                      <option value="CO"> Colorado</option>
                      <option value="CT"> Connecticut</option>
                      <option value="DE"> Delaware</option>
                      <option value="DC"> District of Columbia</option>
                      <option value="FL"> Florida</option>
                      <option value="GA"> Georgia</option>
                      <option value="HI"> Hawaii</option>
                      <option value="ID"> Idaho</option>
                      <option value="IL"> Illinois</option>
                      <option value="IN"> Indiana</option>
                      <option value="IA"> Iowa</option>
                      <option value="KS"> Kansas</option>
                      <option value="KY"> Kentucky</option>
                      <option value="LA"> Louisiana</option>
                      <option value="ME"> Maine</option>
                      <option value="MD"> Maryland</option>
                      <option value="MA"> Massachusetts</option>
                      <option value="MI"> Michigan</option>
                      <option value="MN"> Minnesota</option>
                      <option value="MS"> Mississippi</option>
                      <option value="MO"> Missouri</option>
                      <option value="MT"> Montana</option>
                      <option value="NE"> Nebraska</option>
                      <option value="NV"> Nevada</option>
                      <option value="NH"> New Hampshire</option>
                      <option value="NJ"> New Jersey</option>
                      <option value="NM"> New Mexico</option>
                      <option value="NY"> New York</option>
                      <option value="NC"> North Carolina</option>
                      <option value="ND"> North Dakota</option>
                      <option value="OH"> Ohio</option>
                      <option value="OK"> Oklahoma</option>
                      <option value="OR"> Oregon</option>
                      <option value="PA"> Pennsylvania</option>
                      <option value="RI"> Rhode Island</option>
                      <option value="SC"> South Carolina</option>
                      <option value="SD"> South Dakota</option>
                      <option value="TN"> Tennessee</option>
                      <option value="TX"> Texas</option>
                      <option value="UT"> Utah</option>
                      <option value="VT"> Vermont</option>
                      <option value="VA"> Virginia</option>
                      <option value="WA"> Washington</option>
                      <option value="WV"> West Virginia</option>
                      <option value="WI"> Wisconsin</option>
                      <option value="WY"> Wyoming</option>
                    </select>
                  </span>
                </div>
              </div>
              <div className="mt-3">
                <label>ZipCode:</label>
                <input
                  className="field form-control"
                  name="zipcode"
                  id="zipcode"
                  value={zipcode.value}
                  onChange={zipcode.onChange}
                />
              </div>
              <div className="mt-3">
                <label>Phone:</label>
                <input
                  className="field form-control"
                  name="phone"
                  id="phone"
                  value={phone.value}
                  onChange={phone.onChange}
                />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveLocation}>
            {locationId && locationId.value ? "Save Changes" : "Add Location"}{" "}
          </Button>
          <Button color="secondary" onClick={toggleLocationModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalFirstExperience} toggle={toggleFirstLocationModal}>
        <ModalHeader toggle={toggleFirstLocationModal}>
          Add Experience
        </ModalHeader>
        <ModalBody>
          <form name="frmWorkExperience" id="frmWorkExperience">
            <div>
              <div className="mt-3">
                <span>
                  Create your first location to start posting job oppurtunities
                  to students
                </span>
              </div>
              <div className="mt-3">
                <label>Location Name:</label>
                <input
                  className="field form-control"
                  name="location"
                  id="location"
                  value={location.value}
                  onChange={location.onChange}
                />
              </div>
              <div className="mt-3">
                <label>About:</label>
                <textarea
                  className="textarea form-control"
                  id="about"
                  name="about"
                  placeholder="Write a description about your location"
                  value={about.value}
                  onChange={about.onChange}
                ></textarea>
              </div>
              <div className="mt-12">
                <label>Address 1:</label>
                <input
                  className="field form-control"
                  name="address1"
                  id="address1"
                  value={address1.value}
                  onChange={address1.onChange}
                />
              </div>
              <div className="mt-12">
                <label>Address 2:</label>
                <input
                  className="field form-control"
                  name="address2"
                  id="address2"
                  value={address2.value}
                  onChange={address2.onChange}
                />
              </div>
              <div className="mt-3">
                <label>City:</label>
                <input
                  className="form-control"
                  name="city"
                  id="city"
                  value={city.value}
                  onChange={city.onChange}
                />
              </div>
              <div className="mt-3">
                <label>State:</label>
                <div className="drop_line">
                  <span className="drop">
                    <select
                      name="state"
                      id="state"
                      className="form-control ui search selection dropdown"
                      value={state.value}
                      onChange={state.onChange}
                    >
                      <option value="0">Select A State</option>
                      <option value="0">Select A State</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA"> California</option>
                      <option value="CO"> Colorado</option>
                      <option value="CT"> Connecticut</option>
                      <option value="DE"> Delaware</option>
                      <option value="DC"> District of Columbia</option>
                      <option value="FL"> Florida</option>
                      <option value="GA"> Georgia</option>
                      <option value="HI"> Hawaii</option>
                      <option value="ID"> Idaho</option>
                      <option value="IL"> Illinois</option>
                      <option value="IN"> Indiana</option>
                      <option value="IA"> Iowa</option>
                      <option value="KS"> Kansas</option>
                      <option value="KY"> Kentucky</option>
                      <option value="LA"> Louisiana</option>
                      <option value="ME"> Maine</option>
                      <option value="MD"> Maryland</option>
                      <option value="MA"> Massachusetts</option>
                      <option value="MI"> Michigan</option>
                      <option value="MN"> Minnesota</option>
                      <option value="MS"> Mississippi</option>
                      <option value="MO"> Missouri</option>
                      <option value="MT"> Montana</option>
                      <option value="NE"> Nebraska</option>
                      <option value="NV"> Nevada</option>
                      <option value="NH"> New Hampshire</option>
                      <option value="NJ"> New Jersey</option>
                      <option value="NM"> New Mexico</option>
                      <option value="NY"> New York</option>
                      <option value="NC"> North Carolina</option>
                      <option value="ND"> North Dakota</option>
                      <option value="OH"> Ohio</option>
                      <option value="OK"> Oklahoma</option>
                      <option value="OR"> Oregon</option>
                      <option value="PA"> Pennsylvania</option>
                      <option value="RI"> Rhode Island</option>
                      <option value="SC"> South Carolina</option>
                      <option value="SD"> South Dakota</option>
                      <option value="TN"> Tennessee</option>
                      <option value="TX"> Texas</option>
                      <option value="UT"> Utah</option>
                      <option value="VT"> Vermont</option>
                      <option value="VA"> Virginia</option>
                      <option value="WA"> Washington</option>
                      <option value="WV"> West Virginia</option>
                      <option value="WI"> Wisconsin</option>
                      <option value="WY"> Wyoming</option>
                    </select>
                  </span>
                </div>
              </div>
              <div className="mt-3">
                <label>ZipCode:</label>
                <input
                  className="field form-control"
                  name="zipcode"
                  id="zipcode"
                  value={zipcode.value}
                  onChange={zipcode.onChange}
                />
              </div>
              <div className="mt-3">
                <label>Phone:</label>
                <input
                  className="field form-control"
                  name="phone"
                  id="phone"
                  value={phone.value}
                  onChange={phone.onChange}
                />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={saveLocation}>
            {locationId && locationId.value ? "Save Changes" : "Add Location"}{" "}
          </Button>
          <Button color="secondary" onClick={toggleLocationModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Locations;
