import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useInput from "../../../../hooks/useInput.js";
import CKEditors from "react-ckeditor-component";
import { useMutation } from "@apollo/client";
import {
  ADD_EMPLOYER_LOCATION_IMAGE,
  EDIT_PROFILE_IMAGE,
  GET_STUDENT_DETAILS,
} from "../../../../api/db.js";
import { GetUserID } from "../../../../api/session.js";
import "react-toastify/dist/ReactToastify.css";
import SimpleReactValidator from "simple-react-validator";

const LocationJobContact = ({ form, validator }) => {
  console.log(form);

  let isPublic = form.isPublic.value ?? true;
  let title = form.title ?? "";
  let isActive = form.isActive.value ?? true;
  let about = form.about ?? "";
  let keywords = form.keywords.value ?? new Array();
  console.log(form.keywordlist);
  console.log(keywords);

  const publicCheckHandle = (e) => {
    isPublic = !isPublic;
    form.isPublic.setValue(isPublic);
  };

  const activeCheckHandle = (e) => {
    isActive = !isActive;

    form.isActive.setValue(isActive);
  };

  const keywordsCheckHandle = (input, e) => {
    let keys = keywords.map((x) => x);

    if (keys.includes(input)) {
      keys.splice(keys.indexOf(input), 1);
    } else {
      keys.push(input);
    }

    form.keywords.setValue(keys);
    console.log(form.keywords);
  };

  const onChange = (evt) => {
    const newContent = evt.editor.getData();
    about.setValue(newContent);
  };

  console.log(keywords);

  return (
    <div class="col-xl-12 col-xl-6">
      <div className="card shadow-0 border mt-0">
        <div className="card-header bg-primary">
          <h5>Job Listing</h5>
        </div>
        <div className="card-body">
          <div class="col-12">
            <label>Position Title:</label>
            <input
              className="form-control mb-2"
              type="text"
              value={title.value}
              id="careertitle"
              placeholder="ex: Hair Stylist"
              onChange={title.onChange}
              name="careertitle"
            />
            {validator.current.message("title", title.value, "required")}
          </div>
          <div class="col full mb-2">
            <label>Job Description</label>
            <CKEditors
              activeclassName="p10"
              content={about.value}
              events={{
                change: onChange,
              }}
            />
            {validator.current.message(
              "job description",
              about.value,
              "required"
            )}
          </div>
          <div class="col3 full employer-check-job">
            <label className="fw-bold mt-3">Choose Job Keywords:</label>

            <div className="form-group m-checkbox-inline mb-0 ms-1">
              {form.keywordlist &&
                form.keywordlist.map((x, i) => (
                  <div key={i}>
                    <label className="">
                      <input
                        type="checkbox"
                        value={x.KeywordID}
                        checked={keywords.includes(x.KeywordID)}
                        onChange={(e) => {
                          keywordsCheckHandle(x.KeywordID, e);
                        }}
                      />
                      <span className="ps-2">{x.Keyword}</span>
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LocationJobContact;
