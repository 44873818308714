import React, { Fragment, useState, useEffect, useRef } from 'react'
import Header from './common/header-component/header'
import Sidebar from './common/sidebar-component/sidebar'
import RightSidebar from './common/right-sidebar'
import Footer from './common/footer'
import { ToastContainer } from 'react-toastify'
import Loader from './common/loader'
import { Outlet } from 'react-router-dom'
import Authorize from './shared/authorize'
import HeaderMobile from './common/header-component/header-mobile'
import FooterMobile from './common/footer-mobile'

const AppMobileLayout = (props) => {
  const [sidebar, setSidebar] = useState(false)
  const [rightSidebar, setRightSidebar] = useState(false)
  const wrapperRef = useRef(null)

  function showRightSidebar() {
    var btn = document.getElementById('btn-mobile-conversations')

    if (btn) {
      btn.click()
    } else {
      if (rightSidebar) {
        setRightSidebar(!rightSidebar)
        document.querySelector('.right-sidebar').classList.add('show')
      } else {
        setRightSidebar(!rightSidebar)
        document.querySelector('.right-sidebar').classList.remove('show')
      }
    }
  }

  function closeRightSidebar() {
    setRightSidebar(false)
  }

  return (
    <>
      <div>
        <div>
          <div>
            <HeaderMobile messageclick={() => showRightSidebar()} />
          </div>
        </div>
        <div fullscreen={true}>
          <div className="page-wrapper">
            <div className="page-body-wrapper">
              <div>
                <Sidebar />
              </div>
              <RightSidebar
                show={rightSidebar}
                onClickOutside={() => closeRightSidebar()}
              />
              <div className="page-body">
                <Outlet />
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
        <div>
          <div>
            <FooterMobile messageclick={() => showRightSidebar()} />
          </div>
        </div>
      </div>
    </>
  )
}

export default AppMobileLayout
