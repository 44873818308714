import React from "react";
import { useQuery } from "@apollo/client";
import { GET_STUDENT_DETAILS, GET_USER_INFO } from "../api/db.js";
import { GetUserID } from "../api/session.js";
import JobSearch from "../components/search-jobs/job-search";
import { errorCheck } from "../components/common/functions.js";
import SchoolSearch from "../components/schools/school-search.js";

const Schools = () => {
  const { loading, error, data } = useQuery(GET_USER_INFO, {
    variables: { userId: parseInt(GetUserID()) },
  });

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );

  if (error) {
    return errorCheck(error);
  }

  return <SchoolSearch query={data.user.Zipcode} />;
};

export default Schools;
