import React, { Fragment, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_USER_INFO } from "../../../api/db.js";
import {
  ClearFirstLogin,
  GetFirstLogin,
  GetUserID,
} from "../../../api/session.js";
import ContactInformation from "./profile-edit-contact";
import Breadcrumb from "../../common/breadcrumb.js";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import useInput from "../../../hooks/useInput.js";
import ProfileImageEdit from "./profile-edit-image.js";
import ProfileEditEmail from "./profile-edit-email.js";
import ProfileEditPassword from "./profile-edit-password.js";

const ProfileEditUser = () => {
  var form = {
    userid: "",
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
  };

  const hasApproved = useInput(false);
  const showModal = useInput(false);
  const newEmail = useInput("");
  const showPasswordModal = useInput(false);
  const currentPassword = useInput("");
  const newPassword = useInput("");
  const confirmNewPassword = useInput("");
  const canChangePassword = useInput(false);

  const [modalFirstExperience, setModalFirstLocation] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const toggleFirstLocationModal = () => {
    if (GetFirstLogin()) {
      setModalFirstLocation(!modalFirstExperience);
    }
  };

  const { loading, error, data, refetch } = useQuery(GET_USER_INFO, {
    variables: { userId: GetUserID() },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  if (loading && !isRefreshing)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );
  if (error) return <p>Error : {error.message}</p>;

  if (data) {
    console.log(data);
    form = {
      userid: data.user.UserID,
      firstName: data.user.FirstName ?? "",
      lastName: data.user.LastName ?? "",
      address1: data.user.Address1 ?? "",
      address2: data.user.Address2 ?? "",
      city: data.user.City ?? "",
      state: data.user.State,
      zip: data.user.Zipcode ?? "",
      phone: data.user.Phone ?? "",
      email: data.user.Email ?? "",
      password: "",
    };
  }
  const toggleLocationModal = () => {
    ClearFirstLogin();
    setModalFirstLocation(!modalFirstExperience);
  };

  const handleReloading = () => {
    setIsRefreshing(true);
    refetch();
  };

  return (
    <Fragment>
      <Breadcrumb title="Profile" />
      <div className="container-fluid">
        <div className="edit-profile">
          <div className="row">
            <div className="col-lg-4">
              <div className="card mt-0">
                <div className="card-header">
                  <h4 className="card-title mb-0">My Profile</h4>
                  <div className="card-options">
                    <a
                      className="card-options-collapse"
                      href="javascript"
                      data-toggle="card-collapse"
                    >
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a
                      className="card-options-remove"
                      href="javascript"
                      data-toggle="card-remove"
                    >
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <ProfileImageEdit
                    isStudent={false}
                    profile={data.user.ProfileImage}
                    banner={data.user.BannerImage}
                    FirstName={data.user.FirstName}
                    LastName={data.user.LastName}
                    RoleName={data.user.UserType.Type}
                    onSave={handleReloading}
                    showLoading={loading && isRefreshing}
                  />
                  <ProfileEditEmail
                    form={form}
                    isStudent={false}
                    onSave={refetch}
                    hasApproved={hasApproved}
                    showModal={showModal}
                    newEmail={newEmail}
                  />
                  <ProfileEditPassword
                    onSave={refetch}
                    showPasswordModal={showPasswordModal}
                    currentPassword={currentPassword}
                    newPassword={newPassword}
                    confirmNewPassword={confirmNewPassword}
                    canChangePassword={canChangePassword}
                  />
                </div>
              </div>
            </div>
            <ContactInformation
              form={form}
              isStudent={false}
              onSave={refetch}
            />
          </div>
        </div>
      </div>
      <Modal
        backdrop="static"
        isOpen={modalFirstExperience}
        toggle={toggleFirstLocationModal}
      >
        <ModalHeader toggle={toggleFirstLocationModal}>
          Welcome to the Beauty Career
        </ModalHeader>
        <ModalBody>
          <div>
            <hl>
              Hello {data.user.FirstName} {data.user.LastName}
            </hl>
            <p>
              We are glad you can join us. Please complete your profile so that
              job applicants and other employers can know how to find you.
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleLocationModal}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ProfileEditUser;
