import React, {useState} from 'react';

const LocationViewPhotos =({portfolios}) => {
  console.log(portfolios);
  return(
    <div className="col-12">
            <div className="card">
                <div className="card-header d-flex">
                    <h4 className="card-title me-4 align-self-end">Photos</h4>
                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                                 
                </div>
                <div className="table-responsive col-md-10">
                    <div class="row">
                    {portfolios && portfolios.map((item, i) => {
                        return (<div  key={i} className='col-sm-8'>                            
                        <a className="text-inherit" href={item.Src+"?w=850&amp;h=850"}><img className='img-thumbnail' width={250} height={250} src={item.Src+"?w=150&amp;h=150&amp;mode=crop&amp;anchor=topcenter"} loading="lazy" alt="portfolio"/> </a>
                        </div>);  
                    })}
                       
                    </div>
                   
                </div>
            </div>
          </div>  
       );
  }



export default LocationViewPhotos