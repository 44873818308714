import React, { Fragment, useState } from 'react'
import { Alert } from 'reactstrap'
import { useQuery, gql, useLazyQuery, useMutation } from '@apollo/client'
import logo from '../../assets/images/endless-logo.png'
import { SetUserJWT, LoginSuccess } from '../../api/session.js'
import { RESET_PASSWORD, RESET_PASSWORD_REQUEST } from '../../api/db.js'

let email
let password
//let Gqllogin;

const ForgotPassword = () => {
  const [username, setUsername] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const handleUserNameChange = (e) => setUsername(e.target.value)

  const [logUserIn, { loading, saveerror, data }] = useMutation(
    RESET_PASSWORD_REQUEST
  )

  //console.log('handlesubmit!');

  if (saveerror) return <p>Error:{saveerror.message}</p>
  const handleForgot = async (e) => {
    e.preventDefault()

    if (!username) return alert('Write something')

    //Forgot Password submission goes here

    try {
      await logUserIn({ variables: { email: username } })
    } catch (err) {
      console.log(err)

      setError(err.message)
      setUsername('')
    }
  }

  if (data && data.resetPasswordRequest.success) {
    setSuccess(
      'We sent you an email with instructions to change your password.'
    )
  }
  const showloader = loading || data
  return (
    <Fragment>
      <div className="page-wrapper">
        <div className="auth-bg">
          <div className="authentication-box">
            <div className="text-center">
              <img src={logo} alt="" />
            </div>
            <div className="card mt-4 mb-2">
              <div className="card-body">
                <div className="text-center">
                  <h4>Forgot Password</h4>
                  <h6>{'Enter your Username'} </h6>
                  {success && (
                    <Alert color="info dark alert-dismissible">
                      <p>{success}</p>
                    </Alert>
                  )}

                  {error && (
                    <Alert color="danger dark alert-dismissible">
                      <p>{error}</p>
                    </Alert>
                  )}
                </div>
                {showloader && (
                  <div className="theme-form">
                    <div className="loader-box" style={{ display: 'flex' }}>
                      <div
                        className="loader"
                        style={{ alignItems: 'center', width: '100%' }}
                      >
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                      </div>
                    </div>
                  </div>
                )}

                {!showloader && (
                  <form
                    className="theme-form"
                    onSubmit={(e) => handleForgot(e)}
                  >
                    <div className="form-group">
                      <label className="col-form-label pt-0">
                        Email Address
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        required=""
                        placeholder="YourEmail@Example.com"
                        onChange={(e) => handleUserNameChange(e)}
                      />
                    </div>

                    <div className="form-group">
                      <button className="btn btn-primary w-100" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
            <div className="text-end">
              <a href="/login">
                <span>
                  <i className="fa fa-angle-left pe-2"></i>Go Back
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default ForgotPassword
