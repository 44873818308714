import React, { useState, useEffect, useRef } from "react";
import {
  format,
  formatDistance,
  formatRelative,
  subDays,
  getUnixTime,
} from "date-fns";
import { useQuery } from "@apollo/client";
import { CONVERSATION } from "../../api/db";
import ConversationHeader from "./conversation-header";
import MessageInput from "../shared/message-input";
import refreshData from "../../hooks/refresh";
import MessageUserIcon from "./message-user-icon";
import { GetUserInfo } from "../../api/session";

function groupedDays(messages) {
  return messages.reduce((acc, el, i) => {
    const messageDay = format(el.Datestamp, "yyyy-MM-dd");
    if (acc[messageDay]) {
      return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
    }
    return { ...acc, [messageDay]: [el] };
  }, {});
}

const ChatDesktop = ({ conversationid }) => {
  console.log("current conversation " + conversationid);
  const [currentConversatiionId, setcurrentConversatiionId] =
    useState(conversationid);

  const divRef = useRef(null);

  if (document.getElementById("chat-messager"))
    document.getElementById("chat-messager").scrollIntoView();
  else {
    var objDiv = document.getElementById("chatbox");
    if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
  }

  const user = JSON.parse(localStorage.getItem("user"));
  var currentUser = GetUserInfo(user.UserID);
  /* useEffect(() => {
    var container = document.querySelector(".chat-history");
    if(container != null)
    {
      setTimeout(function () {
        container.scrollBy({ top: container.scrollHeight, behavior: 'smooth' });
    }, 310);
    }
  });*/

  const { loading, error, data, refetch } = useQuery(CONVERSATION, {
    variables: { conversationId: conversationid },
    pollInterval: 3000,
  });

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );
  if (error) return <p>Error : {error.message}</p>;

  const users = data.getConversation.Users.filter(
    (item) => item.UserID !== user.UserID
  );
  const userIds = [];

  users.map((x) => userIds.push(x.UserID));

  const days = groupedDays(data.getConversation.Messages);
  const sortedDays = Object.keys(days).sort(
    (x, y) =>
      getUnixTime(format(y, "yyyy-MM-dd")) -
      getUnixTime(format(x, "yyyy-MM-dd"))
  );

  const items = sortedDays.reverse().reduce((acc, date) => {
    const sortedMessages = days[date].sort(
      (x, y) => new Date(y.Datestamp) - new Date(x.Datestamp)
    );
    return acc.concat([
      { type: "day", date, id: date },
      ...sortedMessages.reverse(),
    ]);
  }, []);

  const divStyle = {
    position: "fixed", // 'ms' is the only lowercase vendor prefix
  };

  return (
    <div className="card mt-0">
      <div className="card-body p-0">
        <div className="row chat-box">
          <div className="col pe-0 chat-right-aside">
            <div className="chat">
              {users.map((x) => {
                return (
                  <ConversationHeader
                    key={x.UserID}
                    conversationid={x.ConversationID}
                    user={x}
                  />
                );
              })}

              <div
                id="chatbox"
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                  overflow: "auto",
                  overflowAnchor: "none",
                  position: "sticky",
                  overflowAnchor: "none",
                }}
                className="chat-history chat-msg-box custom-scrollbar"
              >
                <ul>
                  {items.map((item, i) => {
                    if (item.type && item.type === "day") {
                      return (
                        <li key={i} className="clearfix">
                          <div
                            key={i}
                            className="d-flex mb-4 align-items-center justify-content-center"
                          >
                            <span className="d-block border-top flex-grow-1"></span>
                            <div className="text-body-secondary mx-3 small">
                              {item.date}
                            </div>
                            <span className="d-block border-top flex-grow-1"></span>
                          </div>
                        </li>
                      );
                    } else {
                      var inout =
                        item.FromUserID == user.UserID ? "float-end" : "";
                      var mtype =
                        item.FromUserID == user.UserID
                          ? "my-message"
                          : "other-message";
                      var iconout =
                        item.FromUserID == user.UserID
                          ? "float-end"
                          : "float-start";
                      var textout =
                        item.FromUserID == user.UserID
                          ? "text-start"
                          : "text-end";
                      return (
                        <li key={i} className="clearfix">
                          <div key={i} className={`message ${mtype} ${inout}`}>
                            {iconout == "float-end" && (
                              <MessageUserIcon
                                key={user.UserID}
                                conversationid={currentConversatiionId}
                                user={currentUser}
                                floattype={iconout}
                              />
                            )}
                            {iconout != "float-end" &&
                              users.map((x) => {
                                return (
                                  <MessageUserIcon
                                    key={x.UserID}
                                    conversationid={x.ConversationID}
                                    user={x}
                                    floattype={iconout}
                                  />
                                );
                              })}

                            <div className={`message-data ${textout}`}>
                              <span className="message-data-time">
                                {format(item.Datestamp, "h:mm a")}
                              </span>
                            </div>
                            {item.Message}
                          </div>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
              <div
                id="chat-messager"
                ref={divRef}
                style={{ position: "sticky", bottom: "0px" }}
                className="sticky-bottom chat-message clearfix"
                data-dropzone-area=""
              >
                <MessageInput
                  conversationid={currentConversatiionId}
                  userIds={userIds}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer"></div>
    </div>
  );
};

export default ChatDesktop;
