import React, { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from 'reactstrap'
import { useMutation } from '@apollo/client'
import { DELETE_LOCATION } from '../../api/db'
import SweetAlert from 'sweetalert2'

const LocationCard = ({ location, refreshCallback }) => {
  console.log(location)
  let navigate = useNavigate()

  const [deleteLocation, {}] = useMutation(DELETE_LOCATION, {})

  const editLocation = () => {
    let path = `${process.env.PUBLIC_URL}/location`
    let fullpath = location.EmployerLocationsID
      ? path + '/' + location.EmployerLocationsID
      : path

    navigate(fullpath)
  }

  const handleDeleteLocation = async (locationid) => {
    console.log(locationid)

    SweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        try {
          if (locationid) {
            await deleteLocation({
              variables: { employerLocationsId: locationid },
            })

            refreshCallback()
          }
        } catch (err) {
          console.log(err)
        }
      }
    })
  }

  let profile = location.ProfileImage

  if (!profile) {
    profile = '/images/profile-employer.jpg?w=165&amp;h=165&amp;mode=crop'
  }

  return (
    <Fragment>
      <div className="col-12 col-md-4">
        <div className="card shadow-0 border mt-0">
          <div className="card-header pb-0 px-0 pt-3">
            <div className="connection mb-0 mx-0 rounded-top">
              <div className="blur">
                <img
                  height={260}
                  className="rounded-top-left"
                  alt=""
                  src="/images/blur2.jpg?h=260&amp;mode=crop&amp;anchor=topcenter"
                />
              </div>
              <img alt="" src={profile} className="connect-left small" />
              <div className="grouped">
                <h2>{location.LocationName}</h2>
                <p className="slant">
                  {location.City}, {location.State}
                </p>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="btn-showcase">
              <button className="btn btn-sm btn-outline-secondary">
                <Link
                  className="text-dark"
                  to={'/careers/' + location.EmployerLocationsID}
                >
                  {location.Openings.length} Job Postings
                </Link>
              </button>

              <Button
                className="btn-sm"
                color="primary"
                onClick={() => editLocation()}
              >
                Edit{' '}
              </Button>
              <Button
                className="btn-sm"
                color="danger"
                onClick={() =>
                  handleDeleteLocation(location.EmployerLocationsID)
                }
              >
                Delete{' '}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default LocationCard
