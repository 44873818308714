import { GetUser, GetStudent } from "../../api/session";

const ResumePrintStyle2 = ({ resume }) => {
  console.log(resume);
  var student = GetStudent(resume.studentId);

  console.log(student);
  var color = "blue";
  if (resume.color == "1") color = "#0088b0";

  if (resume.color == "2") color = "#6bab39";

  if (resume.color == "3") color = "#e53679";

  let careertype = "";
  const ctypes = [
    { id: "1", category: "Cosmetologist" },
    { id: "6", category: "Makeup Artist" },
    { id: "8", category: "Hairstylist" },
    { id: "11", category: "Assistant/Apprentice" },
    { id: "12", category: "Barber" },
    { id: "13", category: "Braider" },
    { id: "14", category: "Colorist" },
    { id: "15", category: "Educator-Schools" },
    { id: "16", category: "Educator-Salons" },
    { id: "17", category: "Education Management" },
    { id: "18", category: "Esthetician/Skin Care Specialist" },
    { id: "21", category: "Nail Technician/Manicurist" },
    { id: "22", category: "Product Specialist" },
    { id: "23", category: "Receptionist" },
    { id: "24", category: "Sales Consultant" },
    { id: "25", category: "Salon Management" },
    { id: "26", category: "Spa Management" },
    { id: "27", category: "Esthetician/Cosmetologist" },
  ];

  careertype = resume.careerType
    ? ctypes.find((e) => e.id == resume.careerType).category
    : "";

  return (
    <div id="printPage" className="justify-items-center">
      <div id="resumeebody" className="container">
        <div className="row">
          <div
            className="col-12 bg-light text-dark p-3"
            style={{ backgroundColor: color }}
          >
            <h2 className="display-6">
              {resume.firstName} {resume.lastName}
            </h2>
            <p className="lead">{careertype}</p>
            <hr className="my-4 bg-light" />
            <p>
              <strong>Contact:</strong>
            </p>
            {resume.phone && <p className="mb-0">Phone: {resume.phone}</p>}

            {resume.email && <p>Email: {resume.email}</p>}
          </div>
          <div className="col-md-12 bg-light text-body p-md-2">
            <h3 className="font-weight-bold">Summary</h3>
            <p dangerouslySetInnerHTML={{ __html: resume.objective }}></p>
            <h3 className="font-weight-bold">Experience</h3>
            {resume.experiences &&
              resume.experiences.map((exp, i) => {
                var start =
                  exp.startMonth == "13" || exp.startYear == "1"
                    ? "Present"
                    : exp.startMonth + "/" + exp.startYear;
                var end =
                  exp.endMonth == "13" || exp.endYear == "1"
                    ? "Present"
                    : exp.endMonth + "/" + exp.endYear;

                return (
                  <p key={i}>
                    {exp.employerName},{" "}
                    {exp.employerCity + ", " + exp.employerState} {exp.title},{" "}
                    {start + " - " + end}
                  </p>
                );
              })}
            <h3 className="font-weight-bold">Education</h3>
            {resume.educations &&
              resume.educations.map((edu, i) => {
                var start =
                  edu.startMonth == "13" || edu.startYear == "1"
                    ? "Present"
                    : edu.startMonth + "/" + edu.startYear;
                var end =
                  edu.endMonth == "13" || edu.endYear == "1"
                    ? "Present"
                    : edu.endMonth + "/" + edu.endYear;
                return (
                  <p key={i}>
                    {edu.school + ", " + edu.city + ", " + edu.state}{" "}
                    {edu.certificate}, {start + " - " + end}
                  </p>
                );
              })}
          </div>
        </div>
      </div>
    </div>
    //   <div  className="container my-3">
    //         <div className="row mx-auto g-0">
    //             <div className="col">
    //                 <div className="row g-0 position-relative" style={{backgroundColor:color}}>
    //                     <div className="col-2">
    //                         <div className="p-3">
    //                           {resume.profile &&
    //                            <img className="top-0 left-0 position-absolute" style={{border: "3px solid white", margin:"60px 0 0 30px", width:"120px", height: "120px", borderRadius:"50%"}} src={resume.profile}/>
    //                           }
    //                         </div>
    //                     </div>
    //                     <div className="col-6">
    //                         <div className="p-3 text-white">
    //                             <span className="fs-5 text-uppercase d-block fw-bold">{resume.firstName}</span>
    //                             <span className="fs-5 text-uppercase d-block fw-bold pb-3">{resume.lastName}</span>
    //                             <span className="fs-6 text-uppercase"></span>
    //                         </div>
    //                     </div>
    //                     <div className="col-4 text-end p-3 text-white align-self-end">
    //                     {resume.phone &&
    //                       <span className="fs-6 text-uppercase d-block">p: {resume.phone}</span>
    //                       }

    //                       {resume.email &&
    //                       <span className="fs-6 text-uppercase">e: {resume.email}</span>
    //                       }
    //                     </div>
    //                 </div>
    //                 <div className="row g-0 position-relative text-white" style={{marginTop: "100px"}}>
    //                     <div className="col-2">

    //                     </div>
    //                     <div className="col-8">
    //                         <div>
    //                             <span className="fw-bold ps-3 d-block" style={{color:color}}>
    //                                 Objective
    //                             </span>
    //                             <span className="fs-6 d-block text-dark ps-3">

    //                             <p dangerouslySetInnerHTML={{__html: resume.objective}}>

    //                                             </p>
    //                             </span>
    //                         </div>
    //                     </div>
    //                     <div className="col-2">

    //                     </div>
    //                 </div>
    //                 <div className="row g-0 position-relative text-white" style={{backgroundColor:color, marginTop: "100px"}}>
    //                     <div className="col">
    //                         <div className="p-3 fw-bold">
    //                             Employment
    //                         </div>
    //                     </div>
    //                 </div>
    //                 {resume.experiences && resume.experiences.map((exp,i) => {

    //                            var start = exp.startMonth == "Present" || exp.startYear == "Present" ? "Present" : exp.startMonth+'/'+exp.startYear;
    //                            var end = exp.endMonth == "Present" || exp.endYear == "Present" ? "Present" : exp.endMonth+'/'+exp.endYear;

    //                           return(<div  key={i} className="row g-0 position-relative text-white" style={{color:color, marginTop: "100px"}}>
    //                           <div className="col-2">

    //                           </div>

    //                           <div className="col-8">
    //                               <div>
    //                                   <span className="fw-bold ps-3 d-block" style={{color:color}}>
    //                                   {exp.title} {exp.employerName}, {(exp.employerCity+', '+exp.employerState)}
    //                                   </span>
    //                                   <span className="fw-bold fs-6 ps-3 d-block" style={{color:color}}>
    //                                   {start+' - '+end}
    //                                   </span>
    //                                   <span className="fs-6 d-block text-dark ps-3">
    //                                   {exp.description}
    //                                   </span>
    //                               </div>
    //                           </div>
    //                           <div className="col-2">

    //                           </div>
    //                       </div>)
    //                       })}

    //                 <div className="row g-0 position-relative text-white" style={{backgroundColor:color, marginTop: "100px"}}>
    //                     <div className="col">
    //                         <div className="p-3 fw-bold">
    //                             Education
    //                         </div>
    //                     </div>
    //                 </div>
    //                 {resume.educations && resume.educations.map((edu,i) => {

    //                            var start = edu.startMonth == "Present" || edu.startYear == "Present" ? "Present" : edu.startMonth+'/'+edu.startYear;
    //                            var end = edu.endMonth == "Present" || edu.endYear == "Present" ? "Present" : edu.endMonth+'/'+edu.endYear;

    //                            return(   <div key={i} className="row g-0 position-relative text-white" style={{color:color, marginTop: "100px"}}>
    //                            <div className="col-2">

    //                            </div>
    //                            <div className="col-8">
    //                                <div>
    //                                    <span className="fw-bold ps-3 d-block" style={{color:color}}>
    //                                    {edu.school+', '+edu.city+', '+edu.state}
    //                                    </span>
    //                                    <span className="fw-bold fs-6 ps-3 d-block" style={{color:color}}>
    //                                    {start+ ' - '+end}
    //                                    </span>
    //                                    <span className="fs-6 d-block text-dark ps-3">
    //                                    {edu.certificate}
    //                                    </span>
    //                                </div>
    //                            </div>
    //                            <div className="col-2">

    //                            </div>
    //                        </div>)
    //                            })}

    //             </div>
    //         </div>
    //     </div>
  );
};

export default ResumePrintStyle2;
