import { contentfulClient } from "./contentful";

export const getContentfulType = async(type) => {
try{
    const pageContent = await contentfulClient.getEntries({       
        content_type: type
    });

    return pageContent;
}
catch(error)
{
    console.log(error);
}
}