import React, { Router } from "react";
import UserSidebar from "./user-sidebar.js";
import EmployerSidebar from "./employer-sidebar.js";
import { GetUserType } from "../../../api/session";
import AdminSidebar from "./admin-sidebar.js";

function renderView(userType, props) {
  switch (userType) {
    case "Admin":
    case "Director":
      return <AdminSidebar props={props} />;
    case "Student":
      return <UserSidebar props={props} />;
    case "Employer":
      return <EmployerSidebar props={props} />;
    default:
      return <UserSidebar props={props} />;
  }
}
const Sidebar = (props) => {
  return renderView(GetUserType(), props);
};

export default Sidebar;
