import React, { useRef, useState } from "react";
import useInput from "../../../hooks/useInput.js";
import { useMutation } from "@apollo/client";
import {
  EDIT_PROFILE,
  UPDATE_BASIC_USER_DETAILS,
  UPDATE_USER_DETAILS,
} from "../../../api/db.js";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import "react-toastify/dist/ReactToastify.css";

const ContactInformation = ({ form, isStudent, onSave }) => {
  var validator = useRef(new SimpleReactValidator({ autoForceUpdate: this }));
  const firstname = useInput(form && form.firstName);
  const lastName = useInput(form && form.lastName);
  const address1 = useInput(form && form.address1);
  const address2 = useInput(form && form.address2);
  const city = useInput(form && form.city);
  const state = useInput(form && form.state);
  const zipcode = useInput(form && form.zip);
  const phone = useInput(form && form.phone);
  const email = useInput(form && form.email);
  const openForWork = useInput(form && form.openForWork);
  const [updateRequired, setUpdateRequired] = useState(false);
  const [, forceUpdate] = useState();

  const handleFormChange = (data, e) => {
    data.onChange(e);

    setUpdateRequired(true);
  };

  const handleOpenForWorkChange = (data, e) => {
    openForWork.setValue(!openForWork.value);

    setUpdateRequired(true);
  };

  const [editProfileMutation, { saveloading }] = useMutation(EDIT_PROFILE, {});

  const [editUserProfileMutation] = useMutation(UPDATE_USER_DETAILS, {});

  const [editUserBasicProfileMutation] = useMutation(
    UPDATE_BASIC_USER_DETAILS,
    {}
  );

  const handleEditProfile = async (e) => {
    e.preventDefault();

    if (!validator.current.allValid() || !lastName.value) {
      validator.current.showMessages();
      forceUpdate(1);
      return toast.error("First Name and Last Name required");
    }
    try {
      if (isStudent) {
        console.log(openForWork);
        await editProfileMutation({
          variables: {
            userId: Number(form.userid),
            firstName: firstname.value,
            lastName: lastName.value,
            email: email.value,
            phone: phone.value,
            address1: address1.value,
            address2: address2.value,
            city: city.value,
            state: state.value,
            zipcode: zipcode.value,
            isprivate: openForWork.value,
          },
        });

        await editUserProfileMutation({
          variables: {
            userId: Number(form.userid),
            firstName: firstname.value,
            lastName: lastName.value,
            phone: phone.value,
            address1: address1.value,
            address2: address2.value,
            city: city.value,
            state: state.value,
            zipcode: zipcode.value,
          },
        });
      } else {
        await editUserBasicProfileMutation({
          variables: {
            userId: Number(form.userid),
            firstName: firstname.value,
            lastName: lastName.value,
          },
        });
      }

      toast("Your Profile has been updated", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      onSave();
    } catch (err) {
      console.log(err);
      toast.error("Cannot save profile. Please try again.");
    }
  };

  return (
    <>
      <div className="col-lg-8">
        <form className="card mt-0">
          <div className="card-header">
            <h4 className="card-title mb-0">Contact Information</h4>
            <div className="card-options">
              <a
                className="card-options-collapse"
                href="javascript"
                data-toggle="card-collapse"
              >
                <i className="fe fe-chevron-up"></i>
              </a>
              <a
                className="card-options-remove"
                href="javascript"
                data-toggle="card-remove"
              >
                <i className="fe fe-x"></i>
              </a>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6 col-md-6">
                <div className="form-group mb-3">
                  <label className="form-label mb-3">First Name</label>
                  <input
                    className="form-control mb-4"
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    value={firstname.value}
                    onChange={(e) => handleFormChange(firstname, e)}
                  />
                  {validator.current.message(
                    "first name",
                    firstname.value,
                    "required"
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-md-6">
                <div className="form-group mb-3">
                  <label className="form-label mb-3">Last Name</label>
                  <input
                    className="form-control mb-4"
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    value={lastName.value}
                    onChange={(e) => handleFormChange(lastName, e)}
                  />
                  {validator.current.message(
                    "last name",
                    lastName.value,
                    "required"
                  )}
                </div>
              </div>
              {isStudent && (
                <>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label className="form-label mb-3">Address 1</label>
                      <input
                        className="form-control mb-4"
                        type="text"
                        placeholder="Address 1"
                        name="address1"
                        value={address1.value}
                        onChange={(e) => handleFormChange(address1, e)}
                      />
                      {validator.current.message(
                        "Address",
                        address1.value,
                        "required|alpha_num_space"
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label className="form-label mb-3">Address 2</label>
                      <input
                        className="form-control mb-4"
                        type="text"
                        placeholder="Address 2"
                        name="address2"
                        value={address2.value}
                        onChange={(e) => handleFormChange(address2, e)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group mb-3">
                      <label className="form-label mb-3">City</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="City"
                        name="city"
                        value={city.value}
                        onChange={(e) => handleFormChange(city, e)}
                      />
                      {validator.current.message(
                        "City",
                        city.value,
                        "required|alpha_num_space"
                      )}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group mb-3">
                      <label className="form-label mb-3">State</label>
                      <select
                        value={state.value}
                        name="state"
                        id="state"
                        onChange={(e) => handleFormChange(state, e)}
                        className="form-control btn-square"
                      >
                        <option value="">Select A State</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA"> California</option>
                        <option value="CO"> Colorado</option>
                        <option value="CT"> Connecticut</option>
                        <option value="DE"> Delaware</option>
                        <option value="DC"> District of Columbia</option>
                        <option value="FL"> Florida</option>
                        <option value="GA"> Georgia</option>
                        <option value="HI"> Hawaii</option>
                        <option value="ID"> Idaho</option>
                        <option value="IL"> Illinois</option>
                        <option value="IN"> Indiana</option>
                        <option value="IA"> Iowa</option>
                        <option value="KS"> Kansas</option>
                        <option value="KY"> Kentucky</option>
                        <option value="LA"> Louisiana</option>
                        <option value="ME"> Maine</option>
                        <option value="MD"> Maryland</option>
                        <option value="MA"> Massachusetts</option>
                        <option value="MI"> Michigan</option>
                        <option value="MN"> Minnesota</option>
                        <option value="MS"> Mississippi</option>
                        <option value="MO"> Missouri</option>
                        <option value="MT"> Montana</option>
                        <option value="NE"> Nebraska</option>
                        <option value="NV"> Nevada</option>
                        <option value="NH"> New Hampshire</option>
                        <option value="NJ"> New Jersey</option>
                        <option value="NM"> New Mexico</option>
                        <option value="NY"> New York</option>
                        <option value="NC"> North Carolina</option>
                        <option value="ND"> North Dakota</option>
                        <option value="OH"> Ohio</option>
                        <option value="OK"> Oklahoma</option>
                        <option value="OR"> Oregon</option>
                        <option value="PA"> Pennsylvania</option>
                        <option value="RI"> Rhode Island</option>
                        <option value="SC"> South Carolina</option>
                        <option value="SD"> South Dakota</option>
                        <option value="TN"> Tennessee</option>
                        <option value="TX"> Texas</option>
                        <option value="UT"> Utah</option>
                        <option value="VT"> Vermont</option>
                        <option value="VA"> Virginia</option>
                        <option value="WA"> Washington</option>
                        <option value="WV"> West Virginia</option>
                        <option value="WI"> Wisconsin</option>
                        <option value="WY"> Wyoming</option>
                      </select>
                      {validator.current.message(
                        "State",
                        state.value,
                        "required|alpha"
                      )}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group mb-3">
                      <label className="form-label mb-3">Postal Code</label>
                      <input
                        className="form-control"
                        type="text"
                        name="zip"
                        placeholder="ZIP Code"
                        value={zipcode.value}
                        onChange={(e) => handleFormChange(zipcode, e)}
                      />
                      {validator.current.message(
                        "Zip",
                        zipcode.value,
                        "required"
                      )}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label className="form-label">Phone</label>
                      <input
                        className="form-control"
                        placeholder="000-000-0000"
                        name="phone"
                        value={phone.value}
                        onChange={(e) => handleFormChange(phone, e)}
                      />
                      {validator.current.message(
                        "Phone",
                        phone.value,
                        "required|phone"
                      )}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group m-b-15 ms-1">
                      <input
                        id="inline-sqr-1"
                        type="checkbox"
                        name="isOpenForWork"
                        value={openForWork.value ?? false}
                        checked={openForWork.value ?? false}
                        onChange={(e) =>
                          handleOpenForWorkChange(openForWork, e)
                        }
                        className="checkbox checkbox-dark m-squar mt-3 me-2"
                      />
                      <label className="form-label" htmlFor="inline-sqr-1">
                        Open For Work
                      </label>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-md-12 card-footer text-end">
            <button
              className="btn-sm btn btn-primary"
              onClick={handleEditProfile}
              disabled={saveloading || !updateRequired}
              type="submit"
            >
              {" "}
              {saveloading ? "Updating Profile" : "Update Profile"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default ContactInformation;
