import React, { Fragment, useState } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_EMPLOYER } from "../../api/db.js";
import Breadcrumb from "../common/breadcrumb.js";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import EmployerViewInfo from "./employer-view-info.js";
import EmployerViewJobs from "./employer-view-jobs.js";
import EmployerViewPhotos from "./employer-view-photos.js";

const EmployerView = () => {
  const [activeTab, setActiveTab] = useState("1");
  var { id } = useParams();
  let openings = [];
  let locations = [];
  var socialbg = "/images/social-back-default.jpg";
  var profileurl = "/images/profile-employer.jpg";

  var form = {
    employerId: "",
    locationName: "",
    about: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    lat: "",
    lon: "",
    active: "",
    distance: "",
  };

  const { loading, error, data, refetch } = useQuery(GET_EMPLOYER, {
    variables: { employerId: parseInt(id) },
  });

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );
  if (error) return <p>Error : {error.message}</p>;

  if (data) {
    console.log(data);

    let jobcount = 0;

    var employer = data.employer;
    let locationcount = employer.Locations.length;
    data.employer.Locations.map((location) => {
      locations.push({ location, employer });

      jobcount += location.Openings.length;
      location.Openings.map((job) => {
        openings.push({ location, job, employer });
      });
    });

    form = {
      employerId: employer.EmployerID,
      locationName: employer.EmployerName ?? "",
      about: employer.About ?? "",
      active: employer.Active ?? "",
      locationCount: employer.Locations.length ?? "",
      profile: employer.EmployerImages.length
        ? employer.EmployerImages[0].Src
        : "",
      jobCount: jobcount,
      locationCount: locationcount,
    };

    if (employer.EmployerImages && employer.EmployerImages.length >= 1) {
      socialbg = employer.EmployerImages[0].Src;
    }

    if (form.profile) {
      console.log(form.profile);
      profileurl = form.profile;
    } else {
      profileurl = "/images/profile-employer.jpg";
    }
  }

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="page-header py-0">
          <div class="row">
            <div className="col-12 px-0">
              <div className="page-header-left">
                <Breadcrumb title={form.locationName} parent="Employers" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="user-profile social-app-profile">
          <div className="row">
            {/* <!-- user profile first-style start--> */}
            <div className="col-sm-12">
              <div className="card hovercard text-center mt-0">
                <div
                  className="cardheader socialheader"
                  style={{
                    background: "url(" + socialbg + ")",
                    backgroundPosition: "50% 12%",
                  }}
                ></div>
                <div className="user-image">
                  <div className="avatar">
                    {profileurl && profileurl != "" && (
                      <img alt="user" src={profileurl} />
                    )}
                  </div>
                  <ul className="share-icons"></ul>
                </div>
                <div className="info market-tabs p-0 mt-5">
                  <Nav
                    tabs
                    className="tabs-social border-tab nav-primary market-tabs"
                  >
                    <NavItem
                      className="nav nav-tabs "
                      id="myTab"
                      role="tablist"
                    >
                      <NavLink
                        className={activeTab === "1" ? "active" : ""}
                        onClick={() => setActiveTab("1")}
                      >
                        About
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav nav-tabs" id="myTab" role="tablist">
                      <NavLink
                        className={activeTab === "3" ? "active" : ""}
                        onClick={() => setActiveTab("3")}
                      >
                        Photos
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
            </div>
          </div>
          <TabContent activeTab={activeTab} className="tab-content">
            <TabPane tabId="1">
              <EmployerViewInfo
                form={form}
                locations={locations}
                openings={openings}
                portfolios={data.employer.EmployerImages}
              />
            </TabPane>
            <TabPane tabId="2">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card mt-0">
                      <EmployerViewJobs
                        locationID={form.locationId}
                        jobs={openings}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="3">
              <div className="container-fluid px-0">
                <div className="row">
                  <div className="col-12">
                    <div className="card mt-0">
                      <EmployerViewPhotos
                        portfolios={employer.EmployerImages}
                        locationid={employer.EmployerLocationsID}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </Fragment>
  );
};

export default EmployerView;
