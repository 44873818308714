//images
import user1 from "../assets/images/users/user-1.jpg";
import rclogo from "../assets/images/rc-logo.png";
import dribbble from "../assets/images/brand-logo/dribbble.png";
import insta from "../assets/images/brand-logo/insta.png";
import gclogo from "../assets/images/great_clips_logo.png";
import sclogo from "../assets/images/Supercuts.png";
import hclogo from "../assets/images/hclogo.png";
import bootstrap from "../assets/images/brand-logo/bootstrap.png";
import jquery from "../assets/images/brand-logo/jquery.png";
import { Col, Container, Row } from "react-bootstrap";

const Testimonial = () => {
  return (
    <section className="section bg-light" id="testimonial">
      <Container>
        <Row className="justify-content-center mb-5">
          <Col md={8} lg={6} className="text-center">
            <h6 className="subtitle">
              Our <span className="fw-bold">Testimonial</span>
            </h6>
            <h2 className="title">What Employers are Saying</h2>
            <p className="text-muted">
              See what employers have to say about the beautycareer.com process.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="testimonial-box text-center">
              <p className="text-muted mb-2">
                “BeautyCareer.com is an excellent resource. It directly connects Empire students and Alumni to our open positions across 16 states and multiple salon brands. Our Ratner family of brands include : Hair Cuttery, Bubbles, Salon Cielo and this provides a Fabulous career path. We believe that our unlimited earning potential, Free education and family culture owned by a stylist hold the keys to Success for future professionals."
              </p>
             
             
              <img
                src={rclogo}
                alt=""
          
                width="70"
              />
              <h5 className="fs-18 fw-semibold mt-4 mb-0 text-dark">MJoanne Lindenmuth</h5>
              <p className="text-muted fs-14">V.P of Talent Management & Brand Services</p>
            </div>
          </Col>
        </Row>

        <Row className="mt-md-5">
          <Col sm={6} md={3}>
            <div className="text-center py-3">
              <img src={gclogo} alt="" height="40" />
            </div>
          </Col>
          <Col sm={6} md={3}>
            <div className="text-center py-3">
              <img src={rclogo} alt="" height="40" />
            </div>
          </Col>
          <Col sm={6} md={3}>
            <div className="text-center py-3">
              <img src={sclogo} alt="" height="40" />
            </div>
          </Col>
          <Col sm={6} md={3}>
            <div className="text-center py-3">
              <img src={hclogo} alt="" height="40" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testimonial;
