import React from 'react';

export default class Activity extends React.Component {
constructor(props){
    super(props);
    console.log('hi');
}
render() {
  return(
    <div id='container'>
      <h1>Activity</h1>
    </div>
    );
  }
}