import React, { Fragment, useEffect, useState } from 'react'
import { getContentfulType } from '../api/contentful-client'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import HomePage from '../components/home'

const Home = () => {
  const [post, setPost] = useState(null)
  const [isloading, setIsloading] = useState(false)
  const isLoggedIn = localStorage.getItem('token') != undefined

  useEffect(() => {
    getContentfulType('beautyCareerHomePage').then((res) => {
      console.log(res)
      setPost(
        res.items.find((x) => x.sys.id == '6jWLtoEPEfooNGZ8DctOvm').fields
      )
      setIsloading(true)
    })
  }, [])

  return (
    <Fragment>
      {isloading && (
        <HomePage>
          <div className="data">
            {!isLoggedIn && (
              <a href="/login" className="btn btn-info">
                Login
              </a>
            )}
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: documentToHtmlString(post.content),
                }}
              ></div>
            </div>
          </div>
        </HomePage>
      )}
    </Fragment>
  )
}

export default Home
