
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import logoDark from "../assets/images/bc2-logo.png";
import logoLight from "../assets/images/bc2-logo.png"; 
const Footer = () => {
  const year = new Date().getFullYear().toString();

  return (
    <>
      <section className="footer bg-dark pt-4">
        <Container>
          <Row>
            <Col sm={12} lg={3} className="text-center text-lg-start">
              <div className="footer-logo mb-2">
                <Link to="#">
                  <img src={logoLight} alt="" />
                </Link>
              </div>
              <Link to="mailto:support@beautycareer.com" className="text-white">
                Support@BeautyCareer.com
              </Link>
            

              <h5 className="fs-18 my-3 text-white">Follow Us</h5>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <Link to="#;" className="footer-social-icon">
                    <i className="fa fa-facebook"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#;" className="footer-social-icon">
                    <i className="fa fa-twitter"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#;" className="footer-social-icon">
                    <i className="fa fa-linkedin"></i>
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="footer-tagline">
        <Container>
          <Row className="justify-content-between py-2">
            <Col sm={12} md={6} className="text-center text-md-start">
              <p className="text-white opacity-75 mb-0 fs-14">
                {year} © BeautyCareer.com
              </p>
            </Col>
            <Col sm={12} md={6} className="text-center text-md-end">
              <Link to="#" className="text-white opacity-75 fs-14">
                Terms Conditions & Policy
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
