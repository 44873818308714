import faq from "../assets/images/faq.png";
import { Container, Row, Col, Accordion } from "react-bootstrap";

const Faqs = () => {
  return (
    <section className="section bg-light">
      <Container>
        <Row className="justify-content-center mb-5">
          <Col md={8} lg={6} className="text-center">
            <h6 className="subtitle">
              Our <span className="fw-bold">FAQs</span>
            </h6>
            <h2 className="title">Frequently Asked Questions</h2>
            <p className="text-muted">
              Sed ut perspiciatis unde omnis iste natus error voluptatem
              accusantium doloremque rem aperiam.
            </p>
          </Col>
        </Row>

        <Row className="align-items-center">
          <Col lg={4}>
            <img src={faq} alt="" className="img-fluid d-block mx-auto" />
          </Col>

          <Col lg={7} className="offset-lg-1">
            <Accordion
              defaultActiveKey="0"
              className="accordion-flush faqs-accordion mt-4 mt-lg-0"
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header className="h2">
                  <Accordion.Button className="p-0">
                    How to install App?
                  </Accordion.Button>
                </Accordion.Header>
                <Accordion.Collapse eventKey="" className="show">
                  <Accordion.Body className="text-secondary">
                    Beautycareer.com is available in the App Store and Google play stores. There is also a web version available at beautycareer.com.
                  </Accordion.Body>
                </Accordion.Collapse>
              </Accordion.Item>
              {/* <!-- accordion-header end --> */}

              <Accordion.Item eventKey="1">
                <Accordion.Header className="h2">
                  <Accordion.Button className="p-0">
                    How do I create a stylist account?
                  </Accordion.Button>
                </Accordion.Header>
                <Accordion.Collapse eventKey="" className="show">
                  <Accordion.Body className="text-body">
                  Beautycareer.com is exclusive to students and alumni of Empire Beauty School. You will need your student ID as part of registration to verify your status with the school.
                  </Accordion.Body>
                </Accordion.Collapse>
              </Accordion.Item>
              {/* <!-- accordion-header end --> */}

              <Accordion.Item eventKey="2">
                <Accordion.Header className="h2">
                  <Accordion.Button className="p-0">
                    How do I create job openings as an employer?
                  </Accordion.Button>
                </Accordion.Header>
                <Accordion.Collapse eventKey="" className="show">
                  <Accordion.Body className="accordion-body text-secondary">
                    Create an employer account and follow the steps to create a job opening. You will need to provide your salon name, address, and contact information.
                  </Accordion.Body>
                </Accordion.Collapse>
              </Accordion.Item>
              {/* <!-- accordion-header end --> */}

              <Accordion.Item eventKey="3">
                <Accordion.Header className="h2">
                  <Accordion.Button className="p-0">
                    How much does Beautycareer.com cost?
                  </Accordion.Button>
                </Accordion.Header>
                <Accordion.Collapse eventKey="" className="show">
                  <Accordion.Body className="accordion-body text-secondary">
                    Beautycareer.com is free to use for all students, alumni, and employers.
                  </Accordion.Body>
                </Accordion.Collapse>
              </Accordion.Item>
              {/* <!-- accordion-header end --> */}

              <Accordion.Item eventKey="4">
                <Accordion.Header className="h2">
                  <Accordion.Button className="p-0">
                    How do I apply for jobs on beautycareer.com?
                  </Accordion.Button>
                </Accordion.Header>
                <Accordion.Collapse eventKey="" className="show">
                  <Accordion.Body className="accordion-body text-secondary">
                    Use the job search feature to filter for openings in your area. Once you find a position you are interested in you can apply with one click and attach your resume and portfolio.
                  </Accordion.Body>
                </Accordion.Collapse>
              </Accordion.Item>
              {/* <!-- accordion-header end --> */}

              <Accordion.Item eventKey="5">
                <Accordion.Header className="h2">
                  <Accordion.Button className="p-0">
                    How will employers contact me?
                  </Accordion.Button>
                </Accordion.Header>
                <Accordion.Collapse eventKey="" className="show">
                  <Accordion.Body className="accordion-body text-secondary">
                    Beautycareer.com uses a direct messages feature, you will be notified over email when an employer sends you a message.
                  </Accordion.Body>
                </Accordion.Collapse>
              </Accordion.Item>
              {/* <!-- accordion-header end --> */}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Faqs;
