import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  ADD_EMPLOYER_LOCATION_IMAGE,
  DELETE_EMPLOYER_IMAGE,
} from '../../api/db'
import { GetUserID } from '../../api/session'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const LocationPortfolio = ({ portfolios, locationid, refreshCallback }) => {
  const [modal6, setModal6] = useState()
  const [imageFile, setImageFile] = useState()
  const [imageCaption, setImageCaption] = useState()

  const toggleUpload = () => {
    setModal6(!modal6)
  }

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0])
  }

  const handleImageCaption = (e) => {
    setImageCaption(e.target.value)
  }

  const handleImageSubmit = async () => {
    console.log('Submitting Images')
    console.log(imageFile)
    console.log(imageCaption)

    var imageUrl = ''
    const formData = new FormData()

    formData.append('file', imageFile)

    fetch('https://bc.empire-dev.com/api/upload', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.text())
      .then(async (result) => {
        console.log('Success:', result)
        imageUrl = result
        var userid = GetUserID()
        console.log(userid)
        try {
          await addPortfolio({
            variables: {
              employerId: parseInt(userid),
              employerLocationsId: parseInt(locationid),
              src: imageUrl,
              caption: imageCaption,
            },
          })

          refreshCallback()
        } catch (err) {
          console.log(err.message)
        }
      })
      .catch((error) => {
        console.error('Error:', error)
      })

    if (imageUrl != '') {
      console.log(imageUrl)
    }

    setModal6(!modal6)
  }

  const [deletePortfolio] = useMutation(DELETE_EMPLOYER_IMAGE, {})

  const [addPortfolio] = useMutation(ADD_EMPLOYER_LOCATION_IMAGE, {})

  const handleDeletePortfolio = async (employerImageId) => {
    console.log(employerImageId)

    try {
      if (employerImageId) {
        await deletePortfolio({ variables: { employerImagesId: employerImageId } })

        toast.message("")
        refreshCallback()
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="col-lg-7">
      <div className="card">
        <div className="card-header d-flex">
          <h4 className="card-title me-4 align-self-end">Portfolio</h4>
          <div className="card-options">
            <a
              className="card-options-collapse"
              href="javascript"
              data-toggle="card-collapse"
            >
              <i className="fe fe-chevron-up"></i>
            </a>
            <a
              className="card-options-remove"
              href="javascript"
              data-toggle="card-remove"
            >
              <i className="fe fe-x"></i>
            </a>
          </div>

          <Button color="success" onClick={toggleUpload}>
            {'Add Image'}
          </Button>
          <Modal isOpen={modal6} toggle={toggleUpload}>
            <ModalHeader toggle={toggleUpload}>Add Image</ModalHeader>
            <ModalBody>
              <form>
                <div className="form-group">
                  <label className="col-form-label" htmlFor="message-text">
                    Image:
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    onChange={(e) => handleImageChange(e)}
                  />
                </div>
                <div className="form-group">
                  <label className="col-form-label" htmlFor="recipient-name">
                    Caption:
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    onChange={(e) => handleImageCaption(e)}
                    placeholder="Image Description"
                  />
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => handleImageSubmit(e)}>
                Add Image
              </Button>
              <Button color="secondary" onClick={(e) => toggleUpload(e)}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        <div className="table-responsive col-12">
          <table className="table card-table table-vcenter text-nowrap">
            <thead>
              <tr>
                <th>Image</th>
                <th>Caption</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {portfolios.map((items, i) => (
                <tr key={i}>
                  <td>
                    <a
                      className="text-inherit"
                      href={items.Src + '?w=850&amp;h=850'}
                    >
                      <img
                        className="img-thumbnail"
                        width={75}
                        height={75}
                        src={
                          items.Src +
                          '?w=150&amp;h=150&amp;mode=crop&amp;anchor=topcenter'
                        }
                        loading="lazy"
                        alt="portfolio"
                      />{' '}
                    </a>
                  </td>
                  <td className="portfolio-caption">{items.Caption}</td>
                  <td className="text-end">
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={(e) => {
                        handleDeletePortfolio(items.EmployerImagesID)
                      }}
                    >
                      <i className="fa fa-trash"></i> {'Delete'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default LocationPortfolio
