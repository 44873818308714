import React from "react";
import { useQuery } from "@apollo/client";
import { GET_STUDENT_DETAILS, GET_USER_INFO } from "../api/db.js";
import { GetUserID } from "../api/session.js";
import StudentSearch from "../components/search-students/student-search";
import { errorCheck } from "../components/common/functions.js";

const Students = () => {
  const { loading, error, data } = useQuery(GET_USER_INFO, {
    variables: { userId: parseInt(GetUserID()) },
  });

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );

  if (error) {
    return errorCheck(error);
  }

  console.log(data);

  return <StudentSearch query={" "} />;
};

export default Students;
