import React, { Fragment, useState } from "react";
import { Search, MapPin } from "react-feather";
import { Collapse } from "reactstrap";

const SchoolFilter = ({
  schools,
  cities,
  schoolfilter,
  locationfilter,
  cityfilter,
  textfilter,
}) => {
  const [isFilter, setIsFilter] = useState(true);
  const [location, setLocation] = useState(false);
  const [isSchoolTitle, setisSchoolTitle] = useState(false);

  let locationtext = locationfilter.value;
  const onChangeSchool = (e) => {
    let arr = schoolfilter.value;
    if (arr.includes(e.target.value)) {
      schoolfilter.setValue(arr.filter((item) => item !== e.target.value));
    } else {
      schoolfilter.setValue([...arr, e.target.value]);
    }

    console.log(schoolfilter.value);
  };

  const onChangeCities = (e) => {
    let arr = cityfilter.value;
    if (arr.includes(e.target.value)) {
      cityfilter.setValue(arr.filter((item) => item !== e.target.value));
    } else {
      cityfilter.setValue([...arr, e.target.value]);
    }

    console.log(cityfilter.value);
  };

  const clearCities = () => {
    cityfilter.setValue([]);
  };

  const clearSchool = () => {
    schoolfilter.setValue([]);
  };

  const onChangeLocation = (e) => {
    let arr = e.target.value;
    if (arr == "") arr = undefined;
    locationfilter.setValue(arr);
  };

  const searchClick = (e) => {
    setIsFilter(!isFilter);
    locationfilter.setValue(locationfilter.value);
  };
  return (
    <Fragment>
      <div className="col-xl-3 xl-40">
        <div
          className="default-according style-1 faq-accordion job-accordion"
          id="accordionoc"
        >
          <div className="row">
            <div className="col-xl-12">
              <div className="card mt-0">
                <div className="card-header">
                  <h5 className="mb-0">
                    <button
                      className="btn btn-link ps-0 border-0"
                      data-toggle="collapse"
                      onClick={(e) => searchClick()}
                      data-target="#collapseicon"
                      aria-expanded={isFilter}
                      aria-controls="collapseicon"
                    >
                      Filters
                    </button>
                  </h5>
                </div>
                <Collapse isOpen={isFilter}>
                  <div className="card-body filter-cards-view animate-chk">
                    <div className="job-filter">
                      <div className="faq-form">
                        <input
                          className="form-control"
                          type="text"
                          onChange={(e) => textfilter.onChange(e)}
                          placeholder="Search.."
                        />
                        <Search className="search-icon" />
                      </div>
                    </div>
                    <div className="job-filter">
                      <div className="faq-form">
                        <input
                          className="form-control"
                          type="text"
                          onChange={(e) => onChangeLocation(e)}
                          value={locationtext}
                          placeholder="location.."
                        />
                        <MapPin className="search-icon" />
                      </div>
                    </div>
                    <div className="checkbox-animated"></div>
                    <button
                      className="btn btn-primary text-center"
                      type="button"
                    >
                      Find Schools
                    </button>
                  </div>
                </Collapse>
              </div>
            </div>

            <div className="col-xl-12">
              {cities && cities.length > 0 && (
                <div className="card mt-0">
                  <div className="card-header">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link ps-0 border-0"
                        data-toggle="collapse"
                        onClick={() => setLocation(!location)}
                        data-target="#collapseicon1"
                        aria-expanded={location}
                        aria-controls="collapseicon1"
                      >
                        States
                      </button>
                    </h5>
                  </div>
                  <Collapse isOpen={location}>
                    <div className="card-body animate-chk">
                      <div className="location-checkbox">
                        {cities.map((city, i) => {
                          return (
                            <label
                              key={"chk-ani6" + i}
                              className="d-block"
                              htmlFor={"chk-ani6" + i}
                            >
                              <input
                                onChange={(e) => onChangeCities(e)}
                                className="checkbox_animated"
                                checked={cityfilter.value.includes(city)}
                                value={city}
                                id={"chk-ani6" + i}
                                type="checkbox"
                              />
                              {city}
                            </label>
                          );
                        })}
                      </div>
                    </div>
                    <button
                      onClick={(e) => clearCities()}
                      className="btn btn-block btn-primary text-center"
                      type="button"
                    >
                      All States
                    </button>
                  </Collapse>
                </div>
              )}
            </div>
            <div className="col-xl-12"></div>
            <div className="col-xl-12"></div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SchoolFilter;
