import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { CONVERSATIONS, MARK_CONVERSATION_READ } from "../api/db";
import Conversation from "../components/conversations/conversation";
import ConversationDetail from "../components/conversations/conversation-detail";
import Breadcrumb from "../components/common/breadcrumb";
import useRefresh from "../hooks/refresh";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ArrowLeft } from "react-feather";
import ConversationMobile from "../components/conversations/conversation-mobile";
import { errorCheck } from "../components/common/functions";

const Messages = () => {
  const [mobile, setMobile] = useState(window.innerWidth <= 500);
  let { conversation } = useParams();
  const [currentConsersation, setActiveConservation] = useState("");
  const [lastMessage, setActiveMessage] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  };

  useEffect(() => {
    var chatbox = document.querySelector(".chat-message");
    console.log(chatbox);
    if (chatbox) {
      chatbox.scrollIntoView({ behavior: "smooth", block: "end" });
    }

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [currentConsersation]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [markReadMessagesMutation, { error: saveerror }] = useMutation(
    MARK_CONVERSATION_READ,
    {}
  );

  const setConversation = (conversationid) => {
    setActiveConservation(conversationid);
    markReadMessagesMutation({
      variables: { conversationId: conversationid, isRead: true },
    });

    if (saveerror) console.log(saveerror);

    if (mobile && show) {
      handleClose();
    }
  };

  const setCurrentMessage = (message) => {
    if (lastMessage != message && currentConsersation) {
      setActiveMessage(message);
      markReadMessagesMutation({
        variables: { conversationId: currentConsersation, isRead: true },
      });

      if (saveerror) console.log(saveerror);
    }
  };

  const setMessagesRead = () => {
    if (currentConsersation)
      markReadMessagesMutation({
        variables: { conversationId: currentConsersation, isRead: true },
      });
  };

  const { loading, error, data, refetch } = useQuery(CONVERSATIONS, {
    pollInterval: 3000,
  });

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );

  if (error) {
    return errorCheck(error);
  }

  if (saveerror) return errorCheck(saveerror);

  const loadConversation =
    currentConsersation != undefined &&
    currentConsersation != "" &&
    currentConsersation != false;

  if (!loadConversation) {
    if (data.getConversations[0]) {
      setActiveConservation(
        conversation ?? data.getConversations[0].ConversationID
      );
      markReadMessagesMutation({
        variables: {
          conversationId:
            conversation ?? data.getConversations[0].ConversationID,
          isRead: true,
        },
      });
    }
  }

  const handleSearchKeyword = (keyword) => {
    setSearchKeyword(keyword);
  };

  return (
    <div>
      <Breadcrumb title="Messages" />
      <div className="container-fluid">
        <div className="row">
          <div className="col call-chat-sidebar col-sm-12">
            {!mobile && (
              <div className="card mt-0">
                <div className="card-body chat-body">
                  <div className="chat-box">
                    <div className="chat-left-aside">
                      <div
                        className="people-list custom-scrollbar"
                        id="people-list"
                      >
                        <div className="search">
                          <form className="theme-form">
                            <div className="form-group">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="search"
                                defaultValue={searchKeyword}
                                onChange={(e) =>
                                  handleSearchKeyword(e.target.value)
                                }
                              />
                              <i className="fa fa-search"></i>
                            </div>
                          </form>
                        </div>
                        <ul className="list">
                          {data.getConversations.map((conversationItem) => {
                            return (
                              <Conversation
                                key={conversationItem.ConversationID}
                                isActive={
                                  currentConsersation ===
                                  conversationItem.ConversationID
                                }
                                conversation={conversationItem}
                                onPress={setConversation}
                                onRead={setCurrentMessage}
                              />
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {mobile && (
              <>
                <Button
                  id="btn-mobile-conversations"
                  variant="primary"
                  className="mb-2"
                  onClick={handleShow}
                >
                  Conversations
                </Button>

                <Offcanvas
                  restoreFocus={false}
                  show={show}
                  onHide={handleClose}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Conversations</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <div className="card mt-0">
                      <div className="card-body chat-body">
                        <div className="chat-box">
                          <div className="chat-left-aside">
                            <div
                              className="people-list custom-scrollbar"
                              id="people-list"
                            >
                              <div className="search">
                                <form className="theme-form">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      type="text"
                                      placeholder="search"
                                      defaultValue={searchKeyword}
                                      onChange={(e) =>
                                        handleSearchKeyword(e.target.value)
                                      }
                                    />
                                    <i className="fa fa-search"></i>
                                  </div>
                                </form>
                              </div>
                              <ul className="list">
                                {data.getConversations.map(
                                  (conversationItem) => {
                                    return (
                                      <ConversationMobile
                                        key={conversationItem.ConversationID}
                                        isActive={
                                          currentConsersation ===
                                          conversationItem.ConversationID
                                        }
                                        conversation={conversationItem}
                                        onPress={setConversation}
                                        onRead={setCurrentMessage}
                                      />
                                    );
                                  }
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Offcanvas.Body>
                </Offcanvas>
              </>
            )}
          </div>

          <div className="col call-chat-body" style={{ position: "static" }}>
            {loadConversation && (
              <ConversationDetail
                key={currentConsersation}
                conversationid={currentConsersation}
                onRead={setMessagesRead}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Messages;
