import { Col, Row, Container } from "react-bootstrap";
import features2 from "../assets/images/features-2.png";

const Features2 = () => {
  return (
    <section className="section bg-light features-bg">
      <Container>
        <Row className="align-items-center">
          <Col lg={5}>
            <h1 className="title mb-4">Connecting stylists & salons</h1>
            <p className="text-muted">
              Offering streamlined job search and direct messaging between salons and stylists.
            </p>

            <div className="d-flex mb-3">
              <div className="flex-shrink-0">
                <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                  <i className="fa fa-thumbs-up fs-5"></i>
                </span>
              </div>
              <div className="flex-grow-1 ms-4">
                <p className="text-muted">
                  <span className="text-dark fw-bold">
                    One click job applications:
                  </span>{" "}
                  Applying for jobs has never been easier, with our one click application process.
                </p>
              </div>
            </div>
            <div className="d-flex mb-3">
              <div className="flex-shrink-0">
                <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                <i class="fa fa-comment"></i>
                </span>
              </div>
              <div className="flex-grow-1 ms-4">
                <p className="text-muted">
              
                  <span className="text-dark fw-bold">
                  Direct messaging:
                  </span>{" "}
                  Stylists and employers can direct message eachother to coordinate interviews or ask questions about openings.
                </p>
              </div>
            </div>

            <div className="d-flex mb-3">
              <div className="flex-shrink-0">
                <span className="avatar avatar-lg bg-white text-primary rounded-circle shadow-primary">
                <i class="fa fa-check fs-4"></i>
                </span>
              </div>
              <div className="flex-grow-1 ms-4">
                <p className="text-muted">
                  <span className="text-dark fw-bold">
                    Streamlined search
                  </span>{" "}
                
                  Our fast and filterable job search engine allows stylists to find the perfect job for them.
                </p>
              </div>
            </div>
          </Col>

          <Col lg={5} className="offset-lg-1">
            <img
              src={features2}
              alt=""
              className="img-fluid d-block mx-auto ms-lg-auto"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Features2;
