import React, { Fragment, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  GET_STUDENT_DETAILS,
  GET_STUDENT_DETAILS_BY_ID,
  SEND_MESSAGE,
  CONVERSATIONS,
} from "../../api/db.js";
import {
  GetUser,
  GetUserID,
  GetUserInfo,
  GetUserRole,
} from "../../api/session.js";
import {
  TabContent,
  TabPane,
  Nav,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { Search, Edit } from "react-feather";
import { toast } from "react-toastify";
import PortfolioGallery from "./profile-photos.js";
import ProfilePhotos from "./profile-photo-gallery.js";
import useInput from "../../hooks/useInput.js";
import ProfileApplications from "./profile-applications.js";
import { errorCheck } from "../common/functions.js";

function Profile() {
  const [activeTab, setActiveTab] = useState("1");
  var { id } = useParams();
  var socialbg = "/images/social-back-default.jpg";
  const message = useInput("");
  const messageUserId = useInput("");
  const messageUser = useInput("");
  const [messagemodal, setMessageModal] = useState();
  const userinfo = GetUser();
  console.log(userinfo);

  var form = {
    userid: "",
    firstName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    profileurl: "",
  };

  console.log(id);

  let variableInput;
  let variableQuery;
  if (id) {
    variableQuery = GET_STUDENT_DETAILS_BY_ID;
    variableInput = { variables: { studentId: parseInt(id) } };
  } else {
    variableQuery = GET_STUDENT_DETAILS;
    variableInput = { variables: { userId: parseInt(GetUserID()) } };
  }

  const { loading, error, data, refetch } = useQuery(
    variableQuery,
    variableInput
  );

  const [newMessageMutation, { data: savedata }] = useMutation(SEND_MESSAGE, {
    refetchQueries: [{ query: CONVERSATIONS, variables: {} }],
  });

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );

  if (error) {
    return errorCheck(error);
  }

  const handleNewMessage = async (e) => {
    const userIds = [];
    userIds.push(parseInt(messageUserId.value));
    console.log(messageUserId);
    try {
      await newMessageMutation({
        variables: { toUserIDs: userIds, message: message.value },
      });
      message.setValue("");
    } catch (err) {
      console.log(err);
    }
    setMessageModal(!messagemodal);

    toast("Message Sent", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const toggleMessageModal = (e, userId, name) => {
    setMessageModal(!messagemodal);
    console.log("send message:");
    console.log(userId);
    messageUserId.setValue(userId);
    messageUser.setValue(name);

    message.setValue(`Message from: 
        ${userinfo.FirstName} ${userinfo.LastName}
        ${userinfo.City ?? ""} ${userinfo.State ?? ""}
        `);
  };

  if (data) {
    console.log(data);
    form = {
      userid: data.student.UserID,
      firstName: data.student.FirstName ?? "",
      lastName: data.student.LastName ?? "",
      address1: data.student.Address1 ?? "",
      address2: data.student.Address2 ?? "",
      city: data.student.City ?? "",
      state: data.student.State,
      zip: data.student.Zipcode ?? "",
      phone: data.student.Phone ?? "",
      email: data.student.Email ?? "",
      password: "",
      profileurl: "",
      bannerurl: "",
    };

    var profile = data.student.ProfileImage;
    var banner = data.student.BannerImage;

    if (profile && profile != undefined) {
      form.profileurl = profile;
    } else {
      form.profileurl = "/images/profile-student.jpg";
    }

    if (banner && banner != undefined) {
      form.bannerurl = banner;
    } else {
      form.bannerurl = "/images/social-back-default.jpg";
    }

    if (data.student.Portfolios && data.student.Portfolios.length >= 1) {
      socialbg = data.student.Portfolios[0].Src;
    }
  }
  console.log(GetUserRole());
  return (
    <Fragment>
      <div className="container-fluid pt-30">
        <div className="user-profile social-app-profile">
          <div className="row">
            {/* <!-- user profile first-style start--> */}
            <div className="col-sm-12">
              <div className="card hovercard text-center mt-0 pb-3">
                <div
                  className="cardheader socialheader"
                  style={{
                    background: "url(" + form.bannerurl + ")",
                    backgroundPosition: "50% 12%",
                  }}
                ></div>
                <div className="user-image">
                  <div className="avatar">
                    <img alt="user" src={form.profileurl} />
                  </div>
                  <ul className="share-icons">
                    {userinfo.UserID == form.userid && (
                      <li className="align-center">
                        <a
                          className="btn btn-md btn-primary pt-1 px-1"
                          href="/settings/profile"
                        >
                          <Edit className="search-button text-white pt-2" />
                          <span className="mt-0">EDIT PROFILE</span>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="info market-tabs p-0">
                  <Nav tabs className="tabs-social nav-primary market-tabs">
                    <li className="nav-item">
                      <div className="user-designation"></div>
                      <div className="title mt-4">
                        <a href="#javascript">
                          {form.firstName} {form.lastName}
                        </a>
                      </div>
                      <div className="desc mt-2">
                        {form.city}, {form.state}
                      </div>
                    </li>
                  </Nav>
                </div>
              </div>
            </div>
          </div>
          <TabContent activeTab={activeTab} className="tab-content">
            <TabPane tabId="1">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="card mt-0">
                    <div className="card-header">
                      <h4 className="card-title me-4 align-self-end">
                        About Me
                      </h4>
                    </div>
                    <div className="card-body avatar-showcase">
                      <div className="pepole-knows text-start pb-2">
                        <p>
                          {form.firstName} {form.lastName}
                        </p>
                        <p>{form.Address1}</p>
                        <p>{form.Address1}</p>
                        <p>
                          {form.city} {form.state} {form.zip}
                        </p>
                        <p>{form.phone}</p>
                        <p>{form.email}</p>
                      </div>
                      {GetUserRole() == "employer" && (
                        <button
                          className="btn btn-secondary"
                          type="button"
                          onClick={(e) =>
                            toggleMessageModal(
                              e,
                              userinfo.UserID,
                              userinfo.FirstName + "" + userinfo.LastName
                            )
                          }
                        >
                          {"Send a Message"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <ProfilePhotos
                  form={form}
                  portfolios={data.student.Portfolios}
                />
                {userinfo.UserID == form.userid &&
                  GetUserRole() == "student" && (
                    <ProfileApplications id={form.userid} />
                  )}
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <PortfolioGallery portfolios={data.student.Portfolios} />
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
      <Modal isOpen={messagemodal} toggle={toggleMessageModal}>
        <ModalHeader toggle={toggleMessageModal}>
          Send {form.firstName} {form.lastName} a Message
        </ModalHeader>
        <ModalBody>
          <form>
            <textarea
              autoFocus={true}
              value={message.value}
              onChange={message.onChange}
              className="form-control"
              rows="5"
              col="5"
            ></textarea>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleNewMessage("send")}>
            Send
          </Button>
          <Button color="secondary" onClick={(e) => toggleMessageModal(e)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}
export default Profile;
