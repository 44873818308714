import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_EMPLOYER_LOCATION } from "../../api/db.js";
import Breadcrumb from "../common/breadcrumb.js";
import LocationInfo from "./location-info.js";
import JobManager from "./jobs/location-jobs.js";
import LocationPortfolio from "./location-portfolio.js";
import { errorCheck } from "../common/functions.js";

const Location = () => {
  var { id } = useParams();

  var form = {
    employerId: "",
    locationName: "",
    about: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    lat: "",
    lon: "",
    active: "",
    distance: "",
  };

  const { loading, error, data, refetch } = useQuery(GET_EMPLOYER_LOCATION, {
    variables: { employerLocationsId: parseInt(id) },
  });

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );

  if (error) {
    return errorCheck(error);
  }

  if (data) {
    console.log(data);

    var location = data.employerLocation;
    form = {
      employerId: location.EmployerID,
      locationId: location.EmployerLocationsID,
      locationName: location.LocationName ?? "",
      about: location.About ?? "",
      address1: location.Address1 ?? "",
      address2: location.Address2 ?? "",
      city: location.City ?? "",
      state: location.State,
      zip: location.Zipcode ?? "",
      phone: location.Phone ?? "",
      lat: location.Lat ?? "",
      lon: location.Lon ?? "",
      active: location.Active ?? "",
      distance: location.Distance ?? "",
      profile: location.ProfileImage,
      banner: location.BannerImage,
      videourl: location.VideoURL ?? "",
    };
  }

  return (
    <Fragment>
      <Breadcrumb parent="Beauty Career" title="Edit Location" />
      <div className="container-fluid">
        <div className="edit-profile">
          <div className="row">
            <LocationInfo form={form} onSave={refetch} />
            <JobManager
              locationID={form.locationId}
              jobs={location.Openings}
              refreshCallback={refetch}
            />
            <LocationPortfolio
              portfolios={location.EmployerImages}
              locationid={location.EmployerLocationsID}
              refreshCallback={refetch}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Location;
