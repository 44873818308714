import React, { useState } from "react";
import ConversationUserIcon from "./conversation-user-icon.js";
import { useQuery, gql } from "@apollo/client";
import { GET_USER_INFO } from "../../api/db.js";
import { GetUserID } from "../../api/session.js";

const MessageUserIcon = ({ user, floattype, conversationid }) => {
  var userId = GetUserID();
  const { loading, error, data } = useQuery(GET_USER_INFO, {
    variables: { userId: userId },
  });

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );
  if (error) return <p>Error : {error.message}</p>;

  if (user.ProfileImage) {
    return (
      <>
        <img
          src={user.ProfileImage}
          className={`rounded-circle ${floattype} chat-user-img img-30`}
          alt=""
        />
      </>
    );
  } else {
    return (
      <>
        <img
          src={"/images/profile-student.jpg"}
          className={`rounded-circle ${floattype} chat-user-img img-30`}
          alt=""
        />
      </>
    );
  }
};
export default MessageUserIcon;
