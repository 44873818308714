import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import "../../../styles/resume-form.scss";
import CKEditors from "react-ckeditor-component";
import { Alert } from "reactstrap";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { css } from "styled-components";

const ResumeBuilderSettings = ({
  settings,
  onSettingsSave,
  step,
  onChangeStep,
}) => {
  var validator = useRef(new SimpleReactValidator({ autoForceUpdate: this }));
  const [, forceUpdate] = useState();
  console.log(settings.isPrimary);
  const [showAlert, setShowAlert] = useState(false);
  const [isPrimary, setPrimary] = useState(settings.isPrimary ?? false);
  const [title, setTitle] = useState(settings.title ?? "");
  const [careerType, setCareerType] = useState(settings.careerType ?? "");
  const [objective, setObjective] = useState(settings.objective ?? "");
  const [color, setColor] = useState("1");
  const [colorValue, setColorValue] = useState(settings.colorValue ?? "blue");
  const [style, setStyle] = useState(settings.style ?? "");
  const [error, setError] = useState("");

  const ontitleChange = (e) => {
    console.log(e.target.value);
    setTitle(e.target.value);
  };

  console.log(isPrimary);

  const handleColorChange = (value) => {
    setColor(value);

    if (value == "1") setColorValue("blue");

    if (value == "2") setColorValue("green");

    if (value == "3") setColorValue("pink");
  };

  const handleStyleChange = (value) => {
    setStyle(value);
  };

  const onobjectiveChange = (e) => {
    setObjective(e.target.value);

    if (e.target.value == "1") setColorValue("blue");

    if (e.target.value == "2") setColorValue("green");

    if (e.target.value == "3") setColorValue("pink");
  };

  const onChange = (evt) => {
    const newContent = evt.editor.getData();

    setObjective(newContent);
  };

  const oncolorChange = (e) => {
    console.log(e);
    setColor(e.target.value);
  };

  const onstyleChange = (e) => {
    setStyle(e.target.value);
  };

  const oncareerTyleChange = (e) => {
    setCareerType(e.target.value);
  };

  const onprimaryChange = (e) => {
    console.log(!isPrimary);

    setPrimary(!isPrimary);
  };

  const handleResumeSave = () => {
    if (isValidated()) {
      var save = {
        isPrimary: isPrimary,
        title: title,
        careerType: careerType,
        objective: objective,
        color: color,
        style: style,
      };

      onSettingsSave(save);
      onChangeStep(step.value + 1);
    }
  };

  const isValidated = () => {
    console.log("validate");

    if (!validator.current.allValid()) {
      validator.current.showMessages();
      let err = "";
      setShowAlert(true);

      err = "Resume Fields not valid please fix all errors to proceed";

      setError(err);
      forceUpdate(1);

      toast.error("Cannot Save Resume. Please check all field");

      return false;
    }

    setShowAlert(false);

    return true;
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12">
          <form id="formResumeOptions" className="pt-0">
            {error && (
              <tr>
                <td colSpan={11}>
                  <Alert color="danger">
                    <ul>
                      {error.split(",").map((x) => {
                        return <li>{x}</li>;
                      })}
                    </ul>
                  </Alert>
                </td>
              </tr>
            )}

            <div className="form-group mb-4 col-sm-11 col-md-11 col-lg-11">
              <label className="form-label left edit">Resumé Title:</label>
              <input
                id="resume_title"
                className="form-control field"
                placeholder="Enter a short title."
                name="resume_title"
                onChange={(e) => ontitleChange(e)}
                value={title}
                required
              />
              {validator.current.message("title", title, "required")}
            </div>
            <div className="form-group mb-4 col-md-11">
              <div>
                <label className="form-label left edit me-2">
                  MakePrimary:
                </label>
                <input
                  type="checkbox"
                  name="isPrimary"
                  value={isPrimary}
                  className="checkbox checkbox-primary"
                  checked={isPrimary}
                  onChange={(e) => onprimaryChange(e)}
                />
              </div>
            </div>
            <div className="form-group mb-4 col-md-11">
              <label className="form-label">Career Type:</label>
              <div className="drop_line resume">
                <span className="drop">
                  <select
                    className="form-control"
                    required
                    name="career"
                    id="career"
                    value={careerType}
                    onChange={(e) => oncareerTyleChange(e)}
                  >
                    <option value="">Select Career Type</option>
                    <option value="1">Cosmetologist</option>
                    <option value="6">Makeup Artist</option>
                    <option value="8">Hairstylist</option>
                    <option value="11">Assistant/Apprentice</option>
                    <option value="12">Barber</option>
                    <option value="13">Braider</option>
                    <option value="14">Colorist</option>
                    <option value="15">Educator-Schools</option>
                    <option value="16">Educator-Salons</option>
                    <option value="17">Education Management</option>
                    <option value="18">Esthetician/Skin Care Specialist</option>
                    <option value="21">Nail Technician/Manicurist</option>
                    <option value="22">Product Specialist</option>
                    <option value="23">Receptionist</option>
                    <option value="24">Sales Consultant</option>
                    <option value="25">Salon Management</option>
                    <option value="26">Spa Management</option>
                    <option value="27">Esthetician/Cosmetologist</option>
                  </select>
                  {validator.current.message(
                    "career type",
                    careerType,
                    "required"
                  )}
                </span>
              </div>
            </div>
            <div className="form-group mb-4 col-md-11">
              <label className="form-label">Objective:</label>
              <CKEditors
                activeclassName="p10"
                content={objective}
                events={{
                  change: onChange,
                }}
              />
              {validator.current.message("objective", objective, "required")}
            </div>
            {/* <div className="form-group mb-4 color-select col-md-11">
          <label className="form-label">Color:</label>
          <div className='row'>               
                <div className="col-2 radio-select"><div className="radio radio-primary"><input type="radio" className="form-control radio iradio_line-orange" onChange={(e) =>oncolorChange(e)} name="iCheckColor" value="1" rel="blue" checked={color == "1"} /><div className="icheck_line-icon"></div><img src="/images/resume/resume-blue.jpg" onClick={() => handleColorChange("1")}  className={ color == "1" ? "resume-image resume-style-color iradio_line-orange checked" : "resume-image resume-style-color iradio_line-orange"} rel="blue"/></div></div>
                <div className="col-2 radio-select"><div className="radio radio-primary"><input type="radio" className="form-control radio iradio_line-orange" onChange={(e) =>oncolorChange(e)} name="iCheckColor" value="2" rel="green" checked={color == "2"} /><div className="icheck_line-icon"></div><img src="/images/resume/resume-green.jpg"  onClick={() => handleColorChange("2")} className={ color == "2" ? "resume-image resume-style-color iradio_line-orange checked" : "resume-image resume-style-color iradio_line-orange"}rel="green"/></div></div>
                <div className="col-2 radio-select"><div className="radio radio-primary"><input type="radio" className="form-control radio iradio_line-orange" onChange={(e) =>oncolorChange(e)} name="iCheckColor" value="3" rel="pink" checked={color == "3"} /><div className="icheck_line-icon"></div><img src="/images/resume/resume-pink.jpg" onClick={() => handleColorChange("3")} className={ color == "3" ? "resume-image resume-style-color iradio_line-orange checked" : "resume-image resume-style-color iradio_line-orange"} rel="pink"/></div></div>
          </div>
        </div> */}
            <div className="form-group resume-color-preview mb-4 col-md-11">
              <label className="form-label">Style:</label>
              <div className="row">
                <div className="col-sm-4 radio-select">
                  <div className="radio radio-primary">
                    <input
                      type="radio"
                      onChange={(e) => onstyleChange(e)}
                      name="iCheck"
                      className="radio"
                      value="1"
                      checked={style == "1"}
                    />
                    <div className="icheck_line-icon"></div>
                    <img
                      src={"/images/resume/resume1-" + colorValue + ".jpg"}
                      onClick={() => handleStyleChange("1")}
                      className={
                        style == "1"
                          ? "resume-image resume-style-color iradio_line-orange checked"
                          : "resume-image resume-style iradio_line-orange"
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-4 radio-select">
                  <div className="radio radio-primary">
                    <input
                      type="radio"
                      onChange={(e) => onstyleChange(e)}
                      name="iCheck"
                      className="radio"
                      value="2"
                      checked={style == "2"}
                    />
                    <div className="icheck_line-icon"></div>
                    <img
                      src={"/images/resume/resume2-" + colorValue + ".jpg"}
                      onClick={() => handleStyleChange("2")}
                      className={
                        style == "2"
                          ? "resume-image resume-style-color iradio_line-orange checked"
                          : "resume-image resume-style iradio_line-orange"
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-4 radio-select">
                  <div className="radio radio-primary">
                    <input
                      type="radio"
                      onChange={(e) => onstyleChange(e)}
                      name="iCheck"
                      className="radio"
                      value="3"
                      checked={style == "3"}
                    />
                    <div className="icheck_line-icon"></div>
                    <img
                      src={"/images/resume/resume3-" + colorValue + ".jpg"}
                      onClick={() => handleStyleChange("3")}
                      className={
                        style == "3"
                          ? "resume-image resume-style-color iradio_line-orange checked"
                          : "resume-image resume-style iradio_line-orange"
                      }
                    />
                  </div>
                </div>
                {validator.current.message("style", style, "required")}
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="footer-buttons">
        <a
          class="btn btn-next btn-info btn-lg pull-left"
          id="prev-button"
          href="/settings/profile"
        >
          Back To Profile
        </a>
        <button
          type="button"
          class="btn btn-prev btn-primary btn-lg pull-right"
          id="next-button"
          onClick={() => handleResumeSave()}
        >
          Next
        </button>
      </div>
    </>
  );
};
export default ResumeBuilderSettings;
