import React from 'react'

const ConversationUserIcon = ({ user, unreadcount }) => {
  if (user.ProfileImage) {
    return (
      <>
        <img
          src={user.ProfileImage}
          className="rounded-circle user-image"
          alt=""
        />
      </>
    )
  } else {
    return (
      <>
        <img
          src={'/images/profile-student.jpg'}
          className="rounded-circle user-image"
          alt=""
        />
      </>
    )
  }
}

export default ConversationUserIcon
