import React, { Fragment } from "react";
import PortfolioGallery from "./profile-photos";

const ProfilePhotos = ({ form, portfolios }) => {
  return (
    <Fragment>
      {portfolios && portfolios.length >= 1 && (
        <div className="col-sm-12 col-md-6 mb-3">
          <div className="card mt-0">
            <div className="card-header">
              <h4 className="card-title me-4 align-self-end">Photos</h4>
            </div>
            <div className="card-body avatar-showcase">
              <div
                className="default-according style-1 faq-accordion job-accordion"
                id="accordionoc3"
              >
                <PortfolioGallery portfolios={portfolios} />
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ProfilePhotos;
