import {
  Home,
  Users,
  Mail,
  HelpCircle,
  Search,
  Clock,
  Scissors,
  MapPin,
  Clipboard,
  User,
  BarChart,
} from "react-feather";

export const MENUITEMS = [
  {
    title: "Home",
    icon: Home,
    path: "/",
    type: "link",
    active: false,
  },
  {
    title: "Profile",
    icon: Users,
    path: "/settings/profile",
    type: "link",
    active: false,
  },
  {
    title: "Messages",
    icon: Mail,
    path: "/messages",
    type: "link",
    active: false,
  },
  {
    title: "Jobs",
    icon: Search,
    path: "/jobs",
    type: "link",
    active: false,
  },
];

export const EMPLOYERMENUITEMS = [
  {
    title: "Careers",
    icon: Scissors,
    path: "/careers",
    type: "link",
    active: false,
  },
  {
    title: "Locations",
    icon: MapPin,
    path: "/locations",
    type: "link",
    active: false,
  },
  {
    title: "Messages",
    icon: Mail,
    path: "/messages",
    type: "link",
    active: false,
  },
  {
    title: "Search",
    icon: Search,
    path: "/students-search",
    type: "link",
    active: false,
  },
  {
    title: "Help",
    icon: HelpCircle,
    path: "/help",
    type: "link",
    active: false,
  },
];

export const ADMINMENUITEMS = [
  {
    title: "Users",
    icon: User,
    path: "/users",
    type: "link",
    active: false,
  },
  {
    title: "Schools",
    icon: Clipboard,
    path: "/schools",
    type: "link",
    active: false,
  },
  {
    title: "Employers",
    icon: Search,
    path: "/employers",
    type: "link",
    active: false,
  },
  {
    title: "Messages",
    icon: Mail,
    path: "/messages",
    type: "link",
    active: false,
  },
  {
    title: "Students",
    icon: Users,
    path: "/students-search",
    type: "link",
    active: false,
  },
  {
    title: "Reports",
    icon: BarChart,
    path: "/reports",
    type: "link",
    active: false,
  },
  {
    title: "Help",
    icon: HelpCircle,
    path: "/help",
    type: "link",
    active: false,
  },
];
