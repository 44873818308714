import React from 'react';
import './index.scss';
import { createRoot } from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import App from './App.js';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from "./theme/themecontext";
import client from "./apollo/client";


const domNode = document.getElementById('root');
console.log(domNode);
const root = createRoot(domNode);

const RootApp = () => {
  return (
    <div className="App">
      <ApolloProvider client={client}>
        <ThemeProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </ApolloProvider>
    </div>);
};

root.render(<RootApp />);
