import React, { Fragment } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_STUDENT_DETAILS, DELETE_RESUME } from "../../../api/db";

import ResumeViewer from "../resume-viewer";
import { useNavigate } from "react-router-dom";
import { GetUserID } from "../../../api/session";
import { Button } from "reactstrap";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const ResumeBuilderComplete = ({ resume, step, onChangeStep }) => {
  const navigate = useNavigate();

  const [deleteResume, deleting, deleteError] = useMutation(DELETE_RESUME, {});
  const { loading, error, data } = useQuery(GET_STUDENT_DETAILS, {
    variables: { userId: parseInt(GetUserID()) },
  });

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );
  if (error) return <p>Error : {error.message}</p>;

  let profile = data.student.ProfileImage;

  if (data) {
    console.log(data);

    if (!profile) {
      profile = "/images/profile-employer.jpg?w=165&amp;h=165&amp;mode=crop";
    }
  }

  resume.profile = profile;

  const handleDeleteResume = async (resumeid) => {
    console.log(resumeid);

    try {
      if (resumeid) {
        await deleteResume({ variables: { resumeeId: parseInt(resumeid) } });
        navigate(`${process.env.PUBLIC_URL}/settings/profile`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  function saveDocument(name, e) {
    const input = document.getElementById("resumeebody");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save(name + ".pdf");
    });
  }

  function printDocument(e) {
    const input = document.getElementById("resumeebody");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      window.open(pdf.output("bloburl"), "_blank");
    });
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-12 col-md-9">
          <div className="main-bar">
            <h2>Resume</h2>
          </div>
          <div id="profileCompletion">
            <ResumeViewer resume={resume} />
          </div>{" "}
        </div>
        <div className="col-12 col-md-3">
          <div className="main-bar">
            <h2>Actions</h2>
          </div>
          <div className="btn-showcase">
            <Button color="primary" onClick={(e) => printDocument(e)}>
              Print Resume
            </Button>
            <Button
              color="primary"
              onClick={(e) =>
                saveDocument(
                  data.student.FirstName + " " + data.student.LastName,
                  e
                )
              }
            >
              Download Resume
            </Button>
            <button
              className="btn red raised max deleteResumeeListing"
              data-rid="1679"
              onClick={() => handleDeleteResume(resume.resumeId)}
            >
              Delete Resume
            </button>
          </div>
        </div>
      </div>

      <div class="footer-buttons">
        <button
          type="button"
          class="btn btn-prev btn-primary btn-lg pull-left"
          id="prev-button"
          onClick={() => onChangeStep(step.value - 1)}
        >
          Next
        </button>

        <a
          class="btn btn-next btn-success btn-lg pull-right"
          id="next-button"
          href="/settings/profile"
        >
          Back To Profile
        </a>
      </div>
    </Fragment>
  );
};

export default ResumeBuilderComplete;
