// Import everything needed to use the `useQuery` hook
import React, { useContext } from 'react'
import './styles/app.scss'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { ThemeContext } from './theme/themecontext'
import Router from './app-routes'
import Auth from './components/auth/auth'
import Home from './routes/home'
import PublicRouter from './public-routes'

const App = () => {
  const { theme } = useContext(ThemeContext)
  const isLoggedIn = localStorage.getItem('token') != undefined

  console.log(isLoggedIn)

  return (
    <StyledThemeProvider theme={theme}>
      {isLoggedIn ? <Router /> : <PublicRouter />}
    </StyledThemeProvider>
  )
}

export default App
