import React, { Fragment, useRef, useState } from "react";
import Breadcrumb from "../components/common/breadcrumb";
import LocationJobCard from "../components/locations/jobs/location-job-card";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_EMPLOYER_LOCATIONS,
  ADD_JOB_OPENING,
  DELETE_JOB_OPENING,
} from "../api/db.js";
import { GetUserID } from "../api/session.js";
import { useParams } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import useInput from "../hooks/useInput";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";
import SimpleReactValidator from "simple-react-validator";
import { errorCheck } from "../components/common/functions.js";

const Careers = () => {
  let navigate = useNavigate();
  var { id } = useParams();

  const [modalNewJobOpening, setModalNewJobOpening] = useState(false);
  var validator = useRef(new SimpleReactValidator({ autoForceUpdate: this }));
  const [, forceUpdate] = useState();
  const locationId = useInput();
  const jobTitle = useInput("");

  const toggleJobOpeningModal = () => {
    setModalNewJobOpening(!modalNewJobOpening);
  };

  const [addJob] = useMutation(ADD_JOB_OPENING, {});

  const { loading, error, data, refetch } = useQuery(GET_EMPLOYER_LOCATIONS, {
    variables: { employerId: GetUserID() },
    pollInterval: 1500,
  });
  const [deleteJobOpening, deleting, deleteError] = useMutation(
    DELETE_JOB_OPENING,
    {}
  );

  const handleDeletejob = async (jobid) => {
    console.log(jobid);

    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        try {
          if (jobid) {
            await deleteJobOpening({
              variables: { employerOpeningsId: parseInt(jobid) },
            });

            refetch();
          }
        } catch (err) {
          console.log(err);
        }
      }
    });
  };

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );

  if (error) {
    return errorCheck(error);
  }
  console.log(data);

  let openings = [];
  let locations = [];
  data.employer.Locations.map((employerlocation) => {
    console.log(employerlocation);

    if (employerlocation.Openings) {
      if (!id || (id && employerlocation.EmployerLocationsID == id)) {
        locations.push(employerlocation);
        employerlocation.Openings.map((job) => {
          openings.push({ employerlocation, job });
          return;
        });
      }
    } else {
      if (!id || (id && employerlocation.EmployerLocationsID == id)) {
        locations.push(employerlocation);
      }
    }

    return;
  });

  if (data.employer.Locations.length == 0) {
    let path = `${process.env.PUBLIC_URL}/jobs`;

    navigate(path);
  }

  const createJob = async () => {
    console.log("saving job....");

    if (!validator.current.allValid() || !locationId.value) {
      validator.current.showMessages();
      forceUpdate(1);
      return toast.error(
        "Cannot create job listing. Job Title and Location required."
      );
    }

    try {
      var result = await addJob({
        variables: {
          employerId: parseInt(GetUserID()),
          employerUsersId: parseInt(GetUserID()),
          employerLocationsId: parseInt(locationId.value),
          title: jobTitle.value,
          active: true,
          public: true,
        },
      }).then(refetch({ variables: { employerId: GetUserID() } }));

      console.log("refetch triggered");

      if (result) {
        locationId.setValue(result.data.EmployerOpeningsID);
        var openingid = result.data.addEmployerOpening.EmployerOpeningsID;
        refetch();
        console.log(result);
        let path = `${process.env.PUBLIC_URL}/job-edit`;
        let fullpath = openingid ? path + "/" + openingid : path;

        //navigate(fullpath);
      }

      toggleJobOpeningModal();

      toast("Job has been Saved", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (err) {
      console.log("saved new ", err);
    }
  };

  return (
    <Fragment>
      <Breadcrumb title="Careers" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 mb-3">
            <Button color="success" onClick={toggleJobOpeningModal}>
              {"Add Job Opening"}
            </Button>
          </div>
        </div>
        <div className="row">
          {openings.map((opening, i) => {
            return (
              <LocationJobCard
                key={i}
                location={opening.employerlocation}
                job={opening.job}
                callback={refetch}
                deleteJob={handleDeletejob}
              />
            );
          })}
        </div>
      </div>
      <Modal isOpen={modalNewJobOpening} toggle={toggleJobOpeningModal}>
        <ModalHeader toggle={toggleJobOpeningModal}>
          Add Job Opening
        </ModalHeader>
        <ModalBody>
          <form name="frmJobApplication" id="frmJobApplication">
            <div>
              <div className="mt-3">
                <label>Locations:</label>
                <div className="drop_line">
                  <span className="drop">
                    <select
                      name="ed_state"
                      id="ed_state"
                      className="form-control ui search selection dropdown"
                      value={locationId.value}
                      onChange={locationId.onChange}
                    >
                      <option value="0">Select Location</option>
                      {locations &&
                        locations.map((x, i) => {
                          return (
                            <option
                              key={i}
                              value={"" + x.EmployerLocationsID + ""}
                            >
                              {x.LocationName}
                            </option>
                          );
                        })}
                    </select>
                    {validator.current.message(
                      "location",
                      locationId.value,
                      "required"
                    )}
                  </span>
                </div>
              </div>
              <div className="mt-3">
                <label>Job Title:</label>
                <input
                  className="field form-control"
                  name="jobTitle"
                  id="jobTitle"
                  value={jobTitle.value}
                  onChange={jobTitle.onChange}
                />
                {validator.current.message(
                  "job title",
                  jobTitle.value,
                  "required"
                )}
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={(e) => {
              createJob(e);
            }}
          >
            {"Create Job Opening"}{" "}
          </Button>
          <Button color="secondary" onClick={toggleJobOpeningModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Careers;
