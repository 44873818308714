import React, { Fragment, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  CONVERSATIONS,
  GET_STUDENT_DETAILS_BY_ID,
  SEND_MESSAGE,
} from "../../../../api/db.js";
import ResumeViewer from "../../../resumes/resume-viewer.js";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { toast } from "react-toastify";
import useInput from "../../../../hooks/useInput.js";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import ResumePrintViewer from "../../../resumes/resume-print-viewer.js";

const LocationJobApplicantCard = ({ application }) => {
  console.log(application);
  const [modal6, setModal6] = useState();
  const message = useInput("");
  const messageUserId = useInput("");
  const messageUser = useInput("");
  const [messagemodal, setMessageModal] = useState();

  const toggleMessageModal = (e, userId, name) => {
    setMessageModal(!messagemodal);
    console.log(userId);
    messageUserId.setValue(userId);
    messageUser.setValue(name);
  };

  const toggleApplication = () => {
    setModal6(!modal6);
  };

  let navigate = useNavigate();

  const { loading, error, data } = useQuery(GET_STUDENT_DETAILS_BY_ID, {
    variables: { studentId: parseInt(application.applicant.StudentID) },
  });
  const [newMessageMutation, { saveloading, saveerror, savedata }] =
    useMutation(SEND_MESSAGE, {
      refetchQueries: [{ query: CONVERSATIONS, variables: {} }],
    });

  if (loading)
    return (
      <div className="loader-box">
        <span className="rotate groove"></span>
      </div>
    );
  if (error) return <p>Error : {error.message}</p>;

  const handleNewMessage = async (e) => {
    const userIds = [];
    userIds.push(parseInt(messageUserId.value));

    try {
      await newMessageMutation({
        variables: { toUserIDs: userIds, message: message.value },
      });
      message.setValue("");
    } catch (err) {
      console.log(err);
    }
    setMessageModal(!messagemodal);

    toast("Message Sent", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  let profile = data.student.ProfileImage;

  if (data) {
    console.log(data);
    var resumee = data.student.Resumees.find(
      (r) => r.ResumeeID == application.applicant.ResumeeID
    );

    var colorValue = "blue";

    if (!profile) {
      profile = "/images/profile-employer.jpg?w=165&amp;h=165&amp;mode=crop";
    }

    var resumeeinput = {
      resumeId: parseInt(resumee.ResumeeID),
      color: String(resumee.ResumeeColorID),
      style: String(resumee.ResumeeStyleID),
      studentID: resumee.StudentID,
      title: resumee.Title,
      objective: resumee.Objective,
      experiences: [],
      educations: [],
      ColorValue: colorValue,
      firstName: data.student.FirstName,
      lastName: data.student.LastName,
      city: data.student.City,
      state: data.student.State,
      phone: data.student.Phone,
      email: data.student.Email,
      profile: profile,
    };

    resumee.EmploymentHistory.map((exp) => {
      resumeeinput.experiences.push({
        employmentHistoryID: exp.EmploymentHistoryID,
        resumeeId: parseInt(exp.ResumeeID),
        employerName: exp.EmployerName,
        title: exp.Title,
        description: exp.Description,
        startMonth: exp.StartMonth,
        startYear: exp.StartYear,
        endMonth: exp.EndMonth,
        endYear: exp.EndYear,
        employerCity: exp.EmployerCity,
        employerState: exp.EmployerState,
        current: exp.Current,
      });
    });

    resumee.EducationHistory.map((exp) => {
      resumeeinput.educations.push({
        educationHistoryId: exp.EducationHistoryID,
        resumeeId: parseInt(exp.ResumeeID),
        school: exp.School,
        certificate: exp.Certificate,
        startMonth: exp.StartMonth,
        startYear: exp.StartYear,
        endMonth: exp.EndMonth,
        endYear: exp.EndYear,
        city: exp.City,
        state: exp.State,
        current: exp.Current,
      });
    });
  }

  function saveDocument(name, e) {
    const input = document.getElementById("printPage");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "JPEG", 2, 2, width - 4, height);
      pdf.save(name + ".pdf");
    });
  }

  function printDocument(e) {
    const input = document.getElementById("printPage");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "JPEG", 2, 2, width - 4, height);
      window.open(pdf.output("bloburl"), "_blank");
    });
  }

  return (
    <Fragment>
      <div className="col-sm-6">
        <div className="card shadow-0 border">
          <div className="card-header">
            <div className="connection">
              <div className="blur">
                <img
                  width={450}
                  height={260}
                  alt=""
                  src="/images/blur2.jpg?w=450&amp;h=260&amp;mode=crop&amp;anchor=topcenter"
                />
              </div>
              <img alt="" src={profile} className="connect-left small" />
              <div className="grouped">
                <h2>
                  {resumeeinput.firstName} {resumeeinput.lastName}
                </h2>
                <p className="slant">
                  {resumeeinput.city}, {resumeeinput.state}
                </p>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="buttons">
              <Button
                className="me-2"
                color="primary"
                onClick={(e) =>
                  toggleMessageModal(
                    e,
                    data.student.UserID,
                    resumeeinput.firstName + " " + resumeeinput.lastName
                  )
                }
              >
                Send Message
              </Button>
              <Button
                color="secondary"
                className="me-2"
                onClick={() => toggleApplication()}
              >
                View Resume
              </Button>
              <Link
                className=""
                color="primary"
                to={`${process.env.PUBLIC_URL}/students/${application.applicant.StudentID}`}
              >
                <div className="btn btn-success">View Profile</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={messagemodal} toggle={toggleMessageModal}>
        <ModalHeader toggle={toggleMessageModal}>
          Send {messageUser.value} A Message
        </ModalHeader>
        <ModalBody>
          <form>
            <textarea
              value={message.value}
              onChange={message.onChange}
              className="form-control"
              rows="5"
              col="5"
            ></textarea>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleNewMessage("send")}>
            Send
          </Button>
          <Button color="secondary" onClick={(e) => toggleMessageModal(e)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modal6} toggle={toggleApplication}>
        <ModalHeader toggle={toggleApplication}>Resume</ModalHeader>
        <ModalBody>
          <ResumePrintViewer resume={resumeeinput} />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() =>
              window.open("/students/" + data.student.UserID, "_blank")
            }
          >
            View Profile
          </Button>
          <Button
            color="primary"
            onClick={(e) => {
              printDocument(e);
            }}
          >
            Print Resume
          </Button>
          <Button
            color="primary"
            onClick={(e) =>
              saveDocument(
                data.student.FirstName + " " + data.student.LastName,
                e
              )
            }
          >
            Download Resume
          </Button>
          <Button color="secondary" onClick={(e) => toggleApplication(e)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
export default LocationJobApplicantCard;
