import { GetUser, GetStudent } from '../../api/session'

const ResumeStyle3 = ({ resume }) => {
  console.log(resume)
  const user = GetUser()
  console.log(user.FirstName)
  var student = GetStudent(resume.studentId)

  var color = 'blue'
  if (resume.color == '1') color = '#0088b0'

  if (resume.color == '2') color = '#6bab39'

  if (resume.color == '3') color = '#e53679'

  let careertype = ''
  const ctypes = [
    { id: '1', category: 'Cosmetologist' },
    { id: '6', category: 'Makeup Artist' },
    { id: '8', category: 'Hairstylist' },
    { id: '11', category: 'Assistant/Apprentice' },
    { id: '12', category: 'Barber' },
    { id: '13', category: 'Braider' },
    { id: '14', category: 'Colorist' },
    { id: '15', category: 'Educator-Schools' },
    { id: '16', category: 'Educator-Salons' },
    { id: '17', category: 'Education Management' },
    { id: '18', category: 'Esthetician/Skin Care Specialist' },
    { id: '21', category: 'Nail Technician/Manicurist' },
    { id: '22', category: 'Product Specialist' },
    { id: '23', category: 'Receptionist' },
    { id: '24', category: 'Sales Consultant' },
    { id: '25', category: 'Salon Management' },
    { id: '26', category: 'Spa Management' },
    { id: '27', category: 'Esthetician/Cosmetologist' },
  ]

  careertype = ctypes.find((e) => e.id == resume.careerType).category

  return (
    <div id="resumeebody" className="container">
      <div className="row">
        <div
          className="col-12 bg-dark text-white p-3"
          style={{ backgroundColor: color }}
        >
          <h2 className="text-warning">
            {resume.firstName} {resume.lastName}
          </h2>
          <p className="text-info">{careertype}</p>
          <hr className="bg-light" />
          <h5 className="text-light">Contact</h5>
          {resume.phone && <p>Phone: {resume.phone}</p>}

          {resume.email && <p>Email: {resume.email}</p>}
        </div>
        <div className="col-12 bg-white p-md-2">
          <h3>Profile Summary</h3>
          <p dangerouslySetInnerHTML={{ __html: resume.objective }}></p>
          <h3>Professional Experience</h3>
          {resume.experiences &&
            resume.experiences.map((exp, i) => {
              var start =
                exp.startMonth == '13' || exp.startYear == '1'
                  ? 'Present'
                  : exp.startMonth + '/' + exp.startYear
              var end =
                exp.endMonth == '13' || exp.endYear == '1'
                  ? 'Present'
                  : exp.endMonth + '/' + exp.endYear

              return (
                <p key={i}>
                  {exp.employerName},{' '}
                  {exp.employerCity + ', ' + exp.employerState} {exp.title},{' '}
                  {start + ' - ' + end}
                </p>
              )
            })}
          <h3>Education</h3>

          {resume.educations &&
            resume.educations.map((edu, i) => {
              var start =
                edu.startMonth == '13' || edu.startYear == '1'
                  ? 'Present'
                  : edu.startMonth + '/' + edu.startYear
              var end =
                edu.endMonth == '13' || edu.endYear == '1'
                  ? 'Present'
                  : edu.endMonth + '/' + edu.endYear
              return (
                <p key={i}>
                  {edu.school + ', ' + edu.city + ', ' + edu.state}{' '}
                  {edu.certificate}, {start + ' - ' + end}
                </p>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default ResumeStyle3
