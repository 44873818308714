import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb.js";
import { useQuery } from "@apollo/client";
import { SCHOOL_SEARCH } from "../../api/db.js";
import useInput from "../../hooks/useInput.js";
import { Alert } from "reactstrap";
import SchoolFilter from "./filters/school-filter.js";
import SchoolFilterMobile from "./filters/school-filter-mobile.js";
import { Users } from "react-feather";

const SchoolSearch = ({ query }) => {
  const [mobile, setMobile] = useState(window.innerWidth <= 510);

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const textfilter = useInput("");
  const locationfilter = useInput(query);
  const schoolfilter = useInput([]);
  const citiesfilter = useInput([]);

  const { loading, error, data } = useQuery(SCHOOL_SEARCH, {
    variables: { query: locationfilter.value, limit: 250 },
  });

  console.log(locationfilter.value);

  let schoolLocations = [];
  let schools = [];
  let cities = [];

  if (data) {
    data.schools.map((school) => {
      if (!schools.some((x) => x === school.School.toUpperCase().trim())) {
        schools.push(school.School.toUpperCase().trim());
      }

      var cityvalue = school.State.trim();

      if (!cities.some((x) => x === cityvalue.toUpperCase().trim())) {
        cities.push(cityvalue.toUpperCase().trim());
      }

      schoolLocations.push(school);
    });
  }

  function search(schoolLocations) {
    return schoolLocations
      .filter((school) => {
        if (schoolfilter.value.length >= 1) {
          return schoolfilter.value.includes(school.School.toUpperCase());
        }
        return true;
      })
      .filter((school) => {
        if (citiesfilter.value.length >= 1) {
          let jobcity = school.State.trim();
          return citiesfilter.value.includes(jobcity.toUpperCase());
        }

        return true;
      })
      .filter((school) => {
        if (textfilter.value.length >= 1) {
          return school.School.toUpperCase().includes(
            textfilter.value.toUpperCase()
          );
        }

        return true;
      });
  }
  console.log(schoolLocations);
  return (
    <Fragment>
      <Breadcrumb title="Schools" />
      <div className="container-fluid">
        <div className="row">
          {mobile && (
            <SchoolFilterMobile
              schools={schools}
              cities={cities}
              schoolfilter={schoolfilter}
              textfilter={textfilter}
              cityfilter={citiesfilter}
              locationfilter={locationfilter}
            />
          )}

          {!mobile && (
            <SchoolFilter
              schools={schools}
              cities={cities}
              schoolfilter={schoolfilter}
              textfilter={textfilter}
              cityfilter={citiesfilter}
              locationfilter={locationfilter}
            />
          )}

          <div className="col-xl-9 xl-60">
            {loading && (
              <Alert color="secondary dark">
                <p>{"Search....."}</p>
              </Alert>
            )}
            {!loading && search(schoolLocations).length == 0 && (
              <Alert color="secondary dark">
                <p>{"No Search Results found"}</p>
              </Alert>
            )}

            {search(schoolLocations).map((data, i) => {
              return (
                <div
                  key={"jsrc" + i}
                  className={`card ${data.badgeValue ? "" : ""} mt-0`}
                >
                  <div className="job-search">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <h6 className="f-w-600">
                            <a href={"/school/" + data.LocID}>{data.School}</a>
                          </h6>
                          <p>{data.Address}</p>
                          <p>
                            {data.City}, {data.State} {data.Zipcode}
                          </p>
                          <p>Phone: {data.Phone}</p>
                          <p className="mb-0 fw-bold">
                            <span>
                              <Users />
                              Total Students:
                              {data.StudentStats.TotalStudents}
                            </span>
                          </p>
                          <p className="mb-0 fw-bold">
                            <span>
                              <Users />
                              Total Alumni:
                              {data.StudentStats.TotalAlumni}
                            </span>
                          </p>
                          <p className="mb-0 fw-bold">
                            <span>
                              <Users />
                              Registered Users:
                              {data.StudentStats.TotalRegistered}
                            </span>
                          </p>
                        </div>
                      </div>
                      <p className="mt-2"></p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SchoolSearch;
