import React from "react";
import { Navigate, Redirect } from "react-router-dom";

export const snippet = (text, threshold) => {
  for (var i = threshold; i < text.length; i++) {
    if (/^\s/.test(text.substr(i, 1))) return text.substr(0, i) + "...";
  }

  return text;
};

export const errorCheck = (error) => {
  if (error.message) {
    if (
      error.message.includes("Expired") ||
      error.message.includes("Unauthorized")
    )
      return <Navigate to="/login" />;
  } else return <p>Error : {error.message}</p>;
};
